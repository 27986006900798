// import * as firebase from 'firebase';
import { AngularFireAuth } from "@angular/fire/compat/auth"; 

import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromApp from '../store/app.reducers';
import * as AuthActions from './store/auth.actions';


@Injectable()
export class AuthenticateService {

    constructor() { } 



}