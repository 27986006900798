import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, merge } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.css']
})
export class UploadTaskComponent implements OnInit {

  @Input() file: File;

  task: AngularFireUploadTask;
  fileId = this.afs.createId();
  percentage: Observable<number>;
  snapshot: Observable<any>; // for upload and commit to afs
  // snapshot$: Observable<any>; // for file size listener
  downloadURL: string;
  mediaType: string;
  size: any;

  constructor(private storage: AngularFireStorage, 
    private afs: AngularFirestore) { }

  ngOnInit() {
    this.startUpload();
  }

  startUpload() {

    // The storage path
    const storagePath = `media/${Date.now()}_${this.file.name}`;

    const customMetadata = {
      copyright: "©2019 LyricalDays.com. All rights reserved."
    };

    const metaData = {
      'Cache-Control': 'public, max-age=300, s-maxage=600',
      'customMetadata': customMetadata
    };

    // Reference to storage bucket
    const ref = this.storage.ref(storagePath);

    // The main task
    this.task = this.storage.upload(storagePath, this.file, metaData);

     // client-sdie validation example
     if (this.file.type.split("/")[0] == "image") {
      // this.mediaItem.mediaType = "video";
      this.mediaType = "image";
      console.error("Image recognised.");
      // Matching afs back end rule::
      // allow read; allow write: if request.resource.contentType.matches('image/.*');
      // return;
    } else if (this.file.type.split("/")[0] == "video") {
      this.mediaType = "video";
      console.log("Video file type recognised.")
    }
    else {
      console.log("Unsupported file type! Please try something else.");
      return;
    }

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    // add file size to doc
    // this.snapshot$ = this.task.snapshotChanges().pipe(
    //   tap(snapshot => {
    //     let bytesTransferred = snapshot.bytesTransferred;
    //     let totalBytes = snapshot.totalBytes;
    //     if(bytesTransferred === totalBytes) {
    //       this.afs.collection('media').doc(this.fileId).set({merge: true,
    //       size: snapshot.totalBytes})
    //     }
    //   }));

    this.snapshot   = this.task.snapshotChanges().pipe(
      tap(
        snapshot => {
          this.size = snapshot.totalBytes;
        }),
      // The file's download URL
      finalize( async() =>  {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        console.log("downloadURL: " , this.downloadURL);
        this.afs.collection('media').doc(this.fileId).set(
          { docId: this.fileId, 
            size: this.size,
            storageRef: storagePath, 
            downloadURL: this.downloadURL, 
            mediaType: this.mediaType,
             });
      }),
    );
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

}


