import { Action, createFeatureSelector, createSelector } from '@ngrx/store';


import * as fromApp from '../../store/app.reducers';
import { CheckoutActions, SET_CHECKOUTBASKET, SET_SELECTED_ECARDS, SET_GIFTS, SET_CUSTOMISED_ECARDS, UPDATE_CUSTOMISE_ECARD, ADD_NEW_SELECTED_ECARD, EMPTY_CHECKOUT, UPDATE_QUICK_SEND_ECARD, ADD_NEW_CUST_ECARD, UPDATE_IS_USING } from './checkout.actions';
import { BasketItem } from "../../basket/basket-item.model";
import { Gift } from '../../gift-catalogue/gift.model';
import { ECard } from '../../e-card-catalogue/e-card.model';
import { ECardCustomise, ECardPack } from '../customise-e-card/customise-ecard.model';



export interface State {
    basket: BasketItem[];
    selectedGifts: Gift[];
    selectedECards: ECardCustomise[]; // pack
    customisedECards: ECardPack[]; // card
}

// export interface State extends fromApp.AppState {
//     checkout: CheckoutState
// }

const initialState : State = {
    basket: null,
    selectedGifts: null,
    selectedECards: null,
    customisedECards: null,
}

export function checkoutReducer(state = initialState, action: CheckoutActions) {
    switch (action.type) {
        case SET_CHECKOUTBASKET:
            return {
                ...state,
                basket: [...action.payload]
            }
        case SET_SELECTED_ECARDS:
            return {
                ...state,
                selectedECards: [...action.payload]
            }
        case SET_GIFTS:
            return {
                ...state,
                selectedGift: [...action.payload]
            }
        case ADD_NEW_SELECTED_ECARD:
            return {
                ...state,
                selectedECards: [...state.selectedECards, action.payload]
            };

        case ADD_NEW_CUST_ECARD:
            return {
                ...state,
                customisedECards: [state.customisedECards, action.payload]
            }
        case UPDATE_QUICK_SEND_ECARD:
            // const ecId = action.payload.ecard.id;
            // console.log("id of ecard to be update: " , ecId);
            // const ecIdIndex = state.selectedECards.indexOf(state.selectedECards.find(e => e.ecard.id  == ecId));
            // console.log("index of said ecard: " , ecIdIndex);
            // const ecard = state.selectedECards[ecIdIndex];
            // console.log("the ecard to be updated: " , ecard);
            // const updatedECard = {
            //     ...action.payload
            // };
            // console.log("the updated ecard " , UpdateECard);
            // const updatedECards = [...state.selectedECards];
            // console.log("the list to be updated: ", updatedECards);
            // updatedECards[ecIdIndex] = updatedECard;
            // console.log("the list updated: " , updatedECards);

            const ecard = state.selectedECards[action.payload.index];
            const updatedECard = action.payload.ecard;
            const updatedECards = [...state.selectedECards];
            updatedECards[action.payload.index] = updatedECard;
            console.log("this : " , updatedECards);
            return {
                ...state,
                selectedECards: updatedECards
            };
            case UPDATE_IS_USING:
                const epId = action.payload.id;
                const cardPack =  state.customisedECards.find(p => p.id === epId);
                const updatedPack = {
                    id: cardPack.id,
                    pack: cardPack.pack,
                    using: action.payload.isUsing
                };
                const updatedPacks = [...state.customisedECards]; // take a copy of the original
                const updatePackIndex = state.customisedECards.indexOf(cardPack);
                updatedPacks[updatePackIndex] = updatedPack;
                return {
                    ...state,
                    customisedECards: updatedPacks
                };
            case UPDATE_CUSTOMISE_ECARD:
                console.log("UPDATE_CUSTOMISE_ECARD", action.payload.ecard, action.payload.index)
                const eId = action.payload.ecard.ecard.id; // find the id of the pack
                const subPackToUpdate = state.customisedECards.find(p => p.id === eId); // find the card pack the card is in
                const singleCardToUpdate =  subPackToUpdate.pack[action.payload.index]; // find the old card data
                const updatedCard = {
                    ...singleCardToUpdate,
                    ...action.payload.ecard
                }; // new data
                const updatedCards = [...state.customisedECards]; // take a slice of the current customised ecard packs
                const updateSubPackIndex = state.customisedECards.indexOf(subPackToUpdate); // index for next line
                // updatedCards[updateSubPackIndex].pack[action.payload.index] = updatedCard;  // update card in subcollection with new data
                
                
                const copyUpdateSubPackArray = [...updatedCards[updateSubPackIndex].pack]  // make a copy of subpack at index
                console.log("const copyUpdateSubPackArray = {...updatedCards[updateSubPackIndex].pack}  ==", copyUpdateSubPackArray );
                copyUpdateSubPackArray[action.payload.index] = {...updatedCard};
                console.log("copyUpdateSubPackArray[action.payload.index]",  copyUpdateSubPackArray[action.payload.index]);
                
                // copyUpdateSubPack = updatedCard; // update ecard in pack in copy
                console.log("AFTER copyUpdateSubpack.pack = ", copyUpdateSubPackArray);
                const newUpdatedCards = updatedCards.slice();
                newUpdatedCards[updateSubPackIndex] = {
                    id: newUpdatedCards[updateSubPackIndex].id,
                    pack: copyUpdateSubPackArray,
                    using: newUpdatedCards[updateSubPackIndex].using
                };
                console.log("newUpdateCards: ", newUpdatedCards);
                // const newUpdatedCards = updatedCards.slice();
                // newUpdatedCards[updateSubPackIndex].pack[action.payload.index] = updatedCard; // error accont assign to object
                
                return {
                    ...state,
                    customisedECards: [...newUpdatedCards]
                };
        case SET_CUSTOMISED_ECARDS:
            return {
                ...state,
                customisedECards: [...action.payload]
            }
        case EMPTY_CHECKOUT:
            return {
                ...state,
                basket: null,
                selectedGifts: null,
                selectedECards: null,
            }
        default:
            return state;
    }
}

// export const getCheckoutState = createFeatureSelector<CheckoutState>('checkout');

// export const getSelectedGifts = createSelector(getCheckoutState, (state: CheckoutState) => state.selectedGifts);
// export const getSelectedECards = createSelector(getCheckoutState, (state: CheckoutState) => state.selectedECards);
// export const getBasketItems = createSelector(getCheckoutState, (state: CheckoutState) => state.basket);
// export const getCustomisedECards = createSelector(getCheckoutState, (state: CheckoutState) => state.customisedECards);

export const getBasketItems  = (state: State) => state.basket;
export const getSelectedECards  = (state: State) => state.selectedECards;
export const getSelectedGifts  = (state: State) => state.selectedGifts;
export const getCustomisedECards = (state: State) => state.customisedECards;