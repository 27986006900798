import { Action, createFeatureSelector, createSelector } from '@ngrx/store';

import { OccCatTagActions, SET_OCCS, SET_CATS, SET_TAGS, ADD_OCCS, ADD_CATS, ADD_TAGS, DELETE_CATS, DELETE_OCCS, DELETE_TAGS, EDIT_OCCS } from './occ-cat-tag.actions';
import * as fromApp from '../../../../store/app.reducers';
import { Filter } from '../../../../e-card-catalogue/e-card.model';


export interface OccCatTagState {
    occ: Filter[];
    cat: string[];
    tag: string[];
}

export interface State extends fromApp.AppState {
    occCatTag: OccCatTagState
}

const initialState: OccCatTagState = {
    occ: [],
    cat: [],
    tag: []
}


export function occCatTagReducer(state = initialState, action: OccCatTagActions) {
    switch (action.type) {
        case SET_OCCS:
        return {
            ...state,
        occ: [...action.payload]
        }
        case SET_CATS:
        return {
            ...state,
        cat: [...action.payload]
        }
        case SET_TAGS:
        return {
            ...state,
        tag: [...action.payload]
        }
        case ADD_OCCS:
        return {
            ...state,
        occ: [...state.occ, action.payload]
        }
        case ADD_CATS:
        return {
            ...state,
        cat: [...state.cat, action.payload]
        }
        case ADD_TAGS:
        return {
            ...state,
        tag: [ ...state.tag, action.payload ]
        }
        case EDIT_OCCS:
            const occEdit = state.occ.find(o=> o.id === action.payload.id);
            const index = state.occ.indexOf(occEdit);
            const occ = state.occ[index];
            const updatedOcc = {
                ...occ,
                ...action.payload
            };
            const updatedOccs = [...state.occ];
            updatedOccs[index] = updatedOcc;
        return {
            ...state,
            occ: updatedOccs
        }
        case DELETE_OCCS:
            const ogOccs = state.occ.slice();
            const ogOccDelIndexFind = ogOccs.find(o => o.id === action.payload.id);
            const ogOccDelIndex = ogOccs.indexOf(ogOccDelIndexFind);
            ogOccs.splice(ogOccDelIndex, 1);
            return {
                ...state,
                occ: ogOccs
            }
        default:
        return state;
    }
}


// selectors
 
export const getOccCatTagState = createFeatureSelector<OccCatTagState>('occCatTag');

export const getAvailableOccs = createSelector(getOccCatTagState, (state: OccCatTagState) => state.occ);
export const getAvailableCats = createSelector(getOccCatTagState, (state: OccCatTagState) => state.cat);
export const getAvailableTags = createSelector(getOccCatTagState, (state: OccCatTagState) => state.tag); 

// export const getActiveTraining = createSelector(getTrainingState, (state: TrainingState) => state.activeTraining);
// export const getIsTraining = createSelector(getTrainingState, (state: TrainingState) => state.activeTraining != null);