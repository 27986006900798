import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ECardCustomise } from "./customise-e-card/customise-ecard.model";
import { Mailer } from "../lyrical-mail/mailer.model";

@Injectable()
export class MailerService {

constructor(private afs: AngularFirestore ) {}

// for each ecard breack down

// onSendECardsRepo(uid: string, customisedECards: ECardCustomise[], orderId: string) {
//     customisedECards.forEach(
//         customisedECard => {
//             customisedECard.recipient.forEach( contact => {
//                 const mailerId = this.afs.createId();    
//                 const mailer: Mailer = {
//                         mailerId: mailerId, 
//                         // mailerURL: ,
//                         uid: uid,
//                         orderId: orderId,
//                         ecard:customisedECard.ecard,
//                         customisedECardId: customisedECard.customisedECardId,
//                         message: customisedECard.message,
//                         signature: customisedECard.signature,
//                         contact: contact,
//                         creationDate: new Date(),
//                        from: ''
//                     };
//                     this.afs.collection('users').doc(uid).collection('mailedECards').doc(mailerId).set(mailer);
//             })
//         }
//     )
   

// }


 

}