import { Action } from '@ngrx/store';

export const SET_AUTHENTICATED = '[Auth] Set Authenticated';
export const SET_UNAUTHENTICATED = '[Auth] Set Unauthenticated';


export const TRY_SIGNUP = 'TRY_SIGNUP';
export const SIGNUP = 'SIGNUP';
export const TRY_USER = 'TRY_USER';
export const USER = 'USER';
export const CREATE_USER = 'CREATE_USER';
export const NO_USER ='NO_USER';
export const TRY_LOGIN = 'TRY_LOGIN';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';

export class SetAuthenticated implements Action {
    readonly type = SET_AUTHENTICATED;
}

export class SetUnauthenticated implements Action {
    readonly type = SET_UNAUTHENTICATED;
}

export class TrySignup implements Action {
    readonly type = TRY_SIGNUP;

    constructor(public payload: {username: string, password: string}) {}
}

export class Signup implements Action {
    readonly type = SIGNUP;
}

export class TryUser implements Action {
    readonly type = TRY_USER;

    constructor(public payload: boolean) {}
}

export class User implements Action {
    readonly type = USER;
}

export class CreateUser implements Action {
    readonly type = CREATE_USER;

    constructor(public payload: User) {}
}

export class NoUser implements Action {
    readonly type = NO_USER;
}

export class TryLogin implements Action {
    readonly type = TRY_LOGIN;

    constructor(public payload: {username: string, password: string}) {}
}

export class Login implements Action {
    readonly type = LOGIN;
}

export class Logout implements Action {
    readonly type = LOGOUT;
}

export class SetToken implements Action {
    readonly type = SET_TOKEN;

    constructor(public payload: string) {}
}

export type AuthActions =  SetAuthenticated | SetUnauthenticated |
TrySignup | Signup | TryUser | User | CreateUser | NoUser | TryLogin | Login | Logout | SetToken;