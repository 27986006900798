import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';

import { BasketItem } from '../basket-item.model';
import { BasketService } from '../basket.service';
import { Subscription } from 'rxjs/Subscription';
import { CurrencyService } from '../../shared/currency.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-basket-item',
  templateUrl: './basket-item.component.html',
  styleUrls: ['./basket-item.component.css']
})
export class BasketItemComponent implements OnInit {
  @Input() basketItem: BasketItem;
  quantitySelector: number[] = [1,2,3,4,5,6,7,8,9];
  currency: string;


  constructor( private basketService: BasketService,
              private currencyService: CurrencyService ) { }

  ngOnInit() {
    this.currencyService.currency.subscribe((currency) => {
      this.currency = currency;
    })
  }

  updateQuantity(basketItem: BasketItem ,quantity: number) {
    this.basketItem.quantity = quantity;
    this.basketService.onUpdateBasketQty(basketItem, quantity);  // FIX THIS IT's Not Updating properly outide of itself
  }

  onDelete(basketItem: BasketItem) {
    this.basketService.deleteItem(basketItem);
  }


}