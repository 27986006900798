import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "@angular/fire/compat/firestore";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { map, take } from "rxjs/operators";
import { fromPromise } from "rxjs/observable/fromPromise";

import { Contact } from "./contact-entry.model";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";

import * as fromUser from "../../../shared/store/users.reducers";
import * as fromAddressbook from "./store/addressbook.reducers";
import * as AddressActions from "./store/addressbook.actions";
import * as fromApp from '../../../store/app.reducers';

@Injectable()
export class AddressbookService {
  private userUID: string = ''; // using this only now with getUserUID()
  // private fetchUserId = fromPromise(this.afAuth.currentUser.then((user)=> {return this.userUID = user.uid})); //this from promise doesn't set a permanent result
  private currentUserId: string = this.userUID; // this did not update with new value, remained empty
  private addySubs: Subscription[] = [];

  constructor(
    private userStore: Store<fromUser.State>,
    private appStore: Store<fromApp.AppState>,
    private store: Store<fromAddressbook.State>, // contact state is also option that might work
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private analytics: AngularFireAnalytics
  ) {
    // this.fetchUserId;
    // this.getUID;
    this.userStore.select(fromUser.getUserUID).subscribe((UID) => {
      console.log("UID: ", UID);
    })
    this.userStore.select(fromUser.getUser).subscribe(
      (user) => 
    {console.log("user.id: " ,{...user}); 
    const u = {...user};
    u.uid
    console.log("currentUserId, " ,u.uid);
    return this.userUID = user.uid}
    )
  }


  // using appStore instead of user store...no idea as to why this works and the other didn't
  getUserUID() {
    this.appStore.select(fromApp.getIsUser).subscribe(user => {
      this.userUID = user.uid;
    });
   }
  

  fetchAddressbook() {
    console.log("running fetch in addressbookService...");
    // let runFetch: boolean;
    // this.store.select(fromAddressbook.getAvailableContacts).subscribe(
    //   res => {
    //     console.log("onFetchfromStore: " , res);
    //     if (!res) {
    //       return runFetch = true;
    //     } else {
    //       return runFetch = false;
    //     }
    //   }
    // );
    // if (runFetch) {
      // console.log("addressbookService: runFetch:: ", runFetch);
    if (
      this.store.select(fromAddressbook.getAvailableContacts).subscribe( 
      (res) => 
          { 
            console.log("res", res);
            if (res.length === 0) 
              { console.log("what");
                return true;
              } 
              else 
              {console.log("whatnot");
              return false;
            }
          }
        ) 
      ) {
        this.getUserUID();
       console.log("this.currentUserId" , this.userUID);
      const addressbookRef: AngularFirestoreCollection<any> = this.afs
      .collection("users")
      .doc(this.userUID)  //swapped from this.currentUserId to this.userUID
      .collection("addressbook");
      console.log("addressbook ref got! ", addressbookRef);
      console.log("hello??")
    fromPromise(
      addressbookRef.ref.get().then(querySnapshot => {
        let contacts: Contact[] = [];

        querySnapshot.forEach(doc => {
          let id = doc.id;
          let contactFirstName = doc.get("contactFirstName");
          console.log("get a field: ", doc.data());
          let contactSurname = doc.get("contactSurname");
          let contactNickname = doc.data()["contactNickname"];
          let contactRelGroup = doc.data()["contactRelGroup"];
          let contactMobileNumber = doc.data()["contactMobileNumber"];
          let contactEmail = doc.data()["contactEmail"];
          let contactBirthday = doc.data()["contactBirthday"];
          let contactAnniversary = doc.data()["contactAnniversary"];
          // let contactSurname = doc.get("contactSurname");
          // let contactNickname = doc.data()["contactNickname"];
          // let contactRelGroup = doc.data()["contactRelGroup"];
          // let contactMobileNumber = doc.data()["contactMobileNumber"];
          // let contactEmail = doc.data()["contactEmail"];
          // let contactBirthday = doc.data()["contactBirthday"];
          // let contactAnniversary = doc.data()["contactAnniversary"];
          let contact: Contact = {
            id,
            contactFirstName,
            contactSurname,
            contactNickname,
            contactRelGroup,
            contactMobileNumber,
            contactEmail,
            contactBirthday,
            contactAnniversary
          };
          contacts.push(contact);
        });
        console.log("contacts: ", contacts);
        return contacts.slice();
      })
    ).subscribe(
      (contacts: Contact[]) => {
        console.log("contact fetched: ", contacts);
        this.store.dispatch(new AddressActions.SetContacts(contacts));
      },
      error => {
        console.log(error);
      }
    );
    // } else {
    //   return console.log("Happy here.");
    // }
    } else {
      return;
    }
  }

  onAddContact(contact: Contact) {
    console.log("Running add contact...");
     this.getUserUID();
     console.log(this.userUID); //to confirm UID is being fetched correctly
    // this.userStore.select(fromUser.getUser).subscribe(user => {
    //   console.log(user.uid);
    // }); // this doesn't work anymore 23?12/2023
    this.afs
      .collection("users")
      .doc(this.userUID) /// swapped from this.currentUserId to this.userUID
      .collection("addressbook")
      .add({
        merge: true,
        ...contact
      });
    this.analytics.logEvent("add_contact");
    this.store.dispatch(new AddressActions.AddContact(contact))
  }

  onEditContact(contact: Contact) {
    console.log("Checking userID : " , this.currentUserId);
    console.log("Checking contact ID : " , contact.id);
    console.log("Checking contact object: " , contact);
    this.afs
      .collection("users")
      .doc(this.userUID) //changed to userUID
      .collection("addressbook")
      .doc(contact.id)
      .update({
        ...contact
      });
    this.store.dispatch(new AddressActions.EditContact(contact));
    this.analytics.logEvent("edit_contact");
  }

  onDeleteContact(contact: Contact) {
    this.afs
      .collection("users")
      .doc(this.userUID)  // updated to userUID
      .collection("addressbook")
      .doc(contact.id)
      .delete();
    this.analytics.logEvent("delete_contact");
  }
}
