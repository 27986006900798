import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MediaDialogueComponent } from '../../media-uploader/media-dialogue.component';
import * as fromOccCatTag from '../../occ-cat-tag/store/occ-cat-tag.reducers';
import * as OccCatTagActions from '../../occ-cat-tag/store/occ-cat-tag.actions';
import * as fromECard from '../../../../e-card-catalogue/store/ecard.reducers';
import * as ECardActions from '../../../../e-card-catalogue/store/ecard.actions';
import { ECard, Filter, FilterPicker } from '../../../../e-card-catalogue/e-card.model';
import { take } from 'rxjs-compat/operator/take';
import { switchMap, mergeMap, map } from 'rxjs/operators';
import { MediaItem } from '../../media-uploader/media-item.model';
import { ECardService } from '../../../../e-card-catalogue/e-card.service';
import { OccCatTagService } from '../../occ-cat-tag/occ-cat-tag.service';
import { CurrencyService } from '../../../../shared/currency.service';


@Component({
  selector: 'app-upload-e-card',
  templateUrl: './upload-e-card.component.html',
  styleUrls: ['./upload-e-card.component.css']
})

export class UploadECardComponent implements OnInit {

  id: string;
  thumbnail: MediaItem;
  mp4VideoEmail: MediaItem;
  editMode: boolean = false;
  catSelected: boolean = false;
  tags: string[] = [];
  categories: string[] = [];
  occasions: Filter[] = [];
  currency: string = 'gbp';
  // categories: [];
  // catSelected: {id: '', switch: boolean } = {id: '', switch: false};
  formECard: FormGroup;
  ecard: ECard;
  to: string = '';
  aspectRatioSetAs;
  personageFont:string;
  messageFont:string;
  

  fontArray = [
    "Axis Extrabold",
    "Boom Farrow",
    "Brandon Grotesque Light",
    "Loganberry",
    "Nadia",
  ]


  occ$: Observable<string[]>;
  cat$ : Observable<string[]>;
  tag$: Observable<string[]>;
  occs:Filter[] = [];
  occasionsList = [];

  constructor(private store: Store<fromOccCatTag.State>,
              private storeE: Store<fromECard.State>,
              private ecardService: ECardService,
              private currencyService: CurrencyService,
              private occCatTagService: OccCatTagService,
              private dialogue: MatDialog,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.currencyService.currency.subscribe((currency) => {
      this.currency = currency.toUpperCase();
    })
    this.ecardService.fetchECards();
    this.occCatTagService.fetchOCC();
    // this.occ$ = this.store.select(fromOccCatTag.getAvailableOccs);
    this.cat$ = this.store.select(fromOccCatTag.getAvailableCats);
    this.tag$ = this.store.select(fromOccCatTag.getAvailableTags);
    this.store.dispatch({type: 'TRY_OCCS'});
    this.store.dispatch({type: 'TRY_CATS'});
    this.store.dispatch({type: 'TRY_TAGS'}); 
    

    console.log("this.occs: " ,this.occs);
   
    
    // console.log(this.formECard.valueChanges);
    // load in edit mode depending on params URL
    this.route.params
    .subscribe(
      (params: Params) => {
        this.id = params['id'];
        this.editMode = this.id != null;
        this.initForm();
        });
      }
 


  private initForm() {
    console.log("loading....");
    // variables for edit mode
    let name = '';
    let synopsis = ''; 
    let thumbnail = {};
    let mediaAltText = '';
    let asMMS = false;
    let mp4VideoMMS = '';
    let oggVideoMMS = '';
    let asEmail = false;
    let mp4VideoEmail = {};
    let oggVideoEmail = '';
    
    let aspectRatio = true;
    let isChristmas = false;
    
    let textColor = true;
    let personageFont = '';
    let messageFont = '';
    let bodyBackgroundColor = '';
    let textShadow = '';
    let to = '';
    let message = '';
    let from = '';
    let releaseDate = new Date().toString();
    let price = 0;
    let priceEUR = 0;
    let priceUSD = 0;
    let salePrice = 0;
    let saleStartDate = '';
    let saleEndDate = '';
    let sendLimit = 99;
    let review = false;
    let tags = new FormArray([]);    
    let categories = new FormArray([]);

    let occasions = this.store.select(fromOccCatTag.getAvailableOccs).subscribe((res)=> {
      console.log("Res from ocasions store: " ,res);
      let occs = [];
      res.forEach((occ) => {
        const occId = occ.id;
        const occName = occ.name;
        const occSelect = false;
        console.log("occasionsList: " , this.occasionsList);
        occs.push({...occ, selected:occSelect}); 
      });
      return this.occasionsList = occs;
    });
    
    // if edit mode is true then 
    // all the fields will be pre-populated with current data
    if (this.editMode) {
      this.storeE.select(fromECard.getAvailableECards).subscribe(
        res => {
          console.log("ecard select: " , res);
          this.ecard = res.find(e => e.id === this.id);       
          console.log("find map: " , this.ecard);
          name = this.ecard.name;
          synopsis = this.ecard.synopsis;
          asMMS = this.ecard.formatOptions.asMMS;
          mp4VideoMMS = this.ecard.formatOptions.mp4VideoMMS;
          oggVideoMMS = this.ecard.formatOptions.oggVideoMMS;
          asEmail = this.ecard.formatOptions.asEmail;
          this.mp4VideoEmail = this.ecard.formatOptions.mp4VideoEmail;
          oggVideoEmail = this.ecard.formatOptions.oggVideoEmail;
          this.thumbnail = this.ecard.thumbnail;
          mediaAltText = this.ecard.mediaAltText;
          aspectRatio = this.aspectRatioCheck();
          this.personageFont = this.ecard.styleOptions.personageFont;
          this.messageFont = this.ecard.styleOptions.messageFont;
          bodyBackgroundColor = this.ecard.styleOptions.backgroundBodyColor;
          textShadow =  this.ecard.styleOptions.textShadow;
          message = this.ecard.styleOptions.messageFont;
          to = this.ecard.styleOptions.messageRecipient || '';
          message = this.ecard.styleOptions.messageDefault || '';
          from =this.ecard.styleOptions.messageFrom || '';
          releaseDate = this.ecard.releaseDate || new Date().toString();
          price = this.ecard.price;
          priceEUR = this.ecard.priceEUR || price;
          priceUSD = this.ecard.priceUSD || price;
          salePrice = this.ecard.salePrice;
          saleStartDate = this.ecard.saleWindow.saleStartDate;
          saleEndDate = this.ecard.saleWindow.saleEndDate;
          sendLimit = this.ecard.sendLimit;
          isChristmas = this.ecard.isChristmas || false;
          this.occasions = this.ecard.occasions;
          this.preselectOccs(this.occasions);
          review = this.ecard.review;

          this.formECard = new FormGroup({
            'newECard': new FormGroup({
              'name': new FormControl(name, Validators.required),
              'synopsis': new FormControl(synopsis, Validators.required),
              'formatOptions': new FormGroup({
                    'asMMS': new FormControl(asMMS),
                        'uploadMMS': new FormGroup({
                          'mp4VideoMMS': new FormControl(mp4VideoMMS),
                          'oggVideoMMS': new FormControl(oggVideoMMS)
                        }),
                    'asEmail': new FormControl(asEmail),
                        'uploadEmail': new FormGroup({
                          // 'mp4VideoEmail': new FormControl(mp4VideoEmail),
                          'oggVideoEmail': new FormControl(oggVideoEmail)
                    }),
              }),
              'mediaAltText': new FormControl(mediaAltText, Validators.required),
              'aspectRatio': new FormControl(aspectRatio),
              'textColor': new FormControl(textColor),
              'bodyBackgroundColor': new FormControl(bodyBackgroundColor, Validators.required),
              'textShadow': new FormControl(textShadow),
              'to': new FormControl(to), 
              'message': new FormControl(message, Validators.required),
              'from': new FormControl(from),
              'releaseDate': new FormControl(releaseDate),
              // 'thumbnail': new FormControl(thumbnail, Validators.required),
              'price': new FormControl(price, [Validators.required, 
                                      Validators.pattern('^[0-9]*\.[0-9][0-9]$')]),
              'priceEUR': new FormControl(priceEUR, [Validators.required, 
                                        Validators.pattern('^[0-9]*\.[0-9][0-9]$')]),
              'priceUSD': new FormControl(priceUSD, [Validators.required, 
                                          Validators.pattern('^[0-9]*\.[0-9][0-9]$')]),
              'salePrice': new FormControl(salePrice, [
                                      Validators.pattern('^[0-9]*\.[0-9][0-9]$')]),
              'saleWindow': new FormGroup({
                'saleStartDate': new FormControl(saleStartDate),
                'saleEndDate': new FormControl(saleEndDate)
              }),
              'sendLimit': new FormControl(sendLimit),
              'review': new FormControl(review),
              'isChristmas': new FormControl(isChristmas),
              'tags': tags,
              'categories': categories,
              // 'selfDestructRules': new FormGroup({
              //   'resendLimit': new FormControl(1000),
              //   'viewLimit': new FormControl(10000)
              // })
        
            })
          });
        })
          // if (this.ecard['tags']) {
          //   for (let tag of this.ecard.tags) {
          //     tags.push(
          //       new FormGroup({
          //         'tags': new FormControl(tag.tagName)
          //       })
          //     )
          //   }
          // };
      //     if (this.ecard['occasions']) {
      //       for (let occ of this.ecard.occasions) {
      //         occasions.push(
      //           new FormGroup({
      //             'occasions': new FormControl(occ.occName)
      //           })
      //         )
      //       }
      //     };
      //     if (this.ecard['categories']) {
      //       for (let cat of this.ecard.categories) {
      //         categories.push(
      //           new FormGroup({
      //             'catagories': new FormControl(cat.catName)
      //           })
      //         )
      //       }
      //     };
      //   }
      // )
      //   }
      // )
   
     
    } // end of IF edit mode
    else {
    // else init form as normal
   this.formECard = new FormGroup({
      'newECard': new FormGroup({
        'name': new FormControl(null, Validators.required),
        'synopsis': new FormControl(null, Validators.required),
        'formatOptions': new FormGroup({
              'asMMS': new FormControl(false),
                  'uploadMMS': new FormGroup({
                    'mp4VideoMMS': new FormControl(''),
                    'oggVideoMMS': new FormControl('')
                  }),
              'asEmail': new FormControl(false),
                  'uploadEmail': new FormGroup({
                    // 'mp4VideoEmail': new FormControl(''),
                    'oggVideoEmail': new FormControl('')
              }),
        }),
        'mediaAltText': new FormControl('', Validators.required),
        'releaseDate': new FormControl(null),
        'aspectRatio': new FormControl(true),
        'textColor': new FormControl(true),
        'bodyBackgroundColor': new FormControl('#a9c6f3', Validators.required),
        'textShadow': new FormControl('#fc0 1px 0 10px'),
        'to': new FormControl('Anne'),
        'message': new FormControl('Wishing you the best!', Validators.required),
        'from': new FormControl('With lots of love, Bill'),
        // 'thumbnail': new FormControl(null),
        'price': new FormControl(null, [Validators.required, 
                                Validators.pattern('^[0-9]*\.[0-9][0-9]$')]),
        'priceEUR': new FormControl(null, [Validators.required, 
                                  Validators.pattern('^[0-9]*\.[0-9][0-9]$')]),
        'priceUSD': new FormControl(null, [Validators.required, 
                                    Validators.pattern('^[0-9]*\.[0-9][0-9]$')]),
        'salePrice': new FormControl('', [
                                Validators.pattern('^[0-9]*\.[0-9][0-9]$')]),
        'saleWindow': new FormGroup({
          'saleStartDate': new FormControl(''),
          'saleEndDate': new FormControl('')
        }),
        'sendLimit': new FormControl(sendLimit, Validators.required),
        'review': new FormControl(false),
        'isChristmas': new FormControl(false),
        'tags': tags,
        'categories': categories,
        // 'selfDestructRules': new FormGroup({
        //   'resendLimit': new FormControl(1000),
        //   'viewLimit': new FormControl(10000)
        // })
  
      })
    });
   
  }
    
  }

  onOcc(occ) {
    const name = occ.name;
    const occId = occ.id;
    const o = {id: occId, name: name};
    // let isSelected = this.occasionsList.find(o => o.id ===occ.id);
    // isSelected.selected = true;
    if (!occ.selected) {
      occ.selected = true;
      const occsSel = this.occasions.slice();
      occsSel.push({id: occId, name: name});
      this.occasions = occsSel;
      console.log("Select ran, new occasions filter list: ", this.occasions);
    } else {
      const occsSel = this.occasions.slice();
      occsSel.splice(this.occasions.indexOf(o), 1);
      console.log("Deselect ran, new occasions filter list: ", this.occasions);
      this.occasions = occsSel;
      return occ.selected = false;
    }
  }

  preselectOccs(occasionsOnECard: Filter[]) {
    this.store.select(fromOccCatTag.getAvailableOccs).subscribe((res)=> {
      console.log("Res from ocasions store: " ,res);
      let occs = [];
      res.forEach((occ) => {
        const occId = occ.id;
        const occName = occ.name;
        const occSelect = false;
        console.log("occasionsList: " , this.occasionsList);
        occs.push({...occ, selected:occSelect}); 
      });
    this.occasionsList = occs;
    console.log('occs', occasionsOnECard);
    const preselect = this.occasionsList.slice();
    // occs.forEach((o) => {
    //   const setPreselect = this.occasionsList.find(p => p.id === o.id);
    //   return setPreselect.selected = true;
    // })
    console.log("preselect: ", preselect);
    preselect.forEach(
      (occ) => {
        if (occasionsOnECard.find(o => o.id === occ.id) != undefined) {
          return occ.selected = true;
        } else {
          return occ.selected = false;
        }
      
    });
    console.log("preselectedOccs: " , this.occasionsList)
    this.occasionsList = preselect;
    });
   
  }

  // triggers the format warning if not valid
  // clears warning if input empty
onSetSalePrice() {
  if(this.formECard.get('newECard.salePrice').value === '' || 
      this.formECard.get('newECard.salePrice').pristine) {
    this.formECard.get('newECard.salePrice').markAsPristine;
    return false;
  }
else if (!this.formECard.get('newECard.salePrice').valid) {
  return true;
}
}

// triggers the format warning if not valid
  // clears warning if input empty
onSetPrice() {
  if(this.formECard.get('newECard.price').value === '' || 
      this.formECard.get('newECard.price').pristine) {
    this.formECard.get('newECard.price').markAsPristine;
    return false;
  }
  else if (!this.formECard.get('newECard.price').valid) {
  return true;
}
}

onSetPriceEUR() {
  if(this.formECard.get('newECard.priceEUR').value === '' || 
      this.formECard.get('newECard.priceEUR').pristine) {
    this.formECard.get('newECard.priceEUR').markAsPristine;
    return false;
  }
  else if (!this.formECard.get('newECard.priceEUR').valid) {
  return true;
}
}

onSetPriceUSD() {
  if(this.formECard.get('newECard.priceUSD').value === '' || 
      this.formECard.get('newECard.priceUSD').pristine) {
    this.formECard.get('newECard.priceUSD').markAsPristine;
    return false;
  }
  else if (!this.formECard.get('newECard.priceUSD').valid) {
  return true;
}
}


// Switch string for aspect ratio
onSetAspectRatio() {
  if (this.formECard.get('newECard.aspectRatio').value) {
    return this.aspectRatioSetAs = 'landscape', console.log("onSetAspectRatio: " + this.aspectRatioSetAs)
    
  } else {
    return this.aspectRatioSetAs = 'portrait', console.log("onSetAspectRatio: " + this.aspectRatioSetAs)
  }
}

aspectRatioCheck() {
  if (this.ecard.styleOptions.aspectRatio == 'landscape') {
    return true;
  } else {
    return false;
  }
}

updatePersonageFont(fontName: string) {
  this.personageFont = fontName;
}

updateMessageFont(fontName: string) {
  this.messageFont = fontName;
}

// Adds class to selector dynamically
onCatSelected(cat) {
  // if cat ID is present 
  

//  let cat : boolean = true ? false: true;
}

//connects with the tag and create a model based on the tags for auto update if chagnes to tag text by checking id
onAddTag() {}

// media manager dialogue and asset management
// opens dialogue and returns data on media object

onPickThumbnail() {
  console.log("onSetThumbnail running...");
  this.onMediaDialogue(1, 'thumbnail');
}

onPickEmailmp4()  {
  this.onMediaDialogue(1, 'emailmp4');
}

onSetThumbnail(thumbnailMedia: MediaItem[]) {
  console.log("thumbnail: " , thumbnailMedia[0]); 
  this.thumbnail = thumbnailMedia[0];
  console.log(this.thumbnail.downloadURL);
  return this.thumbnail = thumbnailMedia[0];
}

onSetmp4() {}

onSetogg() {}

onSetEmailmp4(media: MediaItem[]) {
  let emailmp4 = media[0];
  this.mp4VideoEmail = emailmp4;
  console.log("thumbnail: " , emailmp4);
}

onSetEmailogg() {}

onMediaDialogue(mediaCount: number, string: string) {
  let media: MediaItem[] = [];
  const dialogueRef = this.dialogue.open(MediaDialogueComponent, {data: {
    mediaCount: mediaCount
  }});
  dialogueRef.afterClosed().subscribe(result => {
    console.log("dialogue closed result " , result);
    media = result;
    if (string == 'thumbnail') {
    return this.onSetThumbnail(media);
  } else if (string == 'emailmp4') {
    console.log("fetching emailmp4 item...");
    this.onSetEmailmp4(media);
  } else {
    return;
  }
  });
  
}

  onNewECard() {
    console.log("hi!");
    console.log(this.formECard);
  }

  getBackgroundColor() {
    return 'transparent';
  }

  getColor() {
    if (this.formECard.get('newECard.textColor').value) {
      return '#fff';
    }
    else {
      return '#000';
    }
  }

  getFont() {
    return true;
  }

  onSubmitECard() {
    this.onSetAspectRatio();
    console.log('Hi new card here!', this.formECard.get('newECard.name').value);
    console.log(this.formECard);
    this.ecard = {
      id: this.id,
      name: this.formECard.get('newECard.name').value,
      synopsis: this.formECard.get('newECard.synopsis').value,
      thumbnail: this.thumbnail,
      mediaAltText: this.formECard.get('newECard.mediaAltText').value,
      formatOptions: {
        asMMS: this.formECard.get('newECard.formatOptions.asMMS').value,
        mp4VideoMMS: this.formECard.get('newECard.formatOptions.uploadMMS.mp4VideoMMS').value,
        oggVideoMMS: this.formECard.get('newECard.formatOptions.uploadMMS.oggVideoMMS').value,
        asEmail: this.formECard.get('newECard.formatOptions.asEmail').value,
        mp4VideoEmail: this.mp4VideoEmail,
        oggVideoEmail: this.formECard.get('newECard.formatOptions.uploadEmail.oggVideoEmail').value,
      },
      styleOptions: {
        aspectRatio: this.aspectRatioSetAs,
        textColor: this.formECard.get('newECard.textColor').value,
        personageFont: this.personageFont,
        messageFont: this.messageFont,
        messageDefault: this.formECard.get('newECard.message').value,
        messageRecipient: this.formECard.get('newECard.to').value,
        messageFrom: this.formECard.get('newECard.from').value,
        backgroundBodyColor: this.formECard.get('newECard.bodyBackgroundColor').value,
        textShadow: this.formECard.get('newECard.textShadow').value
      },
      releaseDate: this.formECard.get('newECard.releaseDate').value,
      price: this.formECard.get('newECard.price').value,
      priceEUR: this.formECard.get('newECard.priceEUR').value,
      priceUSD: this.formECard.get('newECard.priceUSD').value,
      salePrice: this.formECard.get('newECard.salePrice').value,
      saleWindow: this.formECard.get('newECard.saleWindow').value,
      sendLimit: this.formECard.get('newECard.sendLimit').value,
      review: this.formECard.get('newECard.review').value,
      isChristmas : this.formECard.get('newECard.isChristmas').value,
      tags: this.formECard.get('newECard.tags').value,
      occasions: this.occasions,
      categories: this.formECard.get('newECard.categories').value,
      customised : false
      };
      console.log("Fetching ecard values...");
      console.log("ECard values: " , this.ecard);
     
       if (this.editMode) {
         console.log("update ecard: " , this.ecard);
      this.ecardService.updateECard(this.ecard);
    } else {
      const id = this.ecardService.generateId();
      this.ecard.id = id;
      console.log("Add ecard: " , this.ecard);
       this.ecardService.AddECard(this.ecard);
    }
   // navigate out to table
   this.router.navigate(['/admin/product-management/products/ecards/']);

  }


}
