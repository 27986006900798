import { CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from "@angular/router";
import { Store } from "@ngrx/store";
import { Injectable } from "@angular/core";
import { take } from 'rxjs/operators';
// import { take } from 'rxjs/operators'; with RXJS 6+ and no rxjs-compat //

import * as fromApp from '../store/app.reducers';
import * as fromAuth from '../auth/store/auth.reducers';
import * as AuthActions from '../auth/store/auth.actions';


@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

    constructor (private store: Store<fromApp.AppState>,
                private router: Router) {}

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
return this.store.select(fromApp.getIsAuth).pipe(take(1))
.do( () =>
    {if (fromApp.getIsAuth) {
                this.router.navigate(['/my-account']);
           } else {
               this.router.navigate(['/login']);
           }
}
);

}

// reactivate when module mode for portected routes active
canLoad() {
return this.store.select(fromApp.getIsAuth).pipe(take(1)).do(
    (isAuth: boolean) => {
    //    
    if (isAuth) {
        return true;
    } else {
        this.router.navigate(['/login']);
        return false;
    }
    }
);
}


}
