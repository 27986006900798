import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { DropDownDirective } from './dropdown.directive';
import { AppOnlyNumberDirective } from './numerickeycheck.directive'
// import { CalendarComponent } from './calendar/calendar.component';
import { SignByHandComponent } from './sign-by-hand/sign-by-hand.component';
import { InputMaskDirective } from './inputmask.directive';
import { FileSizePipe } from './file-size.pipe';
import { StoreModule } from '@ngrx/store';
import { userReducer } from './store/users.reducers';
import { EffectsModule } from '@ngrx/effects';
// import { UsersEffects } from './store/users.effects';
import { MaterialModule } from '../material.module';
import { DropZoneDirective } from './drop-zone.directive';
import { AddressDialogueComponent } from '../checkout/customise-e-card/address-dialogue.component';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { ECardItemComponent } from '../e-card-catalogue/e-card-list/e-card-item/e-card-item.component';
import { PipesModule } from '../shared/pipes.module';
import { RemoveSpacesPipe } from './remove-spaces.pipe';
import { ECardRoutingModule } from '../e-card-catalogue/e-card-routing.module';
import { ECardFeatureModule } from '../e-card-catalogue/e-card.module';
import { AppRoutingModule } from '../app-routing.module';
import { ECardDetailComponent } from '../e-card-catalogue/e-card/e-card-detail.component';
import { ECardListComponent } from '../e-card-catalogue/e-card-list/e-card-list.component';
import { ECardCatalogueComponent } from '../e-card-catalogue/e-card-catalogue.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        FlexLayoutModule,
        StoreModule.forFeature('users', userReducer),
        // NgcCookieConsentModule,
        // ECardItemComponent
        // ECardFeatureModule
        // ECardRoutingModule
        // AppRoutingModule
        // EffectsModule.forFeature([UsersEffects])
    ],

    declarations:[
        // CalendarComponent,
        PaymentDetailsComponent,
        SignByHandComponent,
        DropDownDirective,
        DropZoneDirective,
        AppOnlyNumberDirective,
        InputMaskDirective,
        FileSizePipe,
        RemoveSpacesPipe,
        AddressDialogueComponent,
    ],
  
    exports:[
        FlexLayoutModule,
        MaterialModule,
        // CalendarComponent,
        PaymentDetailsComponent,
        SignByHandComponent,
        DropDownDirective,
        DropZoneDirective,
        AppOnlyNumberDirective,
        InputMaskDirective,
        AddressDialogueComponent,
        RemoveSpacesPipe
    ],
    // entryComponents: [ AddressDialogueComponent ]
})

export class SharedModule {}