import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked, DoCheck } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Mailer } from './mailer.model';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { Title } from '@angular/platform-browser';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-lyrical-mail',
  templateUrl: './lyrical-mail.component.html',
  styleUrls: ['./lyrical-mail.component.css']
})
export class LyricalMailComponent implements OnInit, AfterViewChecked, DoCheck {

  uid:string;
  mailerId: string;
  mailer: Mailer;
  whiteOutlineLogoPath: string = 'assets/brand/lyricaldays-logo-large-white-outline-02.png';

  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  hasLoaded: boolean = false;

  constructor(private route: ActivatedRoute,
              private afs: AngularFirestore,
              private changeDetector : ChangeDetectorRef,
              private analytics: AngularFireAnalytics,
              private title: Title) { }

  ngOnInit() {
    this.analytics.logEvent("view_ecard");
    this.title.setTitle("Lyrical Mail");
    this.route.params
    .subscribe(
      (params: Params) => {
        this.uid = params['uid'];
        this.mailerId =  params['mailerId'];
        this.onFetchMail();
      }
    )
  }

  onFetchMail() {
     this.afs.
     collection('users')
     .doc(this.uid)
     .collection('mailedECards')
     .doc(this.mailerId)
     .ref.get()
     .then(doc => {
       return {
        mailerId: doc.data()["mailerId"],
        // mailerURL: ,
        uid: doc.data()["uid"],
        orderId: doc.data()["orderId"],
        ecard: doc.data()["ecard"],
        customisedECardId: doc.data()["customisedECardId"],
        message: doc.data()["message"],
        signature: doc.data()["signature"],
        contact: doc.data()["contact"],
        sendLimit: doc.data()["sendLimit"],
        creationDate: doc.data()["creationDate"],
        from: doc.data()["from"],
        to: doc.data()["to"] || doc.data()["contact.contactFirstName"]
       };
     })
     .then(mailer => {
       this.mailer = mailer;
     });
    
  }

ngAfterViewChecked() {
  // this.onLoadCard();
};

ngDoCheck() {
  setTimeout( () => {
    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.muted = true; 
      if (this.videoPlayer.nativeElement.readyState >= 2) {
      this.videoPlayer.nativeElement.play();
      return this.hasLoaded = true;
    }
    }
    // // console.log("Ready state: " ,this.videoPlayer);
    // // console.log("autoplay status: ", this.videoPlayer.nativeElement.canPlay);
   
  }, 200); 
}


// checkInterval  = 50.0 // check every 50 ms (do not use lower values)
// lastPlayPos    = 0;
// currentPlayPos = 0;
// bufferingDetected = false;


onLoadCard() {
  // console.log("is buffering");
  
  //   let isBuffering = this.videoplayer.nativeElement.;
  // console.log("buffering check: " , isBuffering);
 
  // console.log("nativeElement: " , this.videoplayer.nativeElement.);
  
  // return true;
}
// setInterval(checkBuffering, checkInterval){};
// checkBuffering() {
  
//     this.currentPlayPos = this.videoplayer.nativeElement.currentTime

//     // checking offset should be at most the check interval
//     // but allow for some margin
//     var offset = (this.checkInterval - 20) / 1000

//     // if no buffering is currently detected,
//     // and the position does not seem to increase
//     // and the player isn't manually paused...
//     if (
//             !this.bufferingDetected 
//             && this.currentPlayPos < (this.lastPlayPos + offset)
//             && !this.videoplayer.nativeElement.paused
//         ) {
//         console.log("buffering")
//         this.bufferingDetected = true;
//     }

//     // if we were buffering but the player has advanced,
//     // then there is no buffering
//     if (
//         this.bufferingDetected 
//         && this.currentPlayPos > (this.lastPlayPos + offset)
//         && !this.videoplayer.nativeElement.paused
//         ) {
//         console.log("not buffering anymore")
//         this.bufferingDetected = false
//     }
//     this.lastPlayPos = this.currentPlayPos
// }

}
