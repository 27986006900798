import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coupon-voucher-codes',
  templateUrl: './coupon-voucher-codes.component.html',
  styleUrls: ['./coupon-voucher-codes.component.css']
})
export class CouponVoucherCodesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
