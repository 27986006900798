import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ECardService } from '../e-card-catalogue/e-card.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ECardCustomise } from '../checkout/customise-e-card/customise-ecard.model';
import { ECard } from '../e-card-catalogue/e-card.model';
import { Store } from '@ngrx/store';

import * as fromECard from "../e-card-catalogue/store/ecard.reducers";
import { Order } from '../shared/order.model';

@Component({
  selector: 'app-admin-area',
  templateUrl: './admin-area.component.html',
  styleUrls: ['./admin-area.component.css']
})
export class AdminAreaComponent implements OnInit {

  sideNav: boolean = false;
  dashView: boolean = true;

  constructor(private route: ActivatedRoute,
              private ecardService: ECardService,
              private store: Store<fromECard.State>,
              private afs: AngularFirestore,) { }

  ngOnInit() {
    console.log(this.route);
    this.ecardService.fetchECards();
  }

  onNavSideNav() {
    if (this.route != this.route) {
      this.sideNav = true;
      this.dashView = false;
    }
    else {
      this.sideNav = false;
      this.dashView = true;
    }
  }

// private userUID = 'dTVjGkbYVucJsc6i2sjq9h3j03s2';

  onAddChristmasTreetoDaddysaccount() {
    let eId = this.afs.createId();
    let orderId = this.afs.createId();
    let ecardObj: ECard;
    let now = new Date();
    let daddyUID = 'CcrWeNyY2LWwsXKt9JXiUeIMjNe2';
    let uid = 'dTVjGkbYVucJsc6i2sjq9h3j03s2';
    this.store.select(fromECard.getAvailableECards)
    .subscribe( (res) => {
      res.forEach( (e) => {
        if (e.id === 'hfmEdRLWKAJap1dPTJKb') {
        return ecardObj = e;
      }
      })
    });

    const ecardCustomiseObj: ECardCustomise = {
      customisedECardId : eId,
      totalSendLimit: 10,
      dispatched: 0,
      dispatchedAll: false,
      ecard: ecardObj,
      orderId: orderId
    };
    //put ecard into owned ecard library
    this.afs.collection("users").doc(uid).collection("ownedECards").doc(eId).set(ecardCustomiseObj);
    const order: Order = {
      orderId: orderId,
      userId: uid,
      // basketItems: this.basketService.items.slice(),
      orderTotal: 2.00,
      currency: "gbp",
      postage: null,
      customisedECards: [],
      gifts: [],
      status: "complete",
      date: now
    }

    this.afs.collection("users").doc(uid).collection("orders").doc(order.orderId).set(order);
    this.afs.collection("admin").doc("order-directory").collection("orders").doc(order.orderId).set(order);
  }

}
