import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-notifactions-templates',
  templateUrl: './email-notifactions-templates.component.html',
  styleUrls: ['./email-notifactions-templates.component.css']
})
export class EmailNotifactionsTemplatesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
