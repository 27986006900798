// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import 'zone.js/plugins/zone-error';

export const environment = {
  production: false,
  domain: 'localhost',
  firebase: {
    apiKey: "AIzaSyDCKvEBLF4kR07OYFRUYgk2MKYHkHTtDvg",
    authDomain: "lyrical-days.firebaseapp.com",
    databaseURL: "https://lyrical-days.firebaseio.com",
    projectId: "lyrical-days",
    storageBucket: "lyrical-days.appspot.com",
    messagingSenderId: "25663797409",
    appId: "1:25663797409:web:04addd707d152696",
    measurementId: "G-RZ3Z9XSLMJ"
    },
    hj: {
      trackingCode: '3333082',
      version: 6, // optional
      // uri: 'myuri' // optional
    }
};

