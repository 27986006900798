import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MediaService } from "./media.service";
import { MediaManagerItem, MediaItem } from "./media-item.model";

@Component({
  selector: "app-media-dialogue",
  templateUrl: "./media-dialogue.component.html",
  styleUrls: [ './media-dialogue.component.css'          
  ]
})
export class MediaDialogueComponent implements OnInit {

  mediaManagerItem: MediaManagerItem [] = [];
  selectedMedia: MediaItem [] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private mediaService: MediaService,
    public dialogRef: MatDialogRef<MediaDialogueComponent>,
    
  ) {
    console.log(passedData.mediaCount)
  }


  ngOnInit() {
    console.log("Running get items...");
    this.mediaService.fetchMediaItems();
    this.onMedia();
    console.log(this.mediaService.mediaItemsFetched);
    this.mediaService.mediaItemsFetched.subscribe( 
      (res) => {
        console.log("subscribe res: " , res);
        this.mediaManagerItem = res;
      }
    );
  }

  getMedia(e: any) {
    console.log("hi!: ", e);
    let mediaSelected;
    this.mediaService.fetchMediaItems();
    // this.mediaService.selectedMediaURL.subscribe(
    //   urls => (mediaSelected = [urls])
    // );

    console.log("mediaSelected: ", mediaSelected);
    return mediaSelected;
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  onSelected(mediaItem) {
    console.log("Clicked media item: " , mediaItem);
    if (mediaItem.selected == true) {
      this.selectedMedia.splice(mediaItem, 1);
      console.log(this.selectedMedia);
      return mediaItem.selected = false;
      
    }
    else {
      this.selectedMedia.push(mediaItem);
      console.log(this.selectedMedia);
      return mediaItem.selected = true;
    }
  }

  onMedia() {
     if (this.selectedMedia.length <= this.passedData.mediaCount && this.selectedMedia.length >= 1) {
      return false;
      
    } else {
      return true;
  }
}

  onSubmitMediaItem() {
    if (this.selectedMedia.length <= this.passedData.mediaCount && this.selectedMedia.length >= 1) {
      console.log("Correct number of media items selected: " , this.selectedMedia.slice());
      return  this.dialogRef.close({...this.selectedMedia.slice()});
    } else {
      console.log("Whoops! There has been an error in the quantity check of mediaItem[].");
    }
  }

  onUpdateAllMetadata() {
    this.mediaService.onUpdateMediaMetadata();
  }
  

  // getSelectedStyle(selected: boolean) {
  //   console.log(selected);
  //   if (selected) {
  //     return 'rgba(39,62,84,0.82);';
  //   }
  //   else {
  //     return;
  //   }
  // }

}
