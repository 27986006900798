import { Action } from '@ngrx/store';
import { ECard } from '../e-card.model';

export const SET_ECARDS = 'SET_ECARDS';
export const SET_ECARD = 'SET_ECARD';
export const ADD_ECARD = 'ADD_ECARD';
export const FETCH_ECARDS = 'FETCH_ECARDS';
export const UPDATE_ECARD = 'UPDATE_ECARD';
export const DELETE_ECARD = 'DELETE_ECARD';

export class SetECards implements Action {
    readonly type = SET_ECARDS;

    constructor(public payload: ECard[] ) {}
 }

 export class SetECard implements Action {
     readonly type = SET_ECARD;

     constructor(public payload: number ) {}
 }
 
 export class AddECard implements Action {
     readonly type = ADD_ECARD;

    constructor(public payload: ECard) {}
 }
 
 export class FetchECards implements Action {
     readonly type = FETCH_ECARDS;
 }
 
 export class UpdateECard implements Action {
     readonly type = UPDATE_ECARD;

    constructor(public payload: {id: string, updatedECard: ECard}) {}
 }
 
 export class DeleteECard implements Action {
     readonly type = DELETE_ECARD;
 
    constructor(public payload: number) {}
 }

 export type ECardActions = SetECards | 
 SetECard | 
 AddECard | 
 FetchECards |
 UpdateECard | 
 DeleteECard;
