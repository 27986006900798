import { Action, createFeatureSelector, createSelector } from '@ngrx/store';

import { User } from '../user.model';
import {UserActions, ADD_USER, GET_USER, UPDATE_USER, DELETE_USER, SET_USER, UPDATE_USER_PERSONAL_DETAILS, LOGOUT_USER} from './users.actions';
import * as fromApp from '../../store/app.reducers';

export interface State {
    user: User;
}


const initialState = {
    user: null
}

export function userReducer(state = initialState, action: UserActions) {
    // console.log("NGRX Helper: " , action.type, state);
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: {...action.payload}
            };
        case ADD_USER:
            return {
            ...state,
            user: {...action.payload}
            }
        case UPDATE_USER_PERSONAL_DETAILS:
            const user = state.user;
            const updatedUser = {
                ...user,
                firstname: action.payload.name,
                surname: action.payload.surname
             };
             return {
                 ...state,
                 user: {...updatedUser}
             };
        case LOGOUT_USER:
            return {
                ...state,
                user: null
            }
        default:
        return state
    }
}

// selectors

// export const getUserState = createFeatureSelector<UserState>('user');

export const getUser = (state: State) => state.user;
export const getUserUID = (state: State) => state.user.uid;
// console.log("get user... " , getUser);
// export const getUser =  (state: State) => state.userState.user;





