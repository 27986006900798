import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { Store } from "@ngrx/store";

import * as fromUser from '../../../shared/store/users.reducers';
import * as UserActions from '../../../shared/store/users.actions';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { Subject } from "rxjs";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { fromPromise } from "rxjs-compat/observable/fromPromise";

@Injectable()
export class AccountService {
    private userUID: string = '';
    private fetchUserId = fromPromise(this.afAuth.currentUser.then((user)=> {return this.userUID = user.uid}));
    private currentUserId: string = this.userUID;
    exportDataURL = new Subject<string>();
    export: any;
    // altExport: any;

    constructor(
        private afs: AngularFirestore,
        private afAuth: AngularFireAuth,
        private analytics: AngularFireAnalytics,
        private userStore: Store<fromUser.State>,
        private functions: AngularFireFunctions,
        private storage: AngularFireStorage
    ) {
        this.fetchUserId;
    }

    // update user email login 

    // update user password

    // update user personal details
    onUpdatePersonalDetails(name: string, surname: string) {
        console.log("Account service running!")
        this.afs.collection('users').doc(this.currentUserId).update({
            firstname: name,
            surname: surname
        });
        this.analytics.logEvent("update_user");
        this.userStore.dispatch(new UserActions.UpdateUserPersonalDetails({name, surname}));
    }

onExportData() {
            window.alert('Data export has started and will be uploaded to storage.')
            // var request = new XMLHttpRequest();
            // var params = this.afAuth.auth.currentUser;
            // console.log("params to process: ", params);
            // request.open("POST", urltoserver...request, true);
            // request.send( JSON.stringify(params));
            // request.open("GET")
            var xhr = new XMLHttpRequest();
            var body = this.afAuth.currentUser;
            console.log("Passing data for export... ", body);
            xhr.open('POST', '/export', true);
            xhr.send(
                JSON.stringify(body)
                ,
                //   function(er, res, body) {
                //     console.log(er, res, body);
                //   }
            );
            };


    async onExportUserData() {
        console.log("Export data calling cloud function...");
        console.log("Sending data... ", this.currentUserId);
        // this.exportData;
        var fun = this.functions.httpsCallable('exportUserData');
        this.export =  await fun({"data": this.currentUserId}).toPromise().then( (data:any) => {
            console.log("got past toPromise()... Running with data: ", data);
            // do something on trigger
            let filePath = `exportData/${this.currentUserId}/export.json`;
            console.log("file path made: ",  filePath);
            let fileRef =  this.storage.ref(filePath);
            console.log("fileRef used: ", fileRef);
            fileRef.getDownloadURL().subscribe(downloadURL => {
                console.log("subscription link attempt: " , downloadURL);
                this.exportDataURL.next(downloadURL);
            });
            this.analytics.logEvent("export_user_data_success");
        }).catch(error => {
            this.analytics.logEvent("export_user_data_failed");
            console.log("Error found: ", error);
        })        
    }


//    user = FirebaseAuth.getInstance().getCurrentUser();
    // user: FirBaseUser

// Get auth credentials from the user for re-authentication. The example below shows
// email and password credentials but there are multiple possible providers,
// such as GoogleAuthProvider or FacebookAuthProvider.
// credential = EmailAuthProvider
//         .getCredential("user@example.com", "password1234");

// Prompt the user to re-provide their sign-in credentials
// user.reauthenticate(credential)
//         .addOnCompleteListener(new OnCompleteListener<Void>() {
//             @Override
//             public void onComplete(@NonNull Task<Void> task) {
//                 if (task.isSuccessful()) {
//                     user.updatePassword(newPass).addOnCompleteListener(new OnCompleteListener<Void>() {
//                         @Override
//                         public void onComplete(@NonNull Task<Void> task) {
//                             if (task.isSuccessful()) {
//                                 Log.d(TAG, "Password updated");
//                             } else {
//                                 Log.d(TAG, "Error password not updated")
//                             }
//                         }
//                     });
//                 } else {
//                     Log.d(TAG, "Error auth failed")
//                 }
//             }
//         });
}