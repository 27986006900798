// import { Effect, Actions } from '@ngrx/effects';
import * as BasketActions from './basket.actions';
import { BasketItem } from '../basket-item.model';

export class BasketEffects {
    // @Effect()
    // basketItemQuantityCheck = this.actions$
    // .ofType(BasketActions.CHECK_ITEM_QUANTITY);
    // // .switchMap( 
    // // //     (action: BasketActions.CheckItemQuantity) => {
    // // //     let isNewItem = basket.find((i) => i.id === basket.id);
    // // // if (isNewItem === undefined) 
    // // //   {
    // // //   isNewItem = new BasketItem();
    // // //   isNewItem.id = item.id;
    // // //   isNewItem.name = item.name;
    // // //   isNewItem.productImage = item.productImage || item.thumbnail;
    // // //   isNewItem.price = item.price;
    // // //   this.items.push(isNewItem);
    // // //   this.itemsChanged.next(this.items.slice());
    // // //   }
    // // //   isNewItem.quantity += quantity;
    // // // }
    // // );

    // constructor (private actions$: Actions) {}

}



    //   return this.basketTotal = this.getBasketItems().map(
    //     (x) => x.price * x.quantity
    //    ).reduce((p, n) => p + n, 0 )
    // }