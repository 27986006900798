import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { AuthenticateService } from '../authenticate.service';
import * as fromApp from '../../store/app.reducers';
import * as fromUsers from '../.././shared/store/users.reducers';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/compat/auth"; 
import { UserService } from '../../shared/user.service';
import { Subscription } from 'rxjs';
import { email } from 'ng4-validators/src/app/email/validator';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})
export class LogInComponent implements OnInit, OnDestroy {

logInForm: FormGroup;
resetError: any;
emailNeeded: boolean = false;
emailSent: boolean = false;
logInError: string;
ifLogInError: boolean = false;
logInErrorSub: Subscription;
ifReqResetPwd : boolean = false;
confirmReqResetPasswordNotice: string;
isAttemptingLogin: boolean = false;
isResettingPassword: boolean = false;
confirmReset: boolean = false;

  
    constructor(private store: Store<fromApp.AppState>,
                private uStore: Store<fromUsers.State>,
                private userService: UserService,
                private afAuth: AngularFireAuth,
                private title: Title) { }
  
    ngOnInit() {
      console.log("login");
      this.title.setTitle("Login");
      this.userService.onUserPresent();
      this.initLoginForm();
 
    }
  
    initLoginForm() {
      this.logInForm = new FormGroup({
        'user': new FormGroup({
          'email': new FormControl(null, [Validators.required, Validators.email]),
          'password': new FormControl(null, [Validators.required, Validators.minLength(6)]),         
        }),       
      });
      this.logInErrorSub = this.userService.logInError.subscribe( errorString => {
        console.log("error found..." , errorString);
        this.logInError = errorString;
        if (this.logInError != '') {
          this.ifLogInError = true;
          this.isAttemptingLogin = false;
        } else {
          this.ifLogInError = false;
        }
      })
    }

  
    onLogIn() {
      // console.log("Logging in user: " , this.logInForm.get('user.email').value, this.logInForm.get('user.password').value);
      this.userService.onLogin({
        email: this.logInForm.get('user.email').value,
        password: this.logInForm.get('user.password').value
        }
      );
      this.isAttemptingLogin = true;
      // let uid = firebase.auth().currentUser.uid;
      // this.uStore.dispatch(new UserActions.GetUser(uid))
      // this.uStore.dispatch(new UserActions.UserQuery);      
    }

    onForgotPassword() {
      this.confirmReset = true;
      this.isResettingPassword = true;
    }

    onResetPassword() { 
      this.isResettingPassword = true;
      this.isAttemptingLogin = true;
      if (!this.logInForm.get('user.email').value) {
        this.isAttemptingLogin = false; 
       return this.emailNeeded = true;
      }
      else {
        this.emailNeeded = false;

        // var auth = firebase.auth();

        

        return this.afAuth.sendPasswordResetEmail(this.logInForm.get('user.email').value)
          .then(() => {
            console.log("email sent");
            this.emailSent = true;
            this.confirmReqResetPasswordNotice = "We have sent a password reset email to you. Please check your emails."
            this.isAttemptingLogin = false;
          })
          .catch((error) => {
            console.log(error);
            this.isAttemptingLogin = false;
            
          this.resetError = error;})
      }
    }

    canSendResetEmail() {
      if (this.emailNeeded || this.emailSent) {
        return false;
      } 
      else {
        return true;
      }
    }

    onCancelReset() {
      this.emailNeeded = false;
      this.isResettingPassword = false;
      this.confirmReset = false;
      this.emailSent = false;
      this.ifLogInError = false;
      this.ifReqResetPwd = false;
      this.resetError = null;
      // this.initLoginForm();
    }

    ngOnDestroy() {
      this.logInErrorSub.unsubscribe();
    }
  
}
