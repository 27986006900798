import { Component, OnInit, ViewChild, ElementRef, PLATFORM_ID, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { SEOService } from '../../shared/seo.service';
import { Meta, Title } from '@angular/platform-browser';

export interface Slide {
  id: number,
  imageURL : string,
  caption: string,
  active: boolean
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

platformBrowser: boolean = false;
private currentMonth;
private currentDay:Number = new Date().getDate();
isWinter = false;
isSummer = false;
isSpring = false;
isAutumn = false;
coverArt = 'https://firebasestorage.googleapis.com/v0/b/lyrical-days.appspot.com/o/branding%2FSpring-Summer-super.jpg?alt=media&token=80bbb181-a8c3-49a5-8de7-39199c854ad3';
  // slides : Slide[] = [
  //     { id: 1,
  //       imageURL: './assets/brand/Hero-Home-Page.jpg',
  //     caption: "This is slide One",
  //     active: true},
  //     { id: 2,
  //       imageURL: './assets/brand/Hero-Home-Page.jpg',
  //     caption: "This is slide Two",
  //     active: false},
  //     { id: 3,
  //     imageURL: './assets/brand/Hero-Home-Page.jpg',
  //     caption: "This is slide Three",
  //     active: false},
  // ]
 
  // slideIndex = 1;
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  mediaSource:string;
  @ViewChild("canvas") canvas: ElementRef;  
 w: any;
 h: any;
 particlesOnScreen = 245;
 particlesArray = [];
 ctx: any;
 canvasAlt: any;

  constructor(private seoService: SEOService, @Inject(PLATFORM_ID) private readonly platformId: Object, 
              private meta: Meta, private serviceTitle: Title) { }

  ngOnInit() {
    // this.seoService.generateTags({});
    // this.seoService.onSetSEO({
    //   name: "Lyrical days",
    //   twitter_large_image: "summary_large_image",
    //   url: 'https://lyricaldays.com/',
    //   title: 'LyricalDays.com',
    //   desc: 'Browse our ever growing collection of ecards for something that would suit your occasion.',
    //   imageURL: 'https://firebasestorage.googleapis.com/v0/b/lyrical-days.appspot.com/o/branding%2Flyricaldays-logo-large-01.png?alt=media&token=c24b7991-299b-4f28-84dd-3c628338ee6a'
    // })
    this.onSeasonalDisplay();
    this.onInitSeo(); 

  }
  
  onMediaSource() {
    // const mq = window.matchMedia( "(max-width: 1280px)" );
  // if (mq.matches) {
         this.mediaSource = 'https://firebasestorage.googleapis.com/v0/b/lyrical-days.appspot.com/o/marketing%2FForest%20Twilight%20Spirits_You%20can%20change%20this%20too%203.mp4?alt=media&token=29717fc2-f12b-4d05-a714-2fc1d7b69b01';
  // } else {
  //      this.mediaSource = 'https://firebasestorage.googleapis.com/v0/b/lyrical-days.appspot.com/o/marketing%2Fsky%20dreams%20ad%20display%202.mp4?alt=media&token=81bef6be-2da6-40a6-ae88-04a215e33503';
  // }
  }
  
  ngDoCheck() {
    if (isPlatformBrowser(this.platformId)) {
    this.onMediaSource();
    window.addEventListener("resize", this.clientResize);
    }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {    
      this.platformBrowser = true;
      this.onSeasonalDisplay();
    setTimeout( () => {
      // console.log("video: " ,this.videoPlayer);
      if (this.videoPlayer) {
      this.videoPlayer.nativeElement.muted = true;
      // console.log("Ready state: " ,this.videoPlayer);
      // console.log("autoplay status: ", this.videoPlayer.nativeElement.canPlay);
      if (this.videoPlayer.nativeElement.readyState >= 2) {
        this.videoPlayer.nativeElement.play();
      }
    }
  }, 1000); 
  }
  }
  

  onInitSeo() {
    console.log("on Init SEO is being called!")
    this.serviceTitle.setTitle("Lyrical Days | Home");
    this.meta.addTags([
        {name: "twitter:card", content: "summary_large_image"},
        {name: "twitter:site", content: "@itsLyricalDays"},
        {name: "twitter:title", content: "Lyrical Days | Home"},
        {name: "twitter:description", content: "Send something special. Send a Lyrical Days ecard."},
        {name: "twitter:image", content: "https://firebasestorage.googleapis.com/v0/b/lyrical-days.appspot.com/o/branding%2FSpring-Summer.jpg?alt=media&token=bbc4d4ef-1f02-4422-96e0-68b5d7428bc0"},
        {property: "og:url", content: 'lyricaldays.com'},
        {property: "og:title", content: "Lyrical Days | Home"},
        {property: "og:description", content: "Send something special. Send a Lyrical Days ecard."},
        {property: "og:image", content: 'https://firebasestorage.googleapis.com/v0/b/lyrical-days.appspot.com/o/branding%2FSpring-Summer.jpg?alt=media&token=bbc4d4ef-1f02-4422-96e0-68b5d7428bc0'}
    ]);
  }
  
  onSeasonalDisplay() {
    const formatter = new Intl.DateTimeFormat('en', { month: 'numeric' });
    // const formatterDate = new Intl.DateTimeFormat('en', { day: 'numeric' });
    const month = formatter.format(new Date());
    this.currentMonth = month;
    // this.currentDay = formatterDate.format(new Date());
    console.log("found seasonal month: ", this.currentMonth, month);
    switch (true) {
      case this.currentMonth == '12' ||
      this.currentMonth == '1' || this.currentMonth == '11' && this.currentDay >= 15:
      this.onLetItSnow();
      this.isWinter = true;
      this.coverArt = 'https://firebasestorage.googleapis.com/v0/b/lyrical-days.appspot.com/o/branding%2Fsnowy-hills-with-tree-2.jpg?alt=media&token=6136a215-6573-4b14-8fcd-8e262b14130d';
        break;
      case this.currentMonth == '3' || 
      this.currentMonth == '4'|| 
      this.currentMonth == '5' || 
      this.currentMonth == '6' || 
      this.currentMonth == '7' || 
      this.currentMonth == '8' ||
      this.currentMonth == '2':
      this.isSpring = true;
      this.coverArt = 'https://firebasestorage.googleapis.com/v0/b/lyrical-days.appspot.com/o/branding%2FSpring-Summer-super.jpg?alt=media&token=80bbb181-a8c3-49a5-8de7-39199c854ad3';
        break;
      case this.currentMonth == '9' ||
      this.currentMonth == '10' ||
      this.currentMonth == '11' && this.currentDay < 25:
      this.isAutumn = true;
      this.coverArt = 'https://firebasestorage.googleapis.com/v0/b/lyrical-days.appspot.com/o/branding%2Fsecond-autumn.jpg?alt=media&token=136a7793-83b8-4bee-8f8f-5ffb6c97205d';
        break;
      default:
        break;
    }
  }

  onLetItSnow() {
    if (this.canvas) {
    this.ctx = this.canvas.nativeElement.getContext("2d");
    this.w = this.canvas.nativeElement.width = window.innerWidth;
    this.h = this.canvas.nativeElement.height = window.innerHeight;
    console.log("window.innerWidth: ", window.innerWidth);
    window.addEventListener("resize", this.clientResize);
    console.log("viewChild: ", this.canvas.nativeElement.getContext("2d"));
    this.createSnowFlakes();
    this.drawSnowFlakes();
    setInterval( () => {
    this.ctx.clearRect(0, 0, this.w, this.h);
    this.drawSnowFlakes();
    this.moveSnowFlakes();
    }, 50);
    this.createSnowFlakes();
  }
  }

  // onThisSlide(i) {
  //   let activeSlide: number = this.slides[i].id;
  //   this.slides.forEach( slide => {
  //     if (activeSlide == slide.id) {
  //       return slide.active = true;
  //     } else {
  //       return slide.active = false;
  //     }
  //   });
    
  // }
  
  // showSlides(slideIndex) {};
  
  // // Next/previous controls
  // plusSlides(n) {
  //   this.showSlides(this.slideIndex += n);
  // }
  
  // // Thumbnail image controls
  //  currentSlide(n) {
  //   this.showSlides(this.slideIndex = n);
  // }
  
  // showSlides(n) {
  //   var i;
  //   var slides = document.getElementsByClassName("mySlides");
  //   var dots = document.getElementsByClassName("dot");
  //   if (n > slides.length) {this.slideIndex = 1}
  //   if (n < 1) {this.slideIndex = slides.length}
  //   for (i = 0; i < slides.length; i++) {
  //       slides[i]
  //   }
  //   for (i = 0; i < dots.length; i++) {
  //       dots[i].className = dots[i].className.replace(" active", "");
  //   }
  //   slides[this.slideIndex-1].style.display = "block";
  //   dots[this.slideIndex-1].className += " active";
  // }
  // }

   

// $(document).ready(function (){
//   if (jQuery().flexslider) {
//   //flexslider ticker
//   $('.flexslider-ticker').each(function() {
//     var tickerSettings =  {
//       animation: "slide",
//       animationLoop: false,
//       selector: ".items > .item",
//       move: 1,
//       controlNav: false,
//       slideshow: true,
//       direction: 'vertical'
//     };
//     $(this).flexslider(tickerSettings);
//   });
//   // flexsliders
//   $('.flexslider').each(function() {
//     var sliderSettings =  {
//       animation: $(this).attr('data-transition'),
//       selector: ".slides > .slide",
//       controlNav: false,
//       smoothHeight: true,
//       prevText: "",
//       nextText: "",
//       sync: $(this).data('slidernav') || '',
//       start: function(slider) {
//         if (slider.find('[data-slide-bg-stretch=true]').length > 0) {
//           slider.find('[data-slide-bg-stretch=true]').each(function() {
//             if ($(this).data('slide-bg')) {
//               $(this).backstretch($(this).data('slide-bg'));
//               // $(this).data('slide-bg');
//             }
//           });
//         }
//       }
//     };
      
//     $('html').addClass('has-flexslider');
//     $(this).flexslider(sliderSettings);
//   });
//   $(window).delay(1000).trigger('resize'); //make sure height is right load assets loaded
// }})

randomizeSnow(min, max) {
  return min + Math.random() * (max - min + 1);
};

clientResize(ev) {
  if (this.canvas) {
this.w = this.canvas.nativeElement.width = window.innerWidth;
this.h = this.canvas.nativeElement.height = window.innerHeight;
  }
};

createSnowFlakes() {
for(var i = 0; i < this.particlesOnScreen; i++){
    this.particlesArray.push({
        x: Math.random() * this.w,  
        y: Math.random() * this.h,  
        opacity: Math.random(),  
        speedX: this.randomizeSnow(-11, 11),  
        speedY: this.randomizeSnow(7, 15),    
        radius: this.randomizeSnow(0.5, 4.2),
    })
}
};

drawSnowFlakes() {
for(var i = 0; i < this.particlesArray.length; i++){    
    var gradient = this.ctx.createRadialGradient(  
      this.particlesArray[i].x,   
      this.particlesArray[i].y,   
        0,                     
        this.particlesArray[i].x,   
        this.particlesArray[i].y,   
        this.particlesArray[i].radius  
        );

        gradient.addColorStop(0, "rgba(255, 255, 255," + this.particlesArray[i].opacity + ")");  // white
        gradient.addColorStop(.8, "rgba(210, 236, 242," + this.particlesArray[i].opacity + ")");  // bluish
        gradient.addColorStop(1, "rgba(237, 247, 249," + this.particlesArray[i].opacity + ")");   // lighter bluish
      
        this.ctx.beginPath(); 
        this.ctx.arc(
          this.particlesArray[i].x,  
          this.particlesArray[i].y,  
          this.particlesArray[i].radius,  
        0,                         
        Math.PI*2,                 
        false                     
        );

        this.ctx.fillStyle = gradient;   
        this.ctx.fill();                 
}
};

moveSnowFlakes() {
for (var i = 0; i < this.particlesArray.length; i++) {
  this.particlesArray[i].x += this.particlesArray[i].speedX;     
  this.particlesArray[i].y += this.particlesArray[i].speedY;     

    if (this.particlesArray[i].y > this.h) {                                                                               
      this.particlesArray[i].x = Math.random() * this.w * 1.5;
      this.particlesArray[i].y = -50;
    }
}
};






ngOnDestroy() {}

}
