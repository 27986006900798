import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../shared/pipes.module';
import { StoreModule } from '@ngrx/store';
import { AdminRoutingModule } from './admin-routing.module';

import { AdminAreaComponent } from './admin-area.component';

//PRODUCTS & ORDERS MANAGEMENT
import { OrdersComponent } from './product-management/orders/orders.component';
import { OrdersListComponent } from './product-management/orders/orders-list/orders-list.component';
import { OrderItemComponent } from './product-management/orders/order-item/order-item.component';

import { ProductDirectoryComponent } from './product-management/product-directory/product-directory.component'; // root of both gift and ecard directory
import { AllProductsComponent } from './product-management/product-directory/all-products/all-products.component'; // both gift and ecard directory overview

import { ManageECardsComponent } from './product-management/manage-e-cards/manage-e-cards.component'; //holds directory and add, edit, export data options
import { ECardsDirectoryComponent } from './product-management/manage-e-cards/e-cards-directory/e-cards-directory.component'; // table of ecards
import { EditECardComponent } from './product-management/manage-e-cards/edit-e-card/edit-e-card.component'; // load ecard for editing
import { UploadECardComponent } from './product-management/manage-e-cards/upload-e-card/upload-e-card.component'; // add new ecard to site and database

import { ManageGiftsComponent } from './product-management/manage-gifts/manage-gifts.component'; // holds directory nd add, edit export data options
import { GiftsDirectoryComponent } from './product-management/manage-gifts/gifts-directory/gifts-directory.component'; // table of gifts
import { EditGiftComponent } from './product-management/manage-gifts/edit-gift/edit-gift.component'; // load gift for editing
import { UploadGiftComponent } from './product-management/manage-gifts/upload-gift/upload-gift.component'; // add new gift to site and database

// REPORTS & SHOP MANAGEMENT
import { TaxSettingsComponent } from './shop-management/tax-settings/tax-settings.component';
import { ShippingSettingsComponent } from './shop-management/shipping-settings/shipping-settings.component';
import { CouponVoucherCodesComponent } from './shop-management/coupon-voucher-codes/coupon-voucher-codes.component';
import { ReportsComponent } from './shop-management/reports/reports.component';
import { EmailNotifactionsTemplatesComponent } from './shop-management/email-notifactions-templates/email-notifactions-templates.component';
import { OccCatTagComponent } from './product-management/occ-cat-tag/occ-cat-tag.component';
import { OccComponent } from './product-management/occ-cat-tag/occ/occ.component';
import { CatComponent } from './product-management/occ-cat-tag/cat/cat.component';
import { TagComponent } from './product-management/occ-cat-tag/tag/tag.component';
import { occCatTagReducer } from './product-management/occ-cat-tag/store/occ-cat-tag.reducers';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './users/user/user.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { UserListComponent } from './users/user-list/user-list.component';

import { userReducer } from '../shared/store/users.reducers';
import { EffectsModule } from '@ngrx/effects';
// import { OccCatTagEffects } from './product-management/occ-cat-tag/store/occ-cat-tag.effects';
import { MediaUploaderComponent } from './product-management/media-uploader/media-uploader.component';
import { MediaDialogueComponent } from './product-management/media-uploader/media-dialogue.component';
import { MediaService } from './product-management/media-uploader/media.service';
import { UploadTaskComponent } from './product-management/media-uploader/upload-task/upload-task.component';


@NgModule({
    declarations: [
        AdminAreaComponent,
        OrdersComponent,
        OrdersListComponent,
        OrderItemComponent,
        ProductDirectoryComponent,
        AllProductsComponent,
        ManageECardsComponent,
        ECardsDirectoryComponent,
        EditECardComponent,
        UploadECardComponent,
        ManageGiftsComponent,
        GiftsDirectoryComponent,
        EditGiftComponent,
        UploadGiftComponent,
        TaxSettingsComponent,
        ShippingSettingsComponent,
        CouponVoucherCodesComponent,
        ReportsComponent,
        EmailNotifactionsTemplatesComponent,
        OccCatTagComponent,
        OccComponent,
        CatComponent,
        TagComponent,
        DashboardComponent,
        UsersComponent,
        UserComponent,
        EditUserComponent,
        UserListComponent,
        MediaUploaderComponent,
        MediaDialogueComponent,
        UploadTaskComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        PipesModule.forRoot(),
        StoreModule.forFeature('occCatTag', occCatTagReducer),
        // EffectsModule.forFeature([OccCatTagEffects]),
        AdminRoutingModule
    ],
    // entryComponents: [MediaDialogueComponent],
    providers: [MediaService]
})

export class AdminFeatureModule {}
