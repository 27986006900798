import { Action } from '@ngrx/store';
import { Contact } from '../contact-entry.model';

export const SUCCESS = 'SUCCESS';

export const TRY_CONTACTS = 'TRY_CONTACTS';

export const SET_CONTACTS = 'SET_CONTACTS';

export const ADD_CONTACT = 'ADD_CONTACT';

export const EDIT_CONTACT = 'EDIT_CONTACT';

export const DELETE_CONTACT = 'DELETE_CONTACT';

export class Success implements Action {
    readonly type = SUCCESS;
}

export class TryContacts implements Action {
    readonly type = TRY_CONTACTS;
}

export class SetContacts implements Action {
    readonly type = SET_CONTACTS;

    constructor(public payload: Contact[]) {}
}

export class AddContact implements Action {
    readonly type = ADD_CONTACT;

    constructor(public payload: Contact) {}
}

export class EditContact implements Action {
    readonly type = EDIT_CONTACT;

    constructor(public payload: Contact) {}
}

export class DeleteContact implements Action {
    readonly type = DELETE_CONTACT;

    constructor(public payload: Contact) {}
}

export type ContactActions = 
Success |
TryContacts |
SetContacts |
AddContact |
EditContact |
DeleteContact;
