import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromOccCatTag from '../store/occ-cat-tag.reducers';
import * as OccCatTagActions from '../store/occ-cat-tag.actions';
import { OccCatTagService } from '../occ-cat-tag.service';
import { Filter } from '../../../../e-card-catalogue/e-card.model';


@Component({
  selector: 'app-occ',
  templateUrl: './occ.component.html',
  styleUrls: ['./occ.component.css']
})
export class OccComponent implements OnInit {

  occForm: FormGroup;
  editMode: boolean = false;
  tempOcc: {id:string, name:string};

  occasion$: Observable<Filter[]>;

  constructor(private store: Store<fromOccCatTag.State>,
    private occCatTagService: OccCatTagService) { }

  ngOnInit() {
    this.store.dispatch({type: 'TRY_OCCS'});
    this.occCatTagService.fetchOCC();
    this.occasion$ = this.store.select(fromOccCatTag.getAvailableOccs);
    this.occForm = new FormGroup({
      'occControl': new FormControl(null, Validators.required)
    })
  }

  onOcc() {
    if (this.editMode === true) {
      //check for string changes first
      if (this.tempOcc.name != this.occForm.get('occControl').value) {
        this.tempOcc.name = this.occForm.get('occControl').value;
        let id = this.tempOcc.id;
        let name = this.onTitleCase(this.tempOcc.name);
        console.log("Occ ID... ", id, " Occ data...", name);
        this.occCatTagService.updateOCC({id, name});
        this.store.dispatch( {type: 'EDIT_OCCS', payload: {id:id, name:name} } );
        this.store.dispatch({type: 'TRY_OCCS'});
      }
      // else nothing
    } // else create new occ
    else {
      console.log("create new occ : ")
      let occValue = this.occForm.get('occControl').value;
      console.log("create new occ : ", occValue)
      var dataTitleCase = this.onTitleCase(occValue);
      this.occCatTagService.createOCC(dataTitleCase);
    // this.store.dispatch( {type: 'ADD_OCCS', payload: {'name': dataTitleCase}} ); // removed as needs id for delete function to work. now in occ-cat-tag-service
    }
    this.editMode = false;
    this.occForm.reset();
  }

  onEdit(occId: string, occName: string) {
    this.editMode = true;
    this.tempOcc = {id: occId, name: occName};
    this.occForm.setValue({'occControl': occName});
  }

  onDelete() {
    let id = this.tempOcc.id;
    let name = this.tempOcc.name;
    this.occCatTagService.deleteOCC({id, name});
    this.store.dispatch({type: 'DELETE_OCCS', payload: {id, name}});
    this.store.dispatch({type: 'TRY_OCCS'})
    this.editMode = false;
    this.occForm.reset();
  }

  onCancelEdit() {
    this.editMode = false;
    this.occForm.reset();
  }

  onTitleCase(string) {
    // let string: string = this.tagForm.get('tagControl').value;
    var nameTitleCase = string.toLowerCase().split(' ').map( (word) => {
      return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
  return nameTitleCase;
  }
  
}
