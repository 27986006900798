import { Injectable } from "@angular/core";
import {
    AngularFirestore,
    AngularFirestoreCollection
  } from "@angular/fire/compat/firestore";
import { Store } from "@ngrx/store";

// import * as firebase from 'firebase';
import * as fromApp from '../../../store/app.reducers';
import * as fromOwnedECards from './store/ownedecards.reducers';
import * as OwnedECardsActions from './store/ownedecards.actions';
import { User } from "../../../shared/user.model";
import { fromPromise } from "rxjs/observable/fromPromise";
import { ECardCustomise, ECardPack } from "../../../checkout/customise-e-card/customise-ecard.model";
import { Contact } from "../address-book/contact-entry.model";
import { Mailer } from "../../../lyrical-mail/mailer.model";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";


@Injectable()
export class OwnedECardsService {
    private user: User;


    constructor(private afs: AngularFirestore,
                private analytics: AngularFireAnalytics,
                private appStore: Store<fromApp.AppState>,
                private store: Store<fromOwnedECards.State>) {}


fetchUserData() {
    this.appStore.select(fromApp.getIsUser).subscribe((userData: User) => {
        return this.user = userData;
    })
}

    fetchUnsentECards() {
        this.fetchUserData();
        console.log("User: ", this.user.uid);
        if (this.store.select(fromOwnedECards.getOwnedECards).subscribe((res) => {if (res?.length == null || res.length == 0) {return true;} else {return false};})) {
    const ownedECardsRef: AngularFirestoreCollection<any> = this.afs.collection("users").doc(this.user.uid).collection("ownedECards");
    fromPromise(
        ownedECardsRef.ref.where("dispatchedAll" , "==" , false).get().then( query => {
            let ownedECards: ECardCustomise[] = [];
            // console.log("queryRes: " , query);
            query.forEach( doc => {
                let ownedEC : ECardCustomise = {
                            customisedECardId :  doc.get("customisedECardId"),
                            recipient : doc.get("recipient"),
                            totalSendLimit : doc.get("totalSendLimit"),
                            message : doc.get("message"),
                            signature : doc.get("signature"),
                            schedule : doc.get("schedule"),
                            dispatched : doc.get("dispatched"),
                            ecard : doc.get("ecard"),
                            orderId: doc.get("orderId")
                };
                // console.log("quermathc found for undispatched: " , ownedEC);
                ownedECards.push(ownedEC);
            });
            return ownedECards.slice();
        })
    ).subscribe(
        (ownedECards: ECardCustomise[]) => {
            this.store.dispatch(new OwnedECardsActions.SetOwnedECards(ownedECards));
            let ecardPack: ECardPack[] = [];
            ownedECards.forEach( (ecardCustomise: ECardCustomise) => {
                let ecardSingle: ECardCustomise;
                const remainder: number =  ecardCustomise.totalSendLimit - ecardCustomise.dispatched;
                let cardCount = 0;
                const subCardPack = {
                    id: ecardCustomise.customisedECardId,
                    pack: []
                }
                do {
                    cardCount++;
                    if (cardCount != remainder) {
                        ecardSingle = {
                            customisedECardId: '',
                            totalSendLimit: 1,
                            recipient: [],
                            message: '',
                            signature: '',
                            ecard: ecardCustomise.ecard,
                          }
                          subCardPack.pack.push(ecardSingle);
                    }
                } while (subCardPack.pack.length < remainder);
                ecardPack.push(subCardPack);    
            });
            this.store.dispatch( new OwnedECardsActions.SetECardPacks(ecardPack));
        },
        error => {
            console.log("Whoops! " , error);
        }
    )
        }
        else {
            return;
        }
  }

  onDispatchWrittenECardSingle(sendECardC: ECardCustomise, updatedECard: ECardCustomise){
      let now = new Date();
      let updatedECardC = {...updatedECard}; // take a copy of the updatedECard obj to edit
      const mailerId = this.afs.createId();
      const mail: Mailer = {
          mailerId: mailerId,
          uid: this.user.uid,
         orderId: sendECardC.orderId,
         ecard: sendECardC.ecard,
         customisedECardId: sendECardC.customisedECardId,
         message: sendECardC.message,
         signature: sendECardC.signature,
         contact: sendECardC.recipient[0],
         creationDate: now,
         from: this.user.firstname + " " + this.user.surname,
         to: sendECardC.to
      };
      console.log("Check mail for errors: ", mail);
      console.log("Check recieved sendECardC is correct: ", sendECardC);
      updatedECardC.recipient = sendECardC.recipient;
      updatedECardC.message = sendECardC.message;
      updatedECardC.signature = sendECardC.signature;
      updatedECardC.schedule = sendECardC.schedule;
      console.log("Check updatedECard for errors: ", updatedECardC);
      this.afs.collection("users").doc(this.user.uid).collection("mailedECards").doc(mailerId).set(mail);
      this.afs.collection("mailroom").doc(this.user.uid).collection("mailedECards").doc(mailerId).set(mail);
      this.afs.collection("users").doc(this.user.uid).collection("ownedECards").doc(sendECardC.customisedECardId).update(updatedECardC);
  }

  onDistpatchAdditional(ecardC: ECardCustomise, additionalRecipients: Contact[], newECardC: ECardCustomise) {
    let now =  new Date();
      additionalRecipients.forEach(r => {
        const mailerId =  this.afs.createId();
        const mail: Mailer = {
         mailerId: mailerId,
         uid: this.user.uid,
         orderId: ecardC.orderId,
         ecard: ecardC.ecard,
         customisedECardId: ecardC.customisedECardId,
         message: ecardC.message,
         signature: ecardC.signature,
         contact: r,
         creationDate: now,
         from: this.user.firstname + " " + this.user.surname,
         to: r.contactFirstName
        };
        console.log( "Check mail for errors: " , mail);   
    this.afs.collection("users").doc(this.user.uid).collection("mailedECards").doc(mailerId).set(mail);
    this.afs.collection("mailroom").doc(this.user.uid).collection("mailedECards").doc(mailerId).set(mail);
    // this.afs.collection("users").doc(this.user.uid).collection("orders").doc(ecardC.orderId).
    // update({ "customisedECards" : ecardC.customisedECardId,
    //         "recipient": this.firebase.afs.FieldValue.arrayUnion(additionalRecipients)
    //   })
    //   })
        });
    this.afs.collection("users").doc(this.user.uid).collection("ownedECards").doc(newECardC.customisedECardId).update(newECardC);
  }

}
