import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromUser from '../../../shared/store/users.reducers';
import { User } from '../../../auth/store/auth.actions';



@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  // userState: Observable<User>;

  constructor(
    // private router: Router,
    //           private route: ActivatedRoute,
    //           private store: Store<fromUser.State>
              ) { }

  ngOnInit() {
    // this.userState = this.store.select('user');
  
  }

}
