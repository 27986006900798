import { Injectable, EventEmitter } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { BasketItem } from "../basket/basket-item.model";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";

import * as fromECard from "../e-card-catalogue/store/ecard.reducers";
import * as fromGift from "../gift-catalogue/store/gift.reducers";
import * as fromCheckout from "./store/checkout.reducers";
import * as fromApp from '../store/app.reducers';
import * as fromUser from "../shared/store/users.reducers";
import * as fromAddress from "../user-area/user-settings/address-book/store/addressbook.reducers";
import * as CheckoutActions from "./store/checkout.actions";

import { Gift } from "../gift-catalogue/gift.model";
import { ECard } from "../e-card-catalogue/e-card.model";
import { ECardCustomise, ECardPack } from "./customise-e-card/customise-ecard.model";
import { BasketService } from "../basket/basket.service";
import { Subject } from "rxjs";
import { MailerService } from "./mailer.service";
import { Order } from "../shared/order.model";
import { AddressbookService } from "../user-area/user-settings/address-book/addressbook.service";
import { Mailer } from "../lyrical-mail/mailer.model";
import { User } from "../shared/user.model";
import { has } from "lodash";
import { CurrencyService } from "../shared/currency.service";
import { fromPromise } from "rxjs-compat/observable/fromPromise";

// import { AngularFireAnalytics } from "@angular/fire/analytics";



@Injectable()
export class CheckoutService {

  // tempCustomisedEcards: ECardCustomise[] = [];
  checkoutECards: ECardCustomise[] = [];
  ecardPack: ECardPack[] = [];
  pending: ECardCustomise[] = [];
  localECardList: ECard [] = [];
  localGiftList: Gift [] = [];
  ecardCustomiseSelected = new EventEmitter<ECardCustomise>();
  advance = new Subject<boolean>();
  permitConfirmPay: boolean = false;
  private uid: string = '';
  // basketItems: BasketItem[] = this.basketService.items.slice();

  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private eStore: Store<fromECard.State>,
    private gStore: Store<fromGift.State>,
    private store: Store<fromCheckout.State>,
    private appStore: Store<fromApp.AppState>,
    private currencyService: CurrencyService,
    private userStore: Store<fromUser.State>,
    private addressStore: Store<fromAddress.State>,
    private addressbookService: AddressbookService,
    private checkoutStore: Store<fromCheckout.State>,
    private basketService: BasketService,
    private router: Router,
    private mailerService: MailerService,
    // private analytics: AngularFireAnalytics
  ) {}

  
    onGetBasketSortChanges() {
      console.log("Hello! New Sort machine initialised...");
      let runUpdate: boolean;
      let selectedECards: ECardCustomise[];
      let customisedECards: ECardPack[];
      // check if ecards present already

     this.appStore.select(fromApp.getSelectedECards).subscribe( res => {
           selectedECards = res;
           console.log("res in runUpdate Check:" , res);
          if (!res) {
            return runUpdate = false;
          } else{ 
            return runUpdate  = true;
          }
        });
        this.appStore.select(fromApp.getCustomisedECards).subscribe(res => {
          customisedECards = res;
        })
        // if something in the array, run update store with new send limits
      console.log("runUpdate: ", runUpdate);
      if (runUpdate) {
        this.checkoutStore.dispatch(new CheckoutActions.SetCheckoutBasket(this.basketService.items.slice()));
        this.recieveSortItems(this.basketService.items.slice());
      //   console.log("Run update on basket changes: ///");
      //   console.log("customisedECards state...", customisedECards);
      //   console.log("onGetBasketSortChanges():  ",  selectedECards);
      //   selectedECards.forEach( (e: ECardCustomise) => {
      //     let index =  selectedECards.indexOf(e);
      //     const newSendLimit = this.basketService.items.slice().find(b => b.id === e.ecard.id).quantity * this.basketService.items.slice().find(b => b.id === e.ecard.id).sendLimit;
      //     e.totalSendLimit = newSendLimit;


      //   console.log("this ecardPack// if something in the array, run update store", this.ecardPack); // ERROR sub pinning and looping wrong - 160 sendLimit acquired??
      //     this.checkoutStore.dispatch(new CheckoutActions.SetCustomisedECards(this.ecardPack))
      //     // this.checkoutStore.dispatch(new CheckoutActions.UpdateCustomiseECard({ecard: e, index }));
      //   });
      //  // if item is new to store
      //   if (selectedECards.length != this.basketService.items.slice().length) {
      //     this.basketService.items.slice().forEach(i => {
      //       let indexCheckMatch = selectedECards.find(e => e.ecard.id === i.id);
      //       console.log("IndexcheckMatch: " , indexCheckMatch);
      //       if (i.type == "ecard" && !indexCheckMatch) {
      //         let newSendLimit = i.quantity * i.sendLimit;
      //         let newSelectedECard: ECardCustomise;

      //         this.eStore.select(fromECard.getAvailableECards).subscribe(
      //           ecards => {
      //             let e = ecards.find(e => e.id === i.id); 
      //             newSelectedECard = {ecard: e};

      //           }
      //         );
      //         newSelectedECard.totalSendLimit = newSendLimit;
      //         this.checkoutStore.dispatch(new CheckoutActions.AddNewSelectedECard(newSelectedECard));
      //         // this.checkoutStore.dispatch(new CheckoutActions.AddNewCustECard(newCustomiseECard));
      //       }
      //     })
      //   }
      }
      else {
        this.checkoutStore.dispatch(new CheckoutActions.SetCheckoutBasket(this.basketService.items.slice()));
        this.recieveSortItems(this.basketService.items.slice());
      }

    }

    onFetchAddressbook():void {
      console.log("running onFetchAddressbook()")
          this.addressbookService.fetchAddressbook();
    }


  recieveSortItems(items: BasketItem[]) {
    const ecardPack = [...this.ecardPack];
    // this.basketService.itemsChanged.subscribe( items => {
      //  this.checkoutStore.dispatch(new CheckoutActions.SetCheckoutBasket(basketItems));

        // check if items are gift or ecard
     this.checkoutECards = [];
    let checkoutGifts : Gift [] = [];
       items.forEach( (item) => {
         console.log("Basket items transferred to checkout: " , item);
        // if ecard check ecard bucket
        // else its a gift,check gift bucket
        if (item.type === 'ecard') {
            // afs query where id matches, return item
            // and add to checkout store
            // or look up local store if its already run??? ecard store...
            console.log("item being sorted: " , item);
            console.log("item qty: " , item.quantity);
            let newSendLimit = item.quantity * item.sendLimit;
            this.eStore.select(fromECard.getAvailableECards)
            .subscribe( ecards => {
              
               
                ecards.forEach(e => {
                  
                      if (e.id === item.id) {
                        let ecardSingle: ECardCustomise;
                        let cardCount = 0;
                        const subCardPack = {
                          id: e.id,
                          using: false,
                          pack: []
                        }
                        
                        do {
                          cardCount++;
                          
                          if (cardCount != ecardPack.length) {
                            // let id = this.afs.createId();
                            ecardSingle = {
                              customisedECardId: '',
                              totalSendLimit: 1,
                              recipient: [],
                              message: '',
                              signature: '',
                              ecard: e,
                            }
                            subCardPack.pack.push(ecardSingle);
                        }}
                         while( subCardPack.pack.length < newSendLimit);
                        ecardPack.push(subCardPack);
                        } else {
                          console.log("something bugged out...!")
                        }
                  }); // end of sorting fully customisable card packs into temp var this.ecardPack;
               ecards.forEach( e => { if(e.id === item.id) {
                 let ecardCustomise: ECardCustomise;
                  ecardCustomise = {
                   customisedECardId: '',
                   totalSendLimit: newSendLimit,
                   recipient: [],
                   message: '',
                  signature: '',
                  ecard: e
                 };
                 
                  this.checkoutECards.push(ecardCustomise);
               } else {
                 return;
               }}); // end of sorting for quick send
              
            })

        } else {
            // this.gStore.select(fromGift.get)
        }
    });
    // this.pending = this.checkoutECards;
    this.store.dispatch(new CheckoutActions.SetSelectedECards(this.checkoutECards));
    this.store.dispatch(new CheckoutActions.SetCustomisedECards(ecardPack));
    this.store.dispatch(new CheckoutActions.SetGifts(checkoutGifts));
    // console.log("checkout ecards: " + checkoutECards);
  }

  getContacts(start, end) {
    let isUID:string;
    fromPromise(this.afAuth.currentUser.then((user)=> {return isUID = user.uid}));
    let uid = isUID;
    // let userid: string = "";
    var contacts = this.afs
      .collection("users")
      .doc(uid)
      .collection("addressbook")
      .ref
    //   .where("contactSurname", "==", event)
    .startAt(start)
    .endAt(end)
      .orderBy("contactSurname");
    contacts.get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        
        return doc.data();
      });
    });
  }


  onUpdateECardPackItem(card: ECardCustomise, index) {
    console.log("is this working???");
    this.checkoutStore.dispatch(new CheckoutActions.UpdateCustomiseECard({ecard:card, index}))
  }

  onUpdatePack(id:string, isUsing: boolean ) {
    this.checkoutStore.dispatch(new CheckoutActions.UpdateIsUsing({id: id, isUsing: isUsing}));
  }

  onUpdateECard(ecardCustom: ECardCustomise) {
    let index: number;
    index = this.checkoutECards.indexOf(this.checkoutECards.find(e => e.ecard.id == ecardCustom.ecard.id));
    const ecardCustomCopy: ECardCustomise = {...ecardCustom};
    const ecardCopy ={...ecardCustomCopy.ecard};
    ecardCopy.customised = true;
    ecardCustomCopy.ecard = ecardCopy;
    console.log("chechoutService.onUpdateECard: ", ecardCustomCopy);
    this.checkoutStore.dispatch(new CheckoutActions.UpdateQuickSendECard({ecard: ecardCustomCopy, index:index}));
  }

  onEnvelope(ecardCustom: ECardCustomise) {
    
    this.appStore.select(fromApp.getSelectedECards).subscribe( res => {
      let next: ECardCustomise;
    let eIndex: number;  
    
    eIndex = res.indexOf(res.find(e => e.ecard.id == ecardCustom.ecard.id));
    
    next = res[eIndex+1];
  
    if (eIndex+1 === res.length) {
      this.advance.next(true);
      this.router.navigate(['checkout/summary']);
    } else if (next.ecard.id != '') {
      this.advance.next(false);
      this.router.navigate(['checkout/customise-ecards', next.ecard.id]);
    }
    
    })

  }

  onPermitConfirmPay():boolean {
    let check: boolean;
    // this.checkoutStore.subscribe(res => {
    //   let ecardCustomised = res.selectedECards;
    //   console.log("this.pending: " , this.pending);
    //   console.log("ecardCustomised: ", ecardCustomised);
      this.checkoutECards.forEach( e => {
        if (e.customisedECardId) {
          console.log('e: ',  e);
          this.pending.push(e);
        } else {
          return;
        }
      }
      );
     if (this.pending.length === this.checkoutECards.length) {
          return check = true;
        } else {
          return check = false;
        }  ;
     
      return check;
  }



  // make the orders which triggers the cloud functions
  onConfirmOrder(orderTotal:number) {

    let finalECards;    
    const orderId = this.afs.createId();
    let isUID:string = '';
    // fromPromise(this.afAuth.currentUser.then((user)=> {return isUID = user.uid})); // fromPromise no longer works
    //replacing with get from appStore
    this.appStore.select(fromApp.getIsUser).subscribe(user => {
      this.uid = user.uid;
    });
    // let uid = isUID;
    let now =  new Date();
    let userRealName: User;
    this.appStore.select(fromApp.getIsUser).subscribe(user => {
      return userRealName = user;
    })
console.log (this.uid, userRealName);
    this.appStore.select(fromApp.getCustomisedECards).subscribe( (res) => {
   console.log("line 329 supposed error: " , res);
      let ecardPackTouched = [];
      res.forEach( (p) => {
        if (p.using == true) {
          ecardPackTouched.push(p);
        
        } 
        else {
          // create a new owned ecard object for each quick send use case
          this.appStore.select(fromApp.getSelectedECards).subscribe(sortedECards => {
            finalECards = sortedECards;
            sortedECards.forEach(e => {
              e.dispatched = e.recipient.length; 
              if (e.dispatched === e.totalSendLimit) {
                e.dispatchedAll = true;
              } else {
                e.dispatchedAll = false;
              }
              e.orderId = orderId;
              
              // creates a new mailed ecard for each recipient in quick send. This triggers cloud mailer functions
               this.afs.collection("users").doc(this.uid).collection("ownedECards").doc(e.customisedECardId).set(e);
               e.recipient.forEach(r => {
                 const mailerId =  this.afs.createId();
                 const mail: Mailer = {
                  mailerId: mailerId,
                  uid: this.uid,
                  orderId: orderId,
                  ecard: e.ecard,
                  customisedECardId: e.customisedECardId,
                  message: e.message,
                  signature: e.signature,
                  contact: r,
                  creationDate: now,
                  from: userRealName.firstname + " " + userRealName.surname,
                  to: r.contactFirstName
                 } 
                 
                this.afs.collection("users").doc(this.uid).collection("mailedECards").doc(mailerId).set(mail);
                this.afs.collection("mailroom").doc(this.uid).collection("mailedECards").doc(mailerId).set(mail);
               })
            })
            const order: Order = {
              orderId: orderId,
              userId: this.uid,
              // basketItems: this.basketService.items.slice(),
              orderTotal: orderTotal,
              currency: "gbp",
              postage: null,
              customisedECards: finalECards,
              gifts: [],
              status: "complete",
              date: now
            }
        
            
            // ecard drawer where user can see all the made cards directory URLs
            // this.mailerService.onSendECardsRepo(uid, this.checkoutECards, orderId);
            // user order history
            this.afs.collection("users").doc(this.uid).collection("orders").doc(order.orderId).set(order);
            // may need to add in addresses etc to this if delivery required, an independant bucket of all orders for book keeping
            this.afs.collection("admin").doc("order-directory").collection("orders").doc(order.orderId).set(order);
        
          })
        };
        // if customise inideividual ecards in a pack is used, create a owned ecard object for remainder
      if (ecardPackTouched.length >= 1) {
        
        ecardPackTouched.forEach((ep: ECardPack) => {
          let hasRecipient: ECardCustomise[] = [];
          let hasNoRecipient: ECardCustomise[] = [];
          let hasDispatchedAll: boolean;
          let ecardObj: ECard = ep.pack[0].ecard;

          //push card singles to either hasRecipient or hasNoRecipient
          ep.pack.forEach( (e: ECardCustomise) => {
            //because each individual card can only be sent to one person, this is always 1
            if (e.recipient.length === 1) {
              hasRecipient.push(e);
            } else {
              hasNoRecipient.push(e);
            }
          });

          // check if all customised cards are being dispatched
         if (hasNoRecipient.length === 0) {
           // all are dispatched, do this
           hasDispatchedAll = true;
         } else {
           hasDispatchedAll = false;
         }

         const totalSendLimit = hasNoRecipient.length + hasRecipient.length;
         const dispatchedCount = hasRecipient.length;
         const eId = this.afs.createId();
       
         //compile ecard pack back into single object
         const ecardCustomiseObj: ECardCustomise = {
           customisedECardId : eId,
           totalSendLimit: totalSendLimit,
           dispatched: dispatchedCount,
           dispatchedAll: hasDispatchedAll,
           ecard: ecardObj,
           orderId: orderId
         };

         // for each hasRecipient single ecard, create a mailer object
         hasRecipient.forEach((e:ECardCustomise) => {
          e.recipient.forEach(r => {
          const mailerId =  this.afs.createId();
          const mail: Mailer = {
                mailerId: mailerId,
                uid: this.uid,
                orderId: orderId,
                ecard: e.ecard,
                customisedECardId: e.customisedECardId,
                message: e.message,
                signature: e.signature,
                contact: r,
                creationDate: now,
                from: userRealName.firstname + " " + userRealName.surname,
                to: e.to
                }

          this.afs.collection("users").doc(this.uid).collection("mailedECards").doc(mailerId).set(mail);
          this.afs.collection("mailroom").doc(this.uid).collection("mailedECards").doc(mailerId).set(mail);
          }
           )
         });
        
         console.log("CHECK?? Is this the ecards unit attached: ", hasRecipient);
         console.log("CHECK?? Is this the ecards unit attached: ", hasNoRecipient);
         console.log("Final option:: ...  ", ecardPackTouched);

         this.afs.collection("users").doc(this.uid).collection("ownedECards").doc(eId).set(ecardCustomiseObj);
         const order: Order = {
          orderId: orderId,
          userId: this.uid,
          // basketItems: this.basketService.items.slice(),
          orderTotal: orderTotal,
          currency: "gbp",
          postage: null,
          customisedECards: hasRecipient || hasNoRecipient || ecardPackTouched,
          gifts: [],
          status: "complete",
          date: now
        }
      
        // ecard drawer where user can see all the made cards directory URLs
        // this.mailerService.onSendECardsRepo(uid, this.checkoutECards, orderId);
        // user order history
        this.afs.collection("users").doc(this.uid).collection("orders").doc(order.orderId).set(order);
        // may need to add in addresses etc to this if delivery required, an independant bucket of all orders for book keeping
        this.afs.collection("admin").doc("order-directory").collection("orders").doc(order.orderId).set(order);
    

        })
        
      } else {return;}
      });
      
    })
    
        // create a new owned ecard object for each
    // this.appStore.select(fromApp.getSelectedECards).subscribe( sortedECards => {
    //   finalECards = sortedECards;
    //   sortedECards.forEach(e => {
    //     e.dispatched = e.recipient.length; 
    //     if (e.dispatched === e.totalSendLimit) {
    //       e.dispatchedAll = true;
    //     } else {
    //       e.dispatchedAll = false;
    //     }
    //     e.orderId = orderId;
    //      this.afs.collection("users").doc(uid).collection("ownedECards").doc(e.customisedECardId).set(e);
    //      e.recipient.forEach(r => {
    //        const mailerId =  this.afs.createId();
    //        const mail: Mailer = {
    //         mailerId: mailerId,
    //         uid: uid,
    //         orderId: orderId,
    //         ecard: e.ecard,
    //         customisedECardId: e.customisedECardId,
    //         message: e.message,
    //         signature: e.signature,
    //         contact: r,
    //         creationDate: now,
    //         from: userRealName.firstname + " " + userRealName.surname,
    //        } 
    //        console.log( "Check mail for errors: " , mail);
    //       this.afs.collection("users").doc(uid).collection("mailedECards").doc(mailerId).set(mail);
    //      })
    //   })
    // });
    
  //  this.analytics.logEvent('purchase');


  }

  onClearCheckout():void {
    this.basketService.items = [];
    this.basketService.basketTotal = 0;
    this.basketService.itemsChanged.next([]);
    this.checkoutStore.dispatch(new CheckoutActions.EmptyCheckout());
  }


}
