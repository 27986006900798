import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { ECardService } from '../e-card-catalogue/e-card.service';
import { AuthenticateService } from '../auth/authenticate.service';

@Injectable()
export abstract class StorageService {

public abstract get(): Storage;

    constructor(
        // private http: Http,
                private ecardService: ECardService,
                private authService: AuthenticateService) {}



storeECards() {
    // this.http.put('https://lyrical-days.firebaseio.com/ecards.json',
                    // this.ecardService.getECards());
//     return this.http.post('https://lyrical-days.firebaseio.com/ecards.json', ecards);
}

storeUserData() {
    
}

}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class LocalStorageService extends StorageService {
    public get(): Storage {
        return localStorage;
    }
}