import { Action } from '@ngrx/store';
import { BasketItem } from '../../basket/basket-item.model';
import { ECard } from '../../e-card-catalogue/e-card.model';
import { Gift } from '../../gift-catalogue/gift.model';
import { ECardCustomise, ECardPack } from '../customise-e-card/customise-ecard.model';

export const SUCCESS = 'SUCCESS';

export const SET_CHECKOUTBASKET = 'SET_CHECKOUTBASKET';
export const SET_SELECTED_ECARDS = 'SET_SELECTED_ECARDS';
export const SET_GIFTS = 'SET_GIFTS';
export const SET_CUSTOMISED_ECARDS = 'SET_CUSTOMISED_ECARDS';
export const UPDATE_QUICK_SEND_ECARD = 'UPDATE_QUICK_SEND_ECARD';
export const UPDATE_IS_USING = 'UPDATE_IS_USING';
export const UPDATE_CUSTOMISE_ECARD = 'UPDATE_CUSTOMISE_ECARD';
export const ADD_NEW_SELECTED_ECARD = 'ADD_NEW_SELECTED_ECARD';
export const ADD_NEW_CUST_ECARD = 'ADD_NEW_CUST_ECARD';
export const EMPTY_CHECKOUT = 'EMPTY_CHECKOUT';

export class SetCheckoutBasket implements Action {
    readonly type = SET_CHECKOUTBASKET;

    constructor(public payload: BasketItem[]) {}
}

export class SetSelectedECards implements Action {
    readonly type = SET_SELECTED_ECARDS;

    constructor(public payload: ECardCustomise[]) {}
}

export class SetGifts implements Action {
    readonly type = SET_GIFTS;

    constructor(public payload: Gift[]) {}
}

export class SetCustomisedECards implements Action {
    readonly type = SET_CUSTOMISED_ECARDS;

    constructor(public payload: any) {}
}

export class UpdateQuickSendECard implements Action {
    readonly type = UPDATE_QUICK_SEND_ECARD;

    constructor(public payload:{ecard: ECardCustomise, index:number}) {}
}

export class UpdateIsUsing implements Action {
    readonly type = UPDATE_IS_USING;

    constructor(public payload:{id: string, isUsing:boolean}) {}
}

export class UpdateCustomiseECard implements Action {
    readonly type = UPDATE_CUSTOMISE_ECARD;

    constructor(public payload:{ecard: ECardCustomise, index:number}) {}
}

export class AddNewSelectedECard implements Action {
    readonly type = ADD_NEW_SELECTED_ECARD;

    constructor(public payload: ECardCustomise) {}
}

export class AddNewCustECard implements Action {
    readonly type = ADD_NEW_CUST_ECARD;

    constructor(public payload: ECardPack) {}
}

export class EmptyCheckout implements Action {
    readonly type= EMPTY_CHECKOUT
}


export type CheckoutActions = SetCheckoutBasket | 
SetSelectedECards | 
SetGifts | 
SetCustomisedECards |
UpdateQuickSendECard |
UpdateIsUsing |
UpdateCustomiseECard |
AddNewSelectedECard |
AddNewCustECard |
EmptyCheckout;