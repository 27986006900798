import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';

import { ECard } from '../e-card.model';
import { ECardService } from '../e-card.service';

@Component({
  selector: 'app-send-e-card',
  templateUrl: './send-ecard.component.html',
  styleUrls: ['./send-ecard.component.css']
})
export class SendEcardComponent implements OnInit {
ecard: ECard;
id: string;
sendECard: FormGroup;

  constructor(private ecardService: ECardService,
              private route: ActivatedRoute ) { }

  ngOnInit() {
    this.route.params
    .subscribe(
      (params: Params) => {
        this.id = params['id'];
        // this.ecard = this.ecardService.getECard(this.id);
      });
      this.sendECard = new FormGroup({
        'recipient(s)': new FormControl(null, Validators.required),
        'message': new FormControl(null),
        'schedule': new FormControl(null)
      })
      }
  
      onSendECard() {}
}
