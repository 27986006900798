import { Injectable } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { fromPromise } from 'rxjs/observable/fromPromise';

import { MetaService } from '@ngx-meta/core';
import { tap } from "rxjs/operators";
import { ECard } from "../e-card-catalogue/e-card.model";

export interface seoData {
    name: string;
    twitter_large_image: "summary_large_image" | "summary";
    url: string;
    title: string;
    desc: string;
    imageURL: string;
}

@Injectable()
export class SEOService {

    constructor(private titleService: Title, 
                private meta: Meta,
                // private readonly meta: MetaService,
                private afs: AngularFirestore) {}

generateTags(tags) {
tags = {
    title: 'LyricalDays.com',
    description: 'Send something special. Send a Lyrical Days ecard.',
    image: 'https://firebasestorage.googleapis.com/v0/b/lyrical-days.appspot.com/o/branding%2FSpring-Summer.jpg?alt=media&token=bbc4d4ef-1f02-4422-96e0-68b5d7428bc0',
    slug: 'https:lyricaldays.com',
    ...tags
}

this.titleService.setTitle(tags.title);


//         this.meta.updateTag(
//             {name: "twitter:card", content: "summary_large_image" || "summary"}
//         );
//         this.meta.updateTag(
//             {name: "twitter:site", content: "@itsLyricalDays"}
//         );
//         this.meta.updateTag(
//             {name: "twitter:title", content: tags.name}
//         );
//         this.meta.updateTag(
//             {name: "twitter:description", content: tags.description}
//         );
//         this.meta.updateTag(
//             {name: "twitter:image", content: tags.image}
//         );
//         this.meta.updateTag(
//             {property: "og:type", content: "article"}
//         );
//         this.meta.updateTag(
//             {property: "og:site_name", content: "Lyrical Days"}
//         );
//         this.meta.updateTag(
//             {property: "og:url", content: tags.slug}
//         );
//         this.meta.updateTag(
//             {property: "og:title", content: tags.name}
//         ) ;
//         this.meta.updateTag(
//             {property: "og:description", content: tags.description}
//         ) ;
//         this.meta.updateTag(
//             {property: "og:image", content: tags.image}
//         )
//         this.meta.updateTag(
//             {property: "og:url", content: tags.slug}
//         )
}                

//     updateTitle(title: string) {
//     this.title.setTitle(title);
//     }

//     updateOgUrl(url: string) {
//         // this.meta.updateTag({ name: 'og:url', content: url })
//     }

//     updateDescription(desc: string) {
//         // this.meta.updateTag({ name: 'description', content: desc })       
//     }  
    
    onSSROneECard(id: string) {
        console.log("running SSRECARD fetch with id: " ,id);
        return fromPromise(
            this.afs.collection("ecards")
            .doc(id)
            .ref
            .get()
            .then(
                (doc) => { 
                    return {
                        name: doc.data()["name"],
                        desc: doc.data()["synopsis"],
                        mediaItem: doc.data()["thumbnail"]}
                }))
                .subscribe( 
                    ( data1 )=> {
                        console.log("ssr field lookup: " ,data1);
                const data: seoData = {
                        name: "Lyrical Days | " + data1.name,
                        twitter_large_image: "summary_large_image",
                        url: 'https://lyricaldays.com/ecards' + '/' + id + '/' + data1.name.replace(/\s+/g, '-').toLowerCase(),
                        title: data1.name,
                        desc: data1.desc ,
                        imageURL: data1.mediaItem.downloadURL
            };
            console.log("Data to meta tags: " + data.desc.toString(), data.name.toString(), data.url.toString(), data.imageURL.toString(), '"' + data.twitter_large_image + '"');
            this.titleService.setTitle( "Lyrical Days | " + data1.name  );
            this.meta.updateTag(
                {name: "twitter:card", content: data.twitter_large_image.toString()}
            );
            this.meta.updateTag(
                {name: "twitter:title", content:  "Lyrical Days | " + data1.name }
            );
            this.meta.updateTag(
                {name: "twitter:description", content: data.desc.toString() }
            );
            this.meta.updateTag(
                {name: "twitter:image", content: data.imageURL.toString() }
            );
            this.meta.updateTag(
                {property: "og:url", content:  data.url.toString()}
            );
            this.meta.updateTag(
                {property: "og:title", content:  data.title.toString() }
            ) ;
            this.meta.updateTag(
                {property: "og:description", content: data.desc.toString() }
            ) ;
            this.meta.updateTag(
                {property: "og:image", content: data.imageURL.toString() }
            )
            // ssr get to setSEO meta from here otherwise it draws from whole database with read/writes  
        });
    }  
    
//     onInitSEO() {
//         // console.log("on Init SEO is being called!")
//         // this.title.setTitle("LyricalDays.com");
//         // this.meta.updateTag(
//         //     {name: "twitter:card", content: "summary_large_image"},
//         // );
//         // this.meta.updateTag(
//         //    {name: "twitter:site", content: "@itsLyricalDays"},
//         // );
//         // this.meta.updateTag(
//         //     {name: "twitter:title", content: "LyricalDays.com"},
//         // );
//         // this.meta.updateTag(
//         //     {name: "twitter:description", content: "Send something special. Send a Lyrical Days ecard."},
//         // );
//         // this.meta.updateTag(
//         //     {name: "twitter:image", content: "https://firebasestorage.googleapis.com/v0/b/lyrical-days.appspot.com/o/branding%2FSpring-Summer.jpg?alt=media&token=bbc4d4ef-1f02-4422-96e0-68b5d7428bc0"},
//         // );
//         // this.meta.updateTag(
//         //     {property: "og:url", content: 'lyricaldays.com'},
//         // );
//         // this.meta.updateTag(
//         //     {property: "og:title", content: "Lyrical Days"},
//         // ) ;
//         // this.meta.updateTag(
//         //     {property: "og:description", content: "Send something special. Send a Lyrical Days ecard."},
//         // ) ;
//         // this.meta.updateTag(
//         //     {property: "og:image", content: 'https://firebasestorage.googleapis.com/v0/b/lyrical-days.appspot.com/o/branding%2FSpring-Summer.jpg?alt=media&token=bbc4d4ef-1f02-4422-96e0-68b5d7428bc0'}

//         // )
//     }

onSetSEO(data:seoData) {
        // console.log("Data for seo: ", data)

        this.titleService.setTitle(data.title);
        this.meta.updateTag(
            {name: "twitter:card", content: data.twitter_large_image || "summary"}
        );
        this.meta.updateTag(
            {name: "twitter:title", content: data.name}
        );
        this.meta.updateTag(
            {name: "twitter:description", content: data.desc}
        );
        this.meta.updateTag(
            {name: "twitter:image", content: data.imageURL}
        );
        this.meta.updateTag(
            {property: "og:url", content: data.url}
        );
        this.meta.updateTag(
            {property: "og:title", content: data.title}
        ) ;
        this.meta.updateTag(
            {property: "og:description", content: data.desc}
        ) ;
        this.meta.updateTag(
            {property: "og:image", content: data.imageURL}
        )
}            




}