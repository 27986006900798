import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import { BasketService } from '../../basket/basket.service';

import * as fromApp from '../../store/app.reducers';
import * as fromAuth from '../../auth/store/auth.reducers';
import * as AuthActions from '../../auth/store/auth.actions';
import { User } from '../../shared/user.model';
import { Subscription } from 'rxjs';
import { CurrencyService } from '../../shared/currency.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  itemCount: number;
  brandMainLogo: string = 'assets/brand/lyrical-days-logo.png';
  authState: Observable<fromAuth.State>;
  isAdmin: boolean;
  currency: string;
  isAuth: boolean;
  currencySubs: Subscription;
  isValentines: boolean = false;
  isChristmas: boolean = false;
  isLunar: boolean = false;

  birthdayActive = false;
  anniversaryActive = false;
  thankyouActive = false;
  valentinesActive = false;
  christmasActive =  false;
  lunarActive = false;

  onCurrency(currency) {
    if (currency == 'gbp') {
      this.currency = 'usd';
    } else if (currency == 'usd') {
      this.currency = 'eur';
    } else {
      this.currency = 'gbp';
    };
    console.log(currency);
    this.currencyService.onChangeCurrency(this.currency);
  }


  birthdaySelected() {
    this.birthdayActive = true;
    this.anniversaryActive = false;
    this.thankyouActive = false;
    this.valentinesActive = false;
    this.christmasActive = false;
    this.lunarActive = false;
    this.router.navigate(['/ecards', 'Birthday']);
  }

  anniversarySelected() {
    this.birthdayActive = false;
    this.anniversaryActive = true;
    this.thankyouActive = false;
    this.valentinesActive = false;
    this.christmasActive = false;
    this.lunarActive = false;
    this.router.navigate(['/ecards', 'Anniversary']);
  }

  thankyouSelected() {
    this.birthdayActive = false;
    this.anniversaryActive = false;
    this.thankyouActive = true;
    this.valentinesActive = false;
    this.christmasActive = false;
    this.lunarActive = false;
    this.router.navigate(['/ecards', 'Thank-you']);
  }

  valentinesSelected() {
    this.birthdayActive = false;
    this.anniversaryActive = false;
    this.thankyouActive = false;
    this.valentinesActive = true;
    this.christmasActive = false;
    this.lunarActive = false;
    this.router.navigate(['/ecards', 'Valentines']);
  }

  christmasSelected() {
    this.birthdayActive = false;
    this.anniversaryActive = false;
    this.thankyouActive = false;
    this.valentinesActive = false;
    this.christmasActive = true;
    this.lunarActive = false;
    this.router.navigate(['/ecards', 'Christmas']);
  }

  lunarSelected() {
    this.birthdayActive = false;
    this.anniversaryActive = false;
    this.thankyouActive = false;
    this.valentinesActive = false;
    this.christmasActive = false;
    this.lunarActive = true;
    this.router.navigate(['/ecards', 'Lunar-new-year']);
  }

  onNoOcc() {
    this.birthdayActive = false;
    this.anniversaryActive = false;
    this.thankyouActive = false;
    this.valentinesActive = false;
    this.christmasActive = false;
    this.lunarActive = false;
  }


 
  // authenticate: Observable<boolean> = this.store.select('auth')
  //                                     .map((authState: fromAuth.State) => {
  //                                     return authState.authenticated
  //                                     });

  constructor( private basketService: BasketService,
              private currencyService: CurrencyService,
              private router: Router,
              private store: Store<fromApp.AppState>) { }

  ngOnInit() {
    console.log(this.currentDay)
    this.onSeasonalDisplay();
    this.currencyService.currency.subscribe((currency) => {
      this.currency = currency;
    })
     this.store.select(fromApp.getIsAuth).subscribe( res => {
      // console.log("res from header: " + res);
      this.isAuth = res;
    });
    // hide admin button if user is not admin
    this.store.select(fromApp.getIsUser).subscribe(user => {
      if (this.isAuth) {
        if (user.roles.admin == false) {
          this.isAdmin = false;
        } else if (user.roles.admin == true) {
          this.isAdmin = true;
        }
      } else {
        this.isAdmin = false;
      }
     
    })
      
  }


  checkLogInStatus() { 
    // console.log('here');
    // this.store.dispatch(new AuthActions.TryUser);
    }

  itemsInBasket() {
    this.itemCount = this.basketService.getBasketItems().map((x) => x.quantity).reduce((p, n) => p + n, 0);
    this.basketService.basketTotalItems = this.itemCount;
    if (this.itemCount > 0) {
        return true;
    }
    else {
      return false;
    }
    
  }

private currentMonth;
private currentDay:number = new Date().getDate();


onSeasonalDisplay() {
  console.log( this.currentDay , "this.currentDay");
  const formatter = new Intl.DateTimeFormat('en', { month: 'numeric' });
  const formatterDate = new Intl.DateTimeFormat('en', { day: 'numeric' });
  let currentDaay: Number = Date.parse(new Date('d').toString());
  console.log( "formatterDate",  formatterDate);
  const month = formatter.format(new Date());
  this.currentMonth = month;
  switch (true) {
    case this.currentMonth == '1' && this.currentDay > 20 || this.currentMonth == '2' && this.currentDay <= 15:
      this.isValentines = true;
      this.isChristmas = false;
    case 
    this.currentMonth == '1' && this.currentDay <= 7 || 
    this.currentMonth == '11' && this.currentDay >= 25 || 
    this.currentMonth == '12':
    console.log("isChristmas? ", this.isChristmas)  
    this.isChristmas = true;
    case this.currentMonth == '1' && this.currentDay > 20 || this.currentMonth == '2' && this.currentDay <= 21:
      this.isLunar = true;
  }
}


}
