import { isPlatformBrowser } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';

import { ECard } from '../../e-card-catalogue/e-card.model';
import { ECardService } from '../../e-card-catalogue/e-card.service';
import { Store } from "@ngrx/store";

import * as fromECard from '../../e-card-catalogue/store/ecard.reducers';

@Component({
  selector: 'app-essentials',
  templateUrl: './essentials.component.html',
  styleUrls: ['./essentials.component.css']
})
export class EssentialsComponent implements OnInit, AfterViewInit {

// @ViewChild("canvas") canvas: ElementRef;  
//  w: any;
//  h: any;
//  particlesOnScreen = 245;
//  particlesArray = [];
//  ctx: any;
//  canvasAlt: any;

 private latestEcards: ECard[] = [];

 runFetch: boolean = false;

  constructor(
    private elRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: object,
    private ecardService: ECardService,
    // private seoService: SEOService,
    private store: Store<fromECard.State>
    ) { }

    ngOnInit(): void {
      this.checkStore();
      if (this.runFetch) {
      this.ecardService.fetchECards();
      } else {
        console.log("ecards found!")
        this.store.select(fromECard.getAvailableECards)
        .subscribe( (res) => {
          console.log("Running latest ecards pull: ", res.length);
          this.latestEcards = res.slice(0, 6);
        });
      }
      // this.seoService.generateTags({
      //   name: "Shop our ecard collection",
      //   slug: 'https://lyricaldays.com/ecards',
      //   title: 'Shop Ecards',
      //   description: 'Browse our ever growing collection of ecards for something that would suit your occasion.',
      //   image: 'https://firebasestorage.googleapis.com/v0/b/lyrical-days.appspot.com/o/branding%2FSpring-Summer-super.jpg?alt=media&token=80bbb181-a8c3-49a5-8de7-39199c854ad3'
      // }) ;
    }
  
    checkStore() {
      // console.log("running in essentials: check store()")
      this.store.select(fromECard.getAvailableECards)
      .subscribe( (res) => {
        // console.log("res in checkStore: ", res);
      if (res.length < 1) {
        // console.log("no ecards in found in check store: " , res);
        return this.runFetch = true;
      }
      else {
        // console.log("ecards in found in check store! " , res);
        return this.runFetch = false;
      }
      });
    }
  ngAfterViewInit() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     console.log("running snow...");
  //     console.log("starting up...");
  //     setTimeout( () => {
  //       if (this.canvas) {
  //     this.ctx = this.canvas.nativeElement.getContext("2d") || null;
  //     this.w = this.canvas.nativeElement.width = window.innerWidth;
  //     this.h = this.canvas.nativeElement.height = window.innerHeight;
  //     window.addEventListener("resize", this.clientResize);
  //     // this.canvas = this.elRef.nativeElement.querySelector('canvas');
  //     console.log("viewChild: ", this.canvas.nativeElement.getContext("2d"));
  //     this.createSnowFlakes();
  //     console.log("createSnowFlakes: ", this.particlesArray);
  //     console.log("Drawing snowflakes..." );
  //     this.drawSnowFlakes();
  //     console.log("Drawing snowflakes...Some more..." );
      
  //     setInterval( () => {
  //     this.ctx.clearRect(0, 0, this.w, this.h);
  //     this.drawSnowFlakes();
  //     this.moveSnowFlakes();
  //     }, 50);
  //     this.createSnowFlakes();
  //   }
  // }
  //   , 1000);
  //    }   
  }


//   randomizeSnow(min, max) {
//     return min + Math.random() * (max - min + 1);
// };

// clientResize(ev) {
//   this.w = this.canvas.nativeElement.width = window.innerWidth;
//   this.h = this.canvas.nativeElement.height = window.innerHeight;
// };

// createSnowFlakes() {
//   for(var i = 0; i < this.particlesOnScreen; i++){
//       this.particlesArray.push({
//           x: Math.random() * this.w,  
//           y: Math.random() * this.h,  
//           opacity: Math.random(),  
//           speedX: this.randomizeSnow(-11, 11),  
//           speedY: this.randomizeSnow(7, 15),    
//           radius: this.randomizeSnow(0.5, 4.2),
//       })
//   }
// };

// drawSnowFlakes() {
//   for(var i = 0; i < this.particlesArray.length; i++){    
//       var gradient = this.ctx.createRadialGradient(  
//         this.particlesArray[i].x,   
//         this.particlesArray[i].y,   
//           0,                     
//           this.particlesArray[i].x,   
//           this.particlesArray[i].y,   
//           this.particlesArray[i].radius  
//           );

//           gradient.addColorStop(0, "rgba(255, 255, 255," + this.particlesArray[i].opacity + ")");  // white
//           gradient.addColorStop(.8, "rgba(210, 236, 242," + this.particlesArray[i].opacity + ")");  // bluish
//           gradient.addColorStop(1, "rgba(237, 247, 249," + this.particlesArray[i].opacity + ")");   // lighter bluish
        
//           this.ctx.beginPath(); 
//           this.ctx.arc(
//             this.particlesArray[i].x,  
//             this.particlesArray[i].y,  
//             this.particlesArray[i].radius,  
//           0,                         
//           Math.PI*2,                 
//           false                     
//           );

//           this.ctx.fillStyle = gradient;   
//           this.ctx.fill();                 
//   }
// };

// moveSnowFlakes() {
//   for (var i = 0; i < this.particlesArray.length; i++) {
//     this.particlesArray[i].x += this.particlesArray[i].speedX;     
//     this.particlesArray[i].y += this.particlesArray[i].speedY;     

//       if (this.particlesArray[i].y > this.h) {                                                                               
//         this.particlesArray[i].x = Math.random() * this.w * 1.5;
//         this.particlesArray[i].y = -50;
//       }
//   }
// };




}
