import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAnalyticsModule, CONFIG, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { HttpClientModule } from '@angular/common/http';

import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
// import { HttpModule } from "@angular/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BasketComponent } from './basket/basket.component';
import { BasketItemComponent } from './basket/basket-item/basket-item.component';
import { BasketListComponent } from './basket/basket-list/basket-list.component';
import { SearchComponent } from './search/search.component';
// import { CheckoutComponent } from './checkout/checkout.component';
import { HelpDeskComponent } from './admin/help-desk/help-desk.component';
import { SendEcardComponent } from './e-card-catalogue/send-ecard/send-ecard.component';
// import { PostageComponent } from './checkout/postage/postage.component';
import { reducers } from './store/app.reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';


import { AuthEffects } from './auth/store/auth.effects';
import { BasketEffects } from './basket/store/basket.effects';

import { AdminFeatureModule } from './admin/admin.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';

// import { UsersEffects } from './shared/store/users.effects';
import { UserService } from './shared/user.service';
import { AddressbookService } from './user-area/user-settings/address-book/addressbook.service';
import { CheckoutService } from './checkout/checkout.service';
import { BasketService } from './basket/basket.service';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
// import {FunctionsRegionToken } from '@angular/fire/functions/'
import { LyricalMailComponent } from './lyrical-mail/lyrical-mail.component';
import { AccountService } from './user-area/user-settings/account-settings/account.service';
import { MailerService } from './checkout/mailer.service';
import { OrdersService } from './user-area/user-settings/order-history/orders.service';
import { OwnedECardsService } from './user-area/user-settings/your-ecards/owned-ecards.service';
import { PrivacyPolicyComponent } from './legal/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './legal/terms-conditions/terms-conditions.component';
import { CookiesPolicyComponent } from './legal/cookies-policy/cookies-policy.component';

import { MetaModule, MetaLoader, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
// import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { SEOService } from './shared/seo.service';
import { SupplyConditionsComponent } from './legal/supply-conditions/supply-conditions.component';
import { AcceptableUsePolicyComponent } from './legal/acceptable-use-policy/acceptable-use-policy.component';
import { AboutComponent } from './core/about/about.component';
import { ContactComponent } from './core/contact/contact.component';
import { OccCatTagService } from './admin/product-management/occ-cat-tag/occ-cat-tag.service';
import { ServiceWorkerModule } from '@angular/service-worker';

// import { NgxHotjarModule } from 'ngx-hotjar';

import { SnowfModule } from 'ngx-snowf';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { ECardFeatureModule } from './e-card-catalogue/e-card.module';
import { ECardCatalogueComponent } from './e-card-catalogue/e-card-catalogue.component';
import { ECardItemComponent } from './e-card-catalogue/e-card-list/e-card-item/e-card-item.component';
import { ECardListComponent } from './e-card-catalogue/e-card-list/e-card-list.component';
import { ECardDetailComponent } from './e-card-catalogue/e-card/e-card-detail.component';
import { ECardRoutingModule } from './e-card-catalogue/e-card-routing.module';
import { CurrencyService } from './shared/currency.service';
// import { SortableColumnComponent } from './shared/sortable-column.directive';

// const cookieConfig:NgcCookieConsentConfig = {
//   cookie: {
//     domain: environment.domain // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
//   },
//   palette: {
//     popup: {
//       background: '#4866a8'
//     },
//     button: {
//       background: '#a9c6f3'
//     }
//   },
//   theme: 'edgeless',
//   type: 'opt-out'
// };

// export function metaFactory(): MetaLoader {
//   return new MetaStaticLoader({
//     pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
//     pageTitleSeparator: ' - ',
//     applicationName: 'Tour of (lazy/busy) heroes',
//     defaults: {
//       title: 'Mighty mighty mouse',
//       description: 'Mighty Mouse is an animated superhero mouse character',
//       'og:image': 'https://upload.wikimedia.org/wikipedia/commons/f/f8/superraton.jpg',
//       'og:type': 'website',
//       'og:locale': 'en_UK',
//       'og:locale:alternate': 'en_US,nl_NL,tr_TR'
//     }
//   });
// }

@NgModule({
  declarations: [
    AppComponent,
    BasketComponent,
    BasketItemComponent,
    BasketListComponent,
    SearchComponent,
    // CheckoutComponent,
    HelpDeskComponent,
    SendEcardComponent,
    // NgxHotjarModule,
    LyricalMailComponent,
   
    PrivacyPolicyComponent,
   
    TermsConditionsComponent,
   
    CookiesPolicyComponent,
   
    SupplyConditionsComponent,
   
    AcceptableUsePolicyComponent,
   
    AboutComponent,
   
    ContactComponent,
    // PostageComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    // NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserAnimationsModule,
    AppRoutingModule,  
    AuthModule,
    AdminFeatureModule,
    MaterialModule,
    CoreModule,
    SharedModule,
    // ECardRoutingModule,
    // ECardFeatureModule,
    // GiftFeatureModule,
    HttpClientModule,
    // HttpModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFirestoreModule.enablePersistence({synchronizeTabs: true, }),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([AuthEffects, BasketEffects]),
    // StoreRouterConnectingModule.forRoot(),
    // AppRoutingModule,
    // MetaModule.forRoot({
    //   provide: MetaLoader,
    //   useFactory: (metaFactory)
    // }),
    // !environment.production ? StoreDevtoolsModule.instrument({maxAge: 25}) : [],
    // BrowserModule.withServerTransition({ appId: 'lyrical-days' }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // NgxHotjarModule.forRoot(environment.hj.trackingCode),
    ScullyLibModule,
  ],
  providers: [
    CurrencyService,
    UserService,
    SEOService, 
    AccountService, 
    AddressbookService, 
    CheckoutService, 
    BasketService, 
    MailerService, 
    OwnedECardsService,
    OccCatTagService,
    ScreenTrackingService, 
    UserTrackingService,
    { provide: REGION, useValue: 'us-central1' },
    // { provide: CONFIG, useValue: {
    //   send_page_view: false,
    //   allow_ad_personalization_signals: false,
    //   anonymize_ip: true
    // }}
  ],              
  bootstrap: [AppComponent]
})
export class AppModule { }
