import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Router,  } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument, } from '@angular/fire/compat/firestore';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/do';
import { fromPromise } from 'rxjs/observable/fromPromise';
// import { mergeMap } from 'rxjs/operators/mergeMap'; with RXJS 6+ and no rxjs-compat //
import * as firebase from 'firebase/app';
import 'firebase/auth';

import * as AuthActions from './auth.actions';
import * as fromAuth from './auth.reducers';
import * as UserActions from '../../shared/store/users.actions';
import { take } from 'rxjs-compat/operator/take';
import { User } from '../../shared/user.model';
import { concat } from 'rxjs/operators';


@Injectable()
export class AuthEffects {
    // @Effect()
    // authSignup = this.actions$
    //             .ofType(AuthActions.TRY_SIGNUP)
    //             .map( (action: AuthActions.TrySignup) => {
    //                 return action.payload;
    //             })
    //             .switchMap( (authData: {username: string, password: string}) => {
    //                 return fromPromise(firebase.auth()
    //                 .createUserWithEmailAndPassword(authData.username, authData.password)
    //                 )
    //             })
    //             .switchMap( () => {
    //                 return fromPromise(firebase.auth().currentUser.getIdToken());
    //             })
    //             .mergeMap( (token: string) => {
    //                 this.router.navigate(['/user']);
    //                 return [
    //                     {
    //                         type: AuthActions.SIGNUP
    //                     },
    //                     {
    //                         type: AuthActions.SET_TOKEN,
    //                         payload: token
    //                     }
    //                 ]
    //             });
    
    
    // @Effect()
    // authLogin = this.actions$
    //             .ofType(AuthActions.TRY_LOGIN)
    //             .map( (action: AuthActions.TryLogin) => {
    //                 return action.payload;
    //             })
    //             .switchMap( (authData: {username: string, password: string}) => {
    //                 return fromPromise(firebase.auth()
    //                 .signInWithEmailAndPassword(authData.username, authData.password));
    //             })
    //             .switchMap( () => {
    //                 return fromPromise(firebase.auth().currentUser.getIdToken());
    //             })
    //             .mergeMap( (token: string) => {
    //                 let userid = firebase.auth().currentUser.uid;
    //                 // console.log("Token recieved: " + token, " Userid: " + userid);
    //                 this.router.navigate(['/user']);
                    
    //                 return [
    //                     {
    //                         type: AuthActions.LOGIN
    //                     },
    //                     {
    //                         type: AuthActions.SET_TOKEN,
    //                         payload: token
    //                     },
    //                     // {
    //                     //     type: UserActions.GET_USER,
    //                     //     payload:  userid
    //                     // },
    //                     // new UserActions.GetUser(userid)
    //                 ]
    //             });


    // // for Auth guard only 
    // @Effect({dispatch: false})
    // tryUser = this.actions$
    //         .ofType(AuthActions.TRY_USER)
    //         .map((action: AuthActions.TryUser) => {
    //             return action.payload
    //         })
    //         .do( (data: boolean) => {
    //             if (data === false) {
    //                 this.router.navigate(['/login']);
    //             }
    //             else {
    //                 let userid = firebase.auth().currentUser.uid;
    //                 // this.router.navigate(['/user']);
    //                 return [
    //                     // {
    //                     //     type: UserActions.GET_USER,
    //                     //     payload:  userid
    //                     // },
    //                     {
    //                         type: AuthActions.USER
    //                     }
                       
    //                 ]
    //             }
    //         });


    
    // @Effect({dispatch: false})
    // authLogout = this.actions$
    //             .ofType(AuthActions.LOGOUT)
    //             .do( () => {
    //                 var clearCurrentUser = firebase.auth().currentUser;
    //                 console.log("To clear: ", clearCurrentUser)
    //                 clearCurrentUser = null;
    //                 console.log("Clearing attenpt: ", clearCurrentUser)
    //                 firebase.auth().signOut();
    //                 this.router.navigate(['/']);
    //             });

    constructor(private actions$: Actions, 
                private router: Router,
                private afs: AngularFirestore) {

    }
}