import { Action } from '@ngrx/store';

import { User } from '../user.model';


export const QUERY_USER = '[USER] Query User'; // used to be => export const FETCH_USERS = 'FETCH_USERS';



export const ADD_USER = '[USER] Add User';
export const GET_USER = '[USER] Get User';
export const UPDATE_USER = '[USER] Update User';
export const DELETE_USER = '[USER]  Delete User';

export const UPDATE_USER_PERSONAL_DETAILS = 'UPDATE_USER_PERSONAL_DETAILS';

export const SET_USERS = 'SET_USERS'; // may remove
export const SET_USER = 'SET_USER';

export const TRY_USER = 'TRY_USER';

export const SUCCESS_USER = 'SUCCESS_USER';

export const LOGOUT_USER = 'LOGOUT_USER';

export class TryUser implements Action {
    readonly type = TRY_USER;

}

export class GetUser implements Action {
    readonly type = GET_USER

    constructor(public payload: string) {} // the was a string of the user id, now just user object
}

export class AddUser implements Action {
    readonly type = ADD_USER;

    constructor(public payload: User) {}
}

export class DeleteUser implements Action {
    readonly type = DELETE_USER;

    constructor(public payload: User) {}
}

// may be able to remove SetUsers class
export class SetUsers implements Action {
    readonly type = SET_USERS;

    constructor(public payload: User[]) {}
}

export class SetUser implements Action {
    readonly type = SET_USER;

    constructor(public payload: User) {}
}

export class UpdateUser implements Action {
    readonly type = UPDATE_USER;

    constructor(
        public id: string, 
        public payload :User,
        ) {}
}

export class UpdateUserPersonalDetails implements Action {
    readonly type = UPDATE_USER_PERSONAL_DETAILS;

    constructor(public payload: {name: string, surname: string}) {}
}

export class SuccessUser implements Action {
    readonly type = SUCCESS_USER;

    constructor() {}
}

export class LogoutUser implements Action {
    readonly type = LOGOUT_USER;

    constructor() {}
}

export type UserActions = 
TryUser |
SetUsers |
SetUser |
GetUser |
AddUser |
DeleteUser |
UpdateUser |
SuccessUser| 
UpdateUserPersonalDetails |
LogoutUser;