import { Action } from '@ngrx/store';
// import { ECard } from '../e-card.model';

export const SUCCESS = 'SUCCESS';

export const TRY_OCCS = 'TRY_OCCS';
export const TRY_CATS = 'TRY_CATS';
export const TRY_TAGS = 'TRY_TAGS';

export const SET_OCCS = 'SET_OCCS';
export const SET_CATS = 'SET_CATS';
export const SET_TAGS = 'SET_TAGS';

export const ADD_OCCS = 'ADD_OCCS';
export const ADD_CATS = 'ADD_CATS';
export const ADD_TAGS = 'ADD_TAGS';

export const EDIT_OCCS = 'EDIT_OCCS';
export const EDIT_CATS = 'EDIT_CATS';
export const EDIT_TAGS = 'EDIT_TAGS';

export const DELETE_OCCS = 'DELETE_OCCS';
export const DELETE_CATS = 'DELETE_CATS';
export const DELETE_TAGS = 'DELETE_TAGS';

// export const DELETE_ECARD = 'DELETE_ECARD';

export class Success implements Action {
   readonly type = SUCCESS;
}

// TRY OCC CAT TAG

export class TryOccs implements Action {
   readonly type = TRY_OCCS;
}

export class TryCats implements Action {
   readonly type = TRY_CATS;
}

export class TryTags implements Action {
   readonly type = TRY_TAGS;
}

// SET OCC CAT TAG

export class SetOccs implements Action {
    readonly type = SET_OCCS;

    constructor(public payload: string[] ) {}
 }
 export class SetCats implements Action {
    readonly type = SET_CATS;

    constructor(public payload: string[] ) {}
 }
 export class SetTags implements Action {
    readonly type = SET_TAGS;

    constructor(public payload: string[] ) {}
 }

 // ADD OCC CAT TAG

 export class AddOccs implements Action {
    readonly type = ADD_OCCS;

    constructor(public payload: {name: string, id: string} ) {}
 }
 export class AddCats implements Action {
    readonly type = ADD_CATS;

    constructor(public payload: {data: string} ) {}
 }
 export class AddTags implements Action {
    readonly type = ADD_TAGS;

    constructor(public payload: { data: string} ) {}
 }


 // Edit OCC CAT TAG

 export class EditOccs implements Action {
   readonly type =  EDIT_OCCS;

   constructor(public payload: {id: string, name: string} ) {}
}
export class EditCats implements Action {
   readonly type = EDIT_CATS;

   constructor(public payload: {id: string, name: string} ) {}
}
export class EditTags implements Action {
   readonly type = EDIT_TAGS;

   constructor(public payload: {id: string, name: string}) {}
}

 // Delete OCC CAT TAG

 export class DeleteOccs implements Action {
   readonly type =  DELETE_OCCS;

   constructor(public payload: {id: string, name: string} ) {}
}
export class DeleteCats implements Action {
   readonly type = DELETE_CATS;

   constructor(public payload: {id: string, name: string} ) {}
}
export class DeleteTags implements Action {
   readonly type = DELETE_TAGS;

   constructor(public payload: {id: string, name:string}) {}
}

 

//  export class SetECard implements Action {
//      readonly type = SET_ECARD;

//      constructor(public payload: ECard) {}
//  }
 
//  export class AddECard implements Action {
//      readonly type = ADD_ECARD;

//     constructor(public payload: ECard) {}
//  }
 
 // export class UpdateGift implements Action {
 //     readonly type = UPDATE_ECARD;
//
    // constructor(public payload: {id: number, updatedECard: ECard}) {}
 // }
 
 // export class DeleteGift implements Action {
 //     readonly type = DELETE_ECARD;
 //
    // constructor(public payload: number) {}
 // }

 export type OccCatTagActions = 
 Success |
 TryOccs | TryCats | TryTags |
 SetOccs | SetCats | SetTags | 
 AddOccs | AddCats | AddTags |
 EditOccs | EditCats | EditTags | 
 DeleteOccs | DeleteCats | DeleteTags;