import { Component, OnInit } from '@angular/core';
import { GiftService } from '../../../../gift-catalogue/gift.service';
import { Gift } from '../../../../gift-catalogue/gift.model';

@Component({
  selector: 'app-gifts-directory',
  templateUrl: './gifts-directory.component.html',
  styleUrls: ['./gifts-directory.component.css']
})
export class GiftsDirectoryComponent implements OnInit {

  gifts: Gift[];

  constructor(private giftsService: GiftService) { }

  ngOnInit() {
    // this.gifts = this.giftsService.getGifts();
  }

}
