import { Injectable } from "@angular/core";

import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { Subscription } from "rxjs";
import { fromPromise } from "rxjs/observable/fromPromise";
import { Subject } from "rxjs/Subject";

import { MediaManagerItem, MediaItem } from "./media-item.model";


@Injectable()
export class MediaService {

    mediaItemsFetched = new Subject<MediaManagerItem[]>();

    private mediaRetrivalSubscription: Subscription;

    constructor (private storageRef: AngularFireStorage,
                private afs: AngularFirestore) { }


    fetchMediaItems() {
        //go to get the stuffings
        if (this.mediaItemsFetched.subscribe((res) => {if (res.length == 0) {return true;} else {return false;}})) {
            const mediaItemsRef: AngularFirestoreCollection<any> = this.afs.collection('media');
        fromPromise(mediaItemsRef.ref.get()
        .then(
            (querySnapshot) => {
                let mediaItems: MediaManagerItem[] = [];

                querySnapshot.forEach( (doc) => {
                    let id = doc.data()["docId"];
                    let size = doc.get("size");
                    let storageRef = doc.get("storageRef");
                    let downloadURL = doc.get("downloadURL");
                    let mediaType = doc.get("mediaType");
                    let mediaItem: MediaManagerItem = {
                        docId :id,
                        size: size,
                        storageRef: storageRef,
                        downloadURL: downloadURL,
                        mediaType: mediaType,
                        selected: false
                    };
                    mediaItems.push(mediaItem);
                })
                return this.mediaItemsFetched.next(mediaItems.slice());
            }
        ))
        } else {
            return;
        }
    }

    onUpdateMediaMetadata() {
        // Create a reference to the file whose metadata we want to change
        console.log("running media metadata update...");
        const mediaItemsRef: AngularFirestoreCollection<any> = this.afs.collection('media');
        fromPromise(mediaItemsRef.ref.get()
        .then(
            (querySnapshot) => {
                let mediaItems: MediaManagerItem[] = [];

                querySnapshot.forEach( (doc) => {
                    let id = doc.data()["docId"];
                    let size = doc.get("size");
                    let storageRef = doc.get("storageRef");
                    let downloadURL = doc.get("downloadURL");
                    let mediaType = doc.get("mediaType");
                    let mediaItem: MediaManagerItem = {
                        docId :id,
                        size: size,
                        storageRef: storageRef,
                        downloadURL: downloadURL,
                        mediaType: mediaType,
                        selected: false
                    };
                    mediaItems.push(mediaItem);// Create file metadata to update
          const customMetadata = {
              copyright: "©2019-2020 LyricalDays.com. All rights reserved."
            };
        
            const newMetadata = {
              cacheControl: 'public, max-age=31536000, s-maxage=31536000',
              'customMetadata': customMetadata
            };
  
          // Update metadata properties
          this.storageRef.ref(storageRef).updateMetadata(newMetadata).toPromise()
          .then((metadata) => {
          // Updated metadata for 'images/forest.jpg' is returned in the Promise
          console.log(metadata)
          }).catch(function(error) {
          // Uh-oh, an error occurred!
          console.log(error);
          });
                })
                return;
            }
        ))

    }

    onUpdateMedia() {
        // push changes
    }

    onAddMedia(media: any) {
        // add item to storage bucket and return the download url for afs
        const newMedia: MediaManagerItem = {
            docId: '',
            storageRef: '',
            downloadURL: '',
            mediaType: 'image',
            thumnailRef: '',
            selected: false
        }

        // this.afs.collection('media').
    }

    onDeleteMedia() {
        // remove media item from storage
        // then remove from all instances if possible or disable unitl all refs deleted
        // store refereneces as part of the object
    }


}