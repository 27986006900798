import { Routes, RouterModule, PreloadAllModules, NoPreloading } from '@angular/router';
import { NgModule } from '@angular/core';

import { HomeComponent } from './core/home/home.component';
// import { UserAreaComponent } from './user-area/user-area.component';

import { BasketComponent } from './basket/basket.component';

import { PaymentDetailsComponent } from './shared/payment-details/payment-details.component';

// import { AccountSettingsComponent } from './user-area/account-settings/account-settings.component';
// import { AddressBookComponent } from './user-area/address-book/address-book.component';
// import { ContactComponent } from './user-area/user-settings/contact/contact.component';
// import { ECardSubscriptionComponent } from './user-area/user-settings/e-card-subscription/e-card-subscription.component';
import { AuthGuard } from './auth/auth-guard.service';
// import { CheckoutComponent } from './checkout/checkout.component';

import { EssentialsComponent } from './core/essentials/essentials.component';
import { LyricalMailComponent } from './lyrical-mail/lyrical-mail.component';
import { PrivacyPolicyComponent } from './legal/privacy-policy/privacy-policy.component';
import { UserAreaFeatureModule } from './user-area/user-area.module';
import { ECardFeatureModule } from './e-card-catalogue/e-card.module';
import { TermsConditionsComponent } from './legal/terms-conditions/terms-conditions.component';
import { CookiesPolicyComponent } from './legal/cookies-policy/cookies-policy.component';
import { SupplyConditionsComponent } from './legal/supply-conditions/supply-conditions.component';
import { AcceptableUsePolicyComponent } from './legal/acceptable-use-policy/acceptable-use-policy.component';
import { AboutComponent } from './core/about/about.component';
import { ContactComponent } from './core/contact/contact.component';
import { ECardCatalogueComponent } from './e-card-catalogue/e-card-catalogue.component';
import { ECardDetailComponent } from './e-card-catalogue/e-card/e-card-detail.component';
// import { GiftRoutingModule } from './gift-catalogue/gift-routing.module';
// import { GiftFeatureModule } from './gift-catalogue/gift.module';
 


const appRoutes : Routes = [

{path: 'lyricalmailer/:uid/:mailerId', component: LyricalMailComponent},    // change path to ':userId/:ecardId' later
    // window essentials
    {path: '', component: EssentialsComponent, children: [
        {path: '', component: HomeComponent },
            {path: 'account', 
            loadChildren: () => import('./user-area/user-area.module').then(m => m.UserAreaFeatureModule), 
            canLoad: [AuthGuard]
            },
            
            {path: 'admin', loadChildren:() => import('./admin/admin.module').then(m => m.AdminFeatureModule), 
            canLoad: [AuthGuard]
            },
    // {path: 'gifts', 
    // loadChildren: './gift-catalogue/gift.module#GiftFeatureModule',
    // },
    {path: 'ecards', 
    loadChildren: () => import('./e-card-catalogue/e-card.module').then(m => m.ECardFeatureModule),
    },
    // { path: 'ecards/', component: ECardCatalogueComponent },
    // { path: 'ecards/:id/:name', component: ECardDetailComponent},
    // {path:'ecards/:occ', component: ECardCatalogueComponent},
    {path: 'legal/privacy-policy', component: PrivacyPolicyComponent},
    {path: 'legal/terms-conditions', component: TermsConditionsComponent},
    {path: 'legal/cookies-policy', component: CookiesPolicyComponent},
    {path: 'legal/supply-conditions', component: SupplyConditionsComponent},
    {path: 'legal/acceptable-use-policy', component: AcceptableUsePolicyComponent},
    {path: 'about', component: AboutComponent},
    {path: 'contact', component: ContactComponent},
    //basket
    { path: 'basket', component: BasketComponent},
    //search
    
    //payment temp
    // {path: 'payment', component: PaymentDetailsComponent, canLoad: [AuthGuard]},
    //user area
   
    ] },
    // window checkout

    {path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutFeatureModule), 
    canLoad: [AuthGuard]
},

    // window login / sign up >> in authRoutingModule
    // window admin >> in adminRoutesModule
   
    // {path: '**', redirectTo: '/'} 
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule],
    providers: [AuthGuard]
})

export class AppRoutingModule {}