import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
selector: '[appOnlyNumber]'
})
export class AppOnlyNumberDirective {

  @Input() allowDecimals: boolean = true;
  @Input() allowSign: boolean = false;
  @Input() decimalSeparator: string = '.';

  previousValue: string = '';

  // --------------------------------------
  //  Regular expressions
  integerUnsigned: string = '^[0-9]*$';
  integerSigned: string = '^-?[0-9]+$';
  decimalUnsigned: string = '^[0-9]*\.[0-9][0-9]$';
  decimalSigned: string = '^-?[0-9]+(.[0-9]+)?$';

  /**
   * Class constructor
   * @param hostElement
   */
  constructor(private hostElement: ElementRef) { }

  /**
   * Event handler for host's change event
   * @param e
   */
  @HostListener('change', ['$event']) onChange(e) {

          this.validateValue(this.hostElement.nativeElement.value);
}

/**
* Event handler for host's paste event
* @param e
*/
@HostListener('paste', ['$event']) onPaste(e) {

  // get and validate data from clipboard
  let value = e.clipboardData.getData('text/plain');
  this.validateValue(value);
  e.preventDefault();
}

/**
* Event handler for host's keydown event
* @param event
*/
@HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {

  let cursorPosition: number = e.target['selectionStart'];
  let originalValue: string = e.target['value'];
  let key: string = this.getName(e);
  let controlOrCommand = (e.ctrlKey === true || e.metaKey === true);
  let signExists = originalValue.includes('-');
  let separatorExists = originalValue.includes(this.decimalSeparator);

  // allowed keys apart from numeric characters
  let allowedKeys = [
      'Backspace', 'ArrowLeft', 'ArrowRight', 'Escape', 'Tab'
  ];

  // when decimals are allowed, add
  // decimal separator to allowed codes when
  // its position is not close to the the sign (-. and .-)
  let separatorIsCloseToSign = (signExists && cursorPosition <= 1);
  if (this.allowDecimals && !separatorIsCloseToSign && !separatorExists) {

      if (this.decimalSeparator == '.')
          allowedKeys.push('.');
      else
          allowedKeys.push(',');
  }

  // when decimals are allowed, split
  // array of values at decimal, then
  // prevent input after 2 decimal places
  // unless backspace, arrow, escape or tab
if(this.allowDecimals && separatorExists) {
   var decimalCount = originalValue.split(this.decimalSeparator);
    if(decimalCount[1].length >= 2 ) {

            if (allowedKeys.indexOf(key) == -1){
                e.preventDefault();
            }
        }
    }


  // when minus sign is allowed, add its
  // key to allowed key only when the
  // cursor is in the first position, and
  // first character is different from
  // decimal separator
  let firstCharacterIsSeparator = (originalValue.charAt(0) != this.decimalSeparator);
  if (this.allowSign && !signExists &&
      firstCharacterIsSeparator && cursorPosition == 0) {

      allowedKeys.push('-');
  }

  // allow some non-numeric characters
  if (allowedKeys.indexOf(key) != -1 ||
      // Allow: Ctrl+A and Command+A
      (key == 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (key == 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (key == 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (key == 'x' && controlOrCommand)) {
      // let it happen, don't do anything
      return;
  }

  // save value before keydown event
  this.previousValue = originalValue;

  // allow number characters only
  let isNumber = (new RegExp(this.integerUnsigned)).test(key);
  if (isNumber) return; else e.preventDefault();
}

/**
* Test whether value is a valid number or not
* @param value
*/
validateValue(value: string): void {

  // choose the appropiate regular expression
  let regex: string;
  if (!this.allowDecimals && !this.allowSign) regex = this.integerUnsigned;
  if (!this.allowDecimals && this.allowSign) regex = this.integerSigned;
  if (this.allowDecimals && !this.allowSign) regex = this.decimalUnsigned;
  if (this.allowDecimals &&  this.allowSign) regex = this.decimalSigned;

  // when a numbers begins with a decimal separator,
  // fix it adding a zero in the beginning
  let firstCharacter = value.charAt(0);
  if (firstCharacter == this.decimalSeparator)
      value = 0 + value;

  // when a numbers ends with a decimal separator,
  // fix it adding a zero in the end
  let lastCharacter = value.charAt(value.length-1);
  if (lastCharacter == this.decimalSeparator)
      value = value + 0;

  // test number with regular expression, when
  // number is invalid, replace it with a zero
  let valid: boolean = (new RegExp(regex)).test(value);
  this.hostElement.nativeElement['value'] = valid ? value : 0;
}

/**
* Get key's name
* @param e
*/
getName(e): string {

  if (e.key) {

      return e.key;

  } else {

      // for old browsers
      if (e.keyCode && String.fromCharCode) {

          switch (e.keyCode) {
              case   8: return 'Backspace';
              case   9: return 'Tab';
              case  27: return 'Escape';
              case  37: return 'ArrowLeft';
              case  39: return 'ArrowRight';
              case 188: return ',';
              case 190: return '.';
              case 109: return '-'; // minus in numbpad
              case 173: return '-'; // minus in alphabet keyboard in firefox
              case 189: return '-'; // minus in alphabet keyboard in chrome
              default: return String.fromCharCode(e.keyCode);
          }
      }
  }
}










    // private el: NgControl;

    // constructor(private ngControl: NgControl) {
    //   this.el = ngControl;
    // }
  
    // // Listen for the input event to also handle copy and paste.
    // @HostListener('input', ['$event.target.value'])
    // onInput(value: string) {
    //   // Use NgControl patchValue to prevent the issue on validation
    //   this.el.control.patchValue(value.replace(/[^0-9]/g, ''));
    // }

  //   private regex: RegExp = new RegExp(/[0-9]/g);
  //   // Allow key codes for special events. Reflect :
  //   private specialKeys: Array<number> = [46, 8, 9, 27, 13, 110, 190, 35, 36, 37, 39];
  //   // Backspace, tab, end, home
  
  //   @Input() maxlength: number;
  //   @Input() min: number;
  //   @Input() max: number;
  
  //   constructor(private el: ElementRef) {
  //   }
  //     @HostListener('keyup', ['$event'])
  //     onkeyUp(event: KeyboardEvent) {
  //     e = <KeyboardEvent>event;
  
  // if ((
  //   (this.specialKeys.indexOf(event.which) > -1) ||
  //   // to allow backspace, enter, escape, arrows  
  //   (e.which == 65 && e.ctrlKey == true) ||
  //   // Allow: Ctrl+C        
  //   (e.which == 67 && e.ctrlKey == true) ||
  //   // Allow: Ctrl+X
  //   (e.which == 88 && e.ctrlKey == true))) {
  //   return;
  // } else if (// to allow numbers  
  //   (e.which >= 48 && e.which <= 57) ||
  //   // to allow numpad number  
  //   (event.which >= 96 && event.which <= 105)) { }
  // else {
  //       event.preventDefault();
  //     }
  //     let current: string = this.el.nativeElement.value;
  
  //     let next: string = current.concat(event.key);
  //     if ((next && !String(next).match(this.regex)) ||
  //       (this.maxlength && next.length > this.maxlength) ||
  //       (this.min && +next < this.min) ||
  //       (this.max && +next >= this.max)) {
  //       event.preventDefault();
  //     }
  
  //   }
  // }









  //   // constructor(private el: ElementRef) { }

  //   // @Input() appOnlyNumber: boolean;
  
  //   // @HostListener('keydown', ['$event']) onKeyDown(event) {
  //   //   let e = <KeyboardEvent> event;
  //   //   if (this.appOnlyNumber) {
  //   //     if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
  //   //       // Allow: Ctrl+A
  //   //       (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
  //   //       // Allow: Ctrl+C
  //   //       (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
  //   //       // Allow: Ctrl+V
  //   //       (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
  //   //       // Allow: Ctrl+X
  //   //       (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
  //   //       // Allow: home, end, left, right
  //   //       (e.keyCode >= 35 && e.keyCode <= 39)) {
  //   //         // let it happen, don't do anything
  //   //         return;
  //   //       }
  //   //       // Ensure that it is a number and stop the keypress
  //   //       if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
  //   //           return e.defaultPrevented;
  //   //       }
  //   //     }
  //   // }
  // }
  



//    @HostListener('keydown', ['$event'] ) onkeydown(event: KeyboardEvent) {
//             if(event.shiftKey){event.preventDefault(); return false;}
//             //console.log(event.which);
//             if ([8, 13, 27, 37, 38, 39, 40].indexOf(event.which) > -1) {
//                 // backspace, enter, escape, arrows
//                 return true;
//             } else if (event.which >= 48 && event.which <= 57) {
//                 // numbers 0 to 9
//                 return true;
//             } else if (event.which >= 96 && event.which <= 105) {
//                 // numpad number
//                 return true;
//             } 
//             // else if ([110, 190].indexOf(event.which) > -1) {
//             //     // dot and numpad dot
//             //     return true;
//             // }
//             else {
//                 event.preventDefault();
//                 return false;
//             }
//         };
//     }

// // Allow decimal numbers and negative values
//  private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
//  // Allow key codes for special events. Reflect :
//  // Backspace, tab, end, home
//  private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', '-' ];

// constructor(private el: ElementRef) {
//  }
//  @HostListener('keydown', [ '$event' ])
//  onKeyDown(event: KeyboardEvent) {
//  // Allow Backspace, tab, end, and home keys
//  if (this.specialKeys.indexOf(event.key) !== -1) {
//  return;
//  }
//  let current: string = this.el.nativeElement.value;
//  let next: string = current.concat(event.key);
//  if (next && !String(next).match(this.regex)) {
//  event.preventDefault();
//  }
//  }
// }




// @Input('appInputMask') inputType: string;

// showMsg = false;
// pattern: RegExp;

// private regexMap = { // add your own
// integer: /^[0-9 ]*$/g,
// float: /^[+-]?([0-9]*[.])?[0-9]+$/g,
// words: /([A-z]*\\s)*/g,
// point25: /^\-?[0-9]*(?:\\.25|\\.50|\\.75|)$/g,
// badBoys: /^[^{}*+£$%\\^-_]+$/g
// };

// constructor(public el: ElementRef, public renderer: Renderer2) { };

// @HostListener('keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
//    console.log(event)
//     // const pattern = /[0-9\+\-\ ]/;
//     // let inputChar = String.fromCharCode(event.keyCode);

//     if (event.charCode.valueOf() > 31 && (event.charCode.valueOf() < 48 || event.charCode.valueOf() > 57) ) {
//       // invalid character, prevent input
      
//     return false;
//     }
   
   
    // console.log(event.keyCode);
    // if (event.keyCode > 31 && (event.keyCode < 48 || event.keyCode > 57)) {
    //     return false;
    // }
    // return true;



// this.pattern = this.regexMap[this.inputType]
// const inputChar = e.key;
// this.pattern.lastIndex = 0; // dont know why but had to add this

// if (this.pattern.test(inputChar)) {
//    // success
//   this.renderer.setStyle(this.el.nativeElement, 'color', 'green'); 
//   this.badBoyAlert('black');
// } else {

//   this.badBoyAlert('black');
//    //do something her to indicate invalid character
//   this.renderer.setStyle(this.el.nativeElement, 'color', 'red');
//   e.preventDefault();

// }

//   }
//   badBoyAlert(color: string) {
//     setTimeout(() => {
//       this.showMsg = true;
//       this.renderer.setStyle(this.el.nativeElement, 'color', color);
//     }, 2000)
//   }

  }