import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-directory',
  templateUrl: './product-directory.component.html',
  styleUrls: ['./product-directory.component.css']
})
export class ProductDirectoryComponent implements OnInit {
  displayAll = false;

  constructor() { }

  ngOnInit() {
    this.all();
    this.notAll();
  }

  all() {
    this.displayAll = true;
  }

  notAll() {
    this.displayAll = false;
  }

}
