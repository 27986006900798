import { ModuleWithProviders, NgModule } from "@angular/core";

import { RemoveSpacesPipe } from "./remove-spaces.pipe";

@NgModule({
    imports: [],

    declarations:[
      // RemoveSpacesPipe,
    ],
  
    exports:[
      // RemoveSpacesPipe
  ]
})

export class PipesModule {
    static forRoot(): ModuleWithProviders<PipesModule> {
    return {
        ngModule: PipesModule,
        providers: [],
    };
}
}