import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router, ActivatedRoute } from "@angular/router";
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromECard from '../../../../e-card-catalogue/store/ecard.reducers';
import { ECard } from '../../../../e-card-catalogue/e-card.model';
import { ECardService } from '../../../../e-card-catalogue/e-card.service';
import { CurrencyService } from '../../../../shared/currency.service';

@Component({
  selector: 'app-e-cards-directory',
  templateUrl: './e-cards-directory.component.html',
  styleUrls: ['./e-cards-directory.component.css']
})
export class ECardsDirectoryComponent implements OnInit {

ecards: ECard[];
currency:string;

//   constructor(private ecardService: ECardService) { }

//   ngOnInit() {
//     this.ecards = this.ecardService.getECards();
//   }

  displayedColumns = ['name' ,'id', 'thumbnail', 'releaseDate', 'price', 'occasions', 'categories','tags', 'stats'];
  dataSource = new MatTableDataSource<any>();
  
  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
    // contacts: Contact[];
  
    constructor(private router: Router,
                private route: ActivatedRoute,
                private store: Store<fromECard.State>,
                private ecardService: ECardService,
                private currencyService: CurrencyService
               ) { }
  
    ngOnInit() {
      this.currencyService.currency.subscribe((currency) => {
        this.currency = currency;
      })
      console.log("Contacts directory running...");
      // this.store.dispatch({type: 'TRY_CONTACTS'});
      this.ecardService.fetchECards();
      // this.store.dispatch({type: "TRY_ECARDS"});
      this.store.select(fromECard.getAvailableECards)
      .subscribe( (res) => {
        this.ecards = res;
        this.dataSource.data = res;
      });
    }
  
  
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  
  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  
    onViewContact(contactId) {
      let id = contactId;
      this.router.navigate([id], {relativeTo: this.route})
  
    }
  
    onAddContact() {
      this.router.navigate(['new'], {relativeTo: this.route});
  }

}
