import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-gift',
  templateUrl: './upload-gift.component.html',
  styleUrls: ['./upload-gift.component.css']
})
export class UploadGiftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
