import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromOccCatTag from '../store/occ-cat-tag.reducers';
import * as OccCatTagActions from '../store/occ-cat-tag.actions';


@Component({
  selector: 'app-cat',
  templateUrl: './cat.component.html',
  styleUrls: ['./cat.component.css']
})
export class CatComponent implements OnInit {

  catForm: FormGroup;
  editMode: boolean = false;
  tempCat: {id: string, name: string};

  category$ : Observable<string[]>;

  constructor(private store: Store<fromOccCatTag.State>) { }

  ngOnInit() {
    this.store.dispatch({type: 'TRY_CATS'});
    this.category$ = this.store.select(fromOccCatTag.getAvailableCats);
    console.log('Finding cat state... ' , this.category$);
    this.catForm = new FormGroup({
         'catControl': new FormControl(null, Validators.required)
    })
  }

onCat() {
  if (this.editMode === true) {

    //check for string changes first
    if (this.tempCat.name != this.catForm.get('catControl').value) {
      this.tempCat.name = this.catForm.get('catControl').value;
      let id = this.tempCat.id;
      let name = this.onTitleCase(this.tempCat.name);
      console.log("Temp cat name : " , this.tempCat.name, id, name);
      this.store.dispatch( {type: 'EDIT_CATS', payload: {id, name} } );
      this.store.dispatch({type: 'TRY_CATS'});
    }
    //else nothing
  } // else create new category
  else {
     let catValue = this.catForm.get('catControl').value;
     var dataTitleCase = this.onTitleCase(catValue);
    this.store.dispatch( {type: 'ADD_CATS', payload: {'catName': dataTitleCase}} );
  }
  this.editMode = false;
  this.catForm.reset();
}

onEdit(catId: string, catName: string) {
  this.editMode = true;
  this.tempCat = {id: catId, name: catName};
  this.catForm.setValue({'catControl': catName});
}

onDelete() {
 let id = this.tempCat.id;
 let name = this.tempCat.name;
 this.store.dispatch( { type: 'DELETE_CATS', payload: {id, name}} );
 this.store.dispatch({type: 'TRY_CATS'});
  this.editMode = false;
  this.catForm.reset();
}

onCancelEdit() {
  this.editMode = false;
  this.catForm.reset();
}

onTitleCase(string) {
  // let string: string = this.tagForm.get('tagControl').value;
  var nameTitleCase = string.toLowerCase().split(' ').map( (word) => {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
return nameTitleCase;
}

}
