import { Action } from "@ngrx/store";
import { ECardCustomise, ECardPack } from "../../../../checkout/customise-e-card/customise-ecard.model";

export const SET_OWNED_ECARDS = 'SET_OWNED_ECARDS';
export const SET_ECARD_PACKS = 'SET_ECARD_PACKS';
export const UPDATE_OWNED_ECARD = 'UPDATE_OWNED_ECARD';
export const UPDATE_ECARD_PACK = 'UPDATE_ECARD_PACK';

export class SetOwnedECards implements Action {
    readonly type = SET_OWNED_ECARDS;

    constructor(public payload: ECardCustomise[]) {}
}

export class SetECardPacks implements Action {
    readonly type = SET_ECARD_PACKS;

    constructor(public payload: ECardPack[]) {}
}

export class UpdateOwnedECard implements Action {
    readonly type = UPDATE_OWNED_ECARD;

    constructor(public payload: ECardCustomise) {}
}

export class UpdateECardPack implements Action {
    readonly type = UPDATE_ECARD_PACK;

    constructor(public payload: {ecardC: ECardCustomise, id:string, index: number}) {}
}


export type OwnedECardsActions = SetOwnedECards | 
SetECardPacks | 
UpdateOwnedECard |
UpdateECardPack;