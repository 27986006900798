import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import { Contact } from '../contact-entry.model';

import * as fromApp from '../../../../store/app.reducers';
import { ContactActions, SET_CONTACTS, ADD_CONTACT, EDIT_CONTACT, DELETE_CONTACT } from './addressbook.actions';

export interface ContactState {
    contacts: Contact[]
}

export interface State extends fromApp.AppState {
    contacts: ContactState
}

const inititalState: ContactState = {
    contacts: []
}


export function addressbookReducer(state = inititalState, action: ContactActions) {
  switch (action.type) {
    case SET_CONTACTS:
      return {
        ...state,
        contacts: [...action.payload]
      };
    case ADD_CONTACT:
      return {
        ...state,
        contacts: [...state.contacts, action.payload]
      };
      case EDIT_CONTACT:
        const cId = action.payload.id;
        const cIndex = state.contacts.indexOf(state.contacts.find(c => c.id === cId));
        const contact = state.contacts[cIndex];
        const updatedContact = {...action.payload};
        const updatedContacts = [...state.contacts];
        updatedContacts[cIndex] = updatedContact;
        return {
          ...state,
          contacts: updatedContacts
        };
    default:
      return state;
  }
}

export const getContactState = createFeatureSelector<ContactState>('contacts');

export const getAvailableContacts = createSelector(getContactState, (state: ContactState) => state.contacts);