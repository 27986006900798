import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminAreaComponent } from './admin-area.component';

//PRODUCTS & ORDERS MANAGEMENT
import { OrdersComponent } from './product-management/orders/orders.component';
import { OrdersListComponent } from './product-management/orders/orders-list/orders-list.component';
import { OrderItemComponent } from './product-management/orders/order-item/order-item.component';

import { ProductDirectoryComponent } from './product-management/product-directory/product-directory.component'; // root of both gift and ecard directory
import { AllProductsComponent } from './product-management/product-directory/all-products/all-products.component'; // both gift and ecard directory overview

import { ManageECardsComponent } from './product-management/manage-e-cards/manage-e-cards.component'; //holds directory and add, edit, export data options
import { ECardsDirectoryComponent } from './product-management/manage-e-cards/e-cards-directory/e-cards-directory.component'; // table of ecards
import { EditECardComponent } from './product-management/manage-e-cards/edit-e-card/edit-e-card.component'; // load ecard for editing
import { UploadECardComponent } from './product-management/manage-e-cards/upload-e-card/upload-e-card.component'; // add new ecard to site and database

import { ManageGiftsComponent } from './product-management/manage-gifts/manage-gifts.component'; // holds directory nd add, edit export data options
import { GiftsDirectoryComponent } from './product-management/manage-gifts/gifts-directory/gifts-directory.component'; // table of gifts
import { EditGiftComponent } from './product-management/manage-gifts/edit-gift/edit-gift.component'; // load gift for editing
import { UploadGiftComponent } from './product-management/manage-gifts/upload-gift/upload-gift.component'; // add new gift to site and database

// REPORTS & SHOP MANAGEMENT
import { TaxSettingsComponent } from './shop-management/tax-settings/tax-settings.component';
import { ShippingSettingsComponent } from './shop-management/shipping-settings/shipping-settings.component';
import { CouponVoucherCodesComponent } from './shop-management/coupon-voucher-codes/coupon-voucher-codes.component';
import { ReportsComponent } from './shop-management/reports/reports.component';
import { EmailNotifactionsTemplatesComponent } from './shop-management/email-notifactions-templates/email-notifactions-templates.component';
import { OccCatTagComponent } from './product-management/occ-cat-tag/occ-cat-tag.component';
import { OccComponent } from './product-management/occ-cat-tag/occ/occ.component';
import { CatComponent } from './product-management/occ-cat-tag/cat/cat.component';
import { TagComponent } from './product-management/occ-cat-tag/tag/tag.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './users/user/user.component';


const adminRoutes: Routes = [
   
    {path: '', component: DashboardComponent}, 
    {path: '', component: AdminAreaComponent, children: [
    // admin shop manangement settings
    {path: 'shop-management/tax', component: TaxSettingsComponent},
    {path: 'shop-management/shipping', component: ShippingSettingsComponent},
    {path: 'shop-management/coupons', component: CouponVoucherCodesComponent},
    {path: 'shop-management/email-templates', component: EmailNotifactionsTemplatesComponent},
    {path: 'shop-management/reports', component: ReportsComponent},
    // admin product management
    {path: 'product-management/products', component: ProductDirectoryComponent, children: [
        // {path: 'all', component: AllProductsComponent},
        {path: 'ecards', pathMatch:'full', component: ManageECardsComponent},
        {path: 'ecards/upload', pathMatch:'full', component: UploadECardComponent},
        {path: 'ecards/:id/edit', component: UploadECardComponent},
        {path: 'gifts', component: ManageGiftsComponent},
        {path: 'gifts/edit', component: EditGiftComponent},
        {path: 'gifts/upload', component: UploadGiftComponent}
    ]},
    {path: 'product-management/manage-occ-cat-tag', component: OccCatTagComponent, children: [
        {path: 'occasions', component: OccComponent},
        {path: 'categories', component: CatComponent},
        {path: 'tags', component: TagComponent}
    ]},
    {path: 'product-management/orders', component: OrdersComponent},
    {path: 'customer-support/users', component: UsersComponent, children: [
        {path: 'user/:id/:name', component: UserComponent}
    ]}
]}
]

@NgModule({
    imports: [
        RouterModule.forChild(adminRoutes)
    ],
    exports: [RouterModule]
})

export class AdminRoutingModule {}