export class BasketItem {

    public id: string;
    public quantity: number = 0;
    public productImage: string;
    public name: string;
    public price: number;
    public priceEUR?: number;
    public priceUSD?:number;
    public type: string = 'ecard' || 'gift';
    public sendLimit?: number;

  }

  // export interface BasketItem {

  //   id: number;
  //    quantity: number;
  //   productImage: string;
  //   name: string;
  //   price: number;



  // }