import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";

@Injectable()
export class CurrencyService {

    public currency: BehaviorSubject<string> = new BehaviorSubject('gbp');

    // public currency: BehavouSubject<string> = new Subject<'gbp'>();



    constructor() {}

    onChangeCurrency(currency: string) {
        console.log('onChangeCurency: ', currency);
        this.currency.next(currency);
    }

}