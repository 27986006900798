import { Action } from '@ngrx/store';

import { AuthActions, SIGNUP, USER, LOGIN, LOGOUT, SET_TOKEN, NO_USER, SET_AUTHENTICATED, SET_UNAUTHENTICATED} from './auth.actions';

export interface State {
    token: string;
    authenticated: boolean;
}


const initialState: State = {
    token: null,
    authenticated: false
};

export function authReducer(state = initialState, action: AuthActions) {
    switch (action.type) {
        case USER:
        case SIGNUP:
        case LOGIN:
        case SET_AUTHENTICATED:
        return {
            ...state,
            authenticated: true
        };
        case NO_USER:
        case LOGOUT:
        case SET_UNAUTHENTICATED:
        return {
            ...state,
            token: null,
            authenticated: false
        };
        case SET_TOKEN:
        return  {
            ...state,
            token: action.payload
        }
        default:
        return state;
    }
}


export const getIsAuth = (state: State) => state.authenticated;
export const getIsToken = (state: State) => state.token;
// console.log("get is token..." , getIsToken);



