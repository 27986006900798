import { Component, OnInit } from '@angular/core';
import { CurrencyService } from '../../shared/currency.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

currencyArray: string[] = ['gbp', 'usd', 'eur'];

currency: string = 'gbp';

  constructor(private currencyService: CurrencyService) { }

  ngOnInit() {
    this.currencyService.currency.subscribe((currency) => {
      this.currency = currency;
    })
  }


  onUpdateCurrency(currency:string) {
    // if (currency == 'gbp') {
    //   this.currency = 'usd';
    // } else if (currency == 'usd') {
    //   this.currency = 'eur';
    // } else {
    //   this.currency = 'gbp';
    // };
    this.currency = currency;
    console.log(currency);
    this.currencyService.onChangeCurrency(this.currency);
  }

}
