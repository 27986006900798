import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LogInComponent } from './log-in/log-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const authRoutes: Routes = [
    {path: 'login', component: LogInComponent},
    {path: 'signup', component: SignUpComponent, },
    {path: 'reset-password', component: ResetPasswordComponent}
]

@NgModule({
    imports: [
        RouterModule.forChild(authRoutes)
    ],
    exports: [RouterModule]
})

export class AuthRoutingModule {}