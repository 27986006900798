import { Component, Inject, OnInit, ViewChild, AfterViewInit, OnDestroy, } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from "@ngrx/store";
import * as fromAddress from "../../user-area/user-settings/address-book/store/addressbook.reducers";
import * as AddressActions from '../../user-area/user-settings/address-book/store/addressbook.actions';
import { Contact, AddressLookup } from "../../user-area/user-settings/address-book/contact-entry.model";
import { CheckoutService } from "../checkout.service";
import { AddressbookService } from "../../user-area/user-settings/address-book/addressbook.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-media-dialogue",
  templateUrl: "./address-dialogue.component.html",
  styleUrls: [ './address-dialogue.component.css'          
  ]
})
export class AddressDialogueComponent implements OnInit, AfterViewInit, OnDestroy {

  id: string;
  editMode: boolean = false;
  relationship: string = 'Relationship...';
  relationSelector: String[] = ['Family', 'Friend', 'Other'];
  occasion: string = 'Occasion...';
  occasionSelector: String[] = ['Anniversary', 'Other', 'Add Custom'];
  addDate: boolean = false;
  addCustomOccasion: boolean = false;
  customOccasionSelector: String[] = [];
  contactForm: FormGroup;
  contact: Contact;
  asDIY: boolean;

  displayedColumns = ['contactFirstName' ,  'contactEmail']; // removed 'contactSurname', 'contactMobileNumber',
  dataSource = new MatTableDataSource<any>();
  addNewContact = false;

@ViewChild(MatSort) sort: MatSort;
@ViewChild(MatPaginator) paginator: MatPaginator;

  addressbook: AddressLookup [] = [];
  recipients: Contact [] = [];
  addressSub: Subscription;
  remainder: number = 0;
  canSelectMore: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private checkoutService: CheckoutService,
    public dialogRef: MatDialogRef<AddressDialogueComponent>,
    private addressStore: Store<fromAddress.State>,
    private addressbookService: AddressbookService
  ) {
    console.log(passedData.recipientCount);
    dialogRef.disableClose = true;
  }


  ngOnInit() {
    // console.log("Running get items...");
    // console.log("ECard sendlImit received: ", this.passedData.recipientCount);
    this.asDIY = this.passedData.asDIY;
    if (this.asDIY) {
      this.recipients = [];
    }
    if (this.passedData.recipients) {
      this.recipients = this.passedData.recipients;
      this.remainder = this.passedData.recipientCount - this.recipients.length;
    } else {
      this.remainder = this.passedData.recipientCount;
      this.recipients = [];
    }
    this.addressSub = this.addressStore.select(fromAddress.getAvailableContacts)
    .subscribe(res => {
      let lookup: AddressLookup [] = [];
      console.log("Running adressbook lookup...");
      console.log("State of this.recipients..." ,  this.recipients);
      res.forEach( c => {
        if (this.passedData.recipients) {
              const changeCSS = this.recipients.find( e => e.id === c.id);
              if (changeCSS) {
                const l : AddressLookup = {
                id: c.id,
                contactFirstName: c.contactFirstName,
                contactSurname: c.contactSurname,
                contactNickname: c.contactNickname,
                contactRelGroup: c.contactRelGroup,
                contactMobileNumber: c.contactMobileNumber,
                contactEmail: c.contactEmail,
                contactBirthday: c.contactBirthday,
                contactAnniversary:c.contactAnniversary,
                selected: true
              }
              return lookup.push(l);
              } else {
                const l : AddressLookup = {
                  id: c.id,
                  contactFirstName: c.contactFirstName,
                  contactSurname: c.contactSurname,
                  contactNickname: c.contactNickname,
                  contactRelGroup: c.contactRelGroup,
                  contactMobileNumber: c.contactMobileNumber,
                  contactEmail: c.contactEmail,
                  contactBirthday: c.contactBirthday,
                  contactAnniversary:c.contactAnniversary,
                  selected: false
                }
                return lookup.push(l);
              };
        } else {
          console.log("Running alternative ending...");
          const l : AddressLookup = {
            id: c.id,
            contactFirstName: c.contactFirstName,
            contactSurname: c.contactSurname,
            contactNickname: c.contactNickname,
            contactRelGroup: c.contactRelGroup,
            contactMobileNumber: c.contactMobileNumber,
            contactEmail: c.contactEmail,
            contactBirthday: c.contactBirthday,
            contactAnniversary:c.contactAnniversary,
            selected: false
          }
          console.log("Lookup item passed: " , l.selected);
          return lookup.push(l);
        }
        
      });

        this.addressbook = lookup;
        this.dataSource.data = this.addressbook;
    });
    
    this.initForm();
    // this.addressbook.forEach( c => {
     
    // })
  }

  // checkRemainder() {
  //   if(this.recipients === []) {
  //     this.remainder = this.passedData.recipientCount;
  //   }
  //   else {
  //     this.remainder = this.passedData.recipientCount - this.recipients.length;
  //   }
  // }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onSelected(contact) {
    let recipientsArray = this.recipients.slice();
    if (contact.selected == true) {
      this.recipients.splice(contact, 1);
      this.canSelectMore = true;
      return contact.selected = false;
    }
    else {
      console.log("Attempting to change status of contact: " , contact);
      console.log("calculating: ", this.passedData.recipientCount - this.recipients.length  );
      console.log("remainder: " , this.remainder);
      console.log("this.recipients.length: " , this.recipients.length);
      console.log("can select more status: " , this.canSelectMore);
      // check if there are enough cards to send before allowing select again
      if ( this.canSelectMore ) {
        recipientsArray.push(contact);
        this.recipients = recipientsArray;
        console.log("after selecting recipeint length is now: " , this.recipients.length);
        if ( this.passedData.recipientCount > this.recipients.length ) {
          this.canSelectMore = true;
        }
        else {
          this.canSelectMore = false;
        }
        return contact.selected = true;
      }
      else {
           return contact.selected = false;
      }
     
    }
  }

  onContact() {
    if (this.recipients != null) {
      return false;
    } else {
      return true;
    }
  }

  onSubmitContacts() {
    if (this.recipients.length <= this.passedData.recipientCount && this.recipients.length >= 1) {
      return this.dialogRef.close([...this.recipients.slice()]);
    } else {
      console.log("Whoops! There has been an error in the quantity check of contacts[].");
    } 
  }

  private initForm() {
    let contactFirstName = '';
    let contactSurname = '';
    let contactNickname = '';
    let contactEmail = '';
    let contactMobileNumber = 0;

    this.contactForm = new FormGroup({
        'contactFirstName': new FormControl('', Validators.required),
        'contactSurname': new FormControl('', Validators.required),
        'contactNickname': new FormControl(null),
        'contactRelGroup': new FormControl(null),
        'contactMobileNumber': new FormControl(null),
        'contactEmail': new FormControl(null, [Validators.required, Validators.email]),
        'contactBirthday': new FormControl(null),
        'contactAnniversary': new FormControl(null)
    });
}

onQuickAddContact() {

    let contact = {
      id: 'c-'+new Date(),
      contactFirstName: this.contactForm.get('contactFirstName').value,
      contactSurname: this.contactForm.get('contactSurname').value,
      contactNickname: this.contactForm.get('contactNickname').value,
      contactRelGroup: this.contactForm.get('contactRelGroup').value,
      contactMobileNumber: this.contactForm.get('contactMobileNumber').value,
      contactEmail: this.contactForm.get('contactEmail').value,
      contactBirthday: this.contactForm.get('contactBirthday').value,
      contactAnniversary: this.contactForm.get('contactAnniversary').value,
   };
       this.addressbookService.onAddContact(contact);
  // }
  this.addNewContact = false;
  console.log("reseting...");
  this.addressStore.dispatch(new AddressActions.AddContact(contact));
  this.contactForm.reset();
}

onCancelQuickAdd() {
  this.addNewContact = false;
  this.contactForm.reset();
}

  onNoClick(): void {
    this.dialogRef.close();
    // console.log("OnNoClick() running...")
    // if (this.recipients.slice() === []){
    //   console.log("this.recipients.slice() === []")
    //   this.dialogRef.close([]);  
    // } 
    // // else if (this.recipients.slice() != this.passedData.recipients) {
    // //   this.dialogRef.close([...this.recipients.slice()]);  
    // // }
    // else {
    //   console.log("passing back data recieved: " , this.passedData.recipients);
    // this.dialogRef.close(this.passedData.recipients);
    // }
  }

//   onSelected(mediaItem) {
//     console.log("Clicked media item: " , mediaItem);
//     if (mediaItem.selected == true) {
//       this.selectedMedia.splice(mediaItem, 1);
//       console.log(this.selectedMedia);
//       return mediaItem.selected = false;
      
//     }
//     else {
//       this.selectedMedia.push(mediaItem);
//       console.log(this.selectedMedia);
//       return mediaItem.selected = true;
//     }
//   }

//   onMedia() {
//      if (this.selectedMedia.length <= this.passedData.mediaCount && this.selectedMedia.length >= 1) {
//       return false;
      
//     } else {
//       return true;
//   }
// }

//   onSubmitMediaItem() {
//     if (this.selectedMedia.length <= this.passedData.mediaCount && this.selectedMedia.length >= 1) {
//       console.log("Correct number of media items selected: " , this.selectedMedia.slice());
//       return  this.dialogRef.close({...this.selectedMedia.slice()});
//     } else {
//       console.log("Whoops! There has been an error in the quantity check of mediaItem[].");
//     }
//   }

ngOnDestroy() {
  this.addressSub.unsubscribe();
}
}
