import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "@angular/fire/compat/firestore";
import { Store } from "@ngrx/store";
// import { Http } from '@angular/http';

import * as fromECard from './store/ecard.reducers';
import * as ECardActions from './store/ecard.actions';
import { ECard, Filter } from './e-card.model';
import { CachingServiceBase } from '../shared/caching.service';
import { AddECard } from './store/ecard.actions';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { take } from 'rxjs/operators/take';



@Injectable()
export class ECardService {
  ecardCollection: AngularFirestoreCollection<ECard[]>;
  private ecardsSubs: Subscription[] = [];
  private ecards = new Subject<ECard[]>();
  notChristmas: ECard[] = [];
  constructor(private afs: AngularFirestore,
    private store: Store<fromECard.State>) {
    //   this.ecardCollection = this.afs.collection("ecards", (ref) =>
    //   // ref.orderBy("price", "asc").limit(100);
    //   ref.orderBy("releaseDate", "desc")
    // );
    if (this.isChristmasTime()) {
      // get Christmas ecards too
      this.ecardCollection = this.afs.collection("ecards", (ref) =>
      // ref.orderBy("price", "asc").limit(100);
      ref.orderBy("releaseDate", "desc")
    );
    } else {
      // don't read Christmas ecards
      this.afs.collection("ecards").ref.where('isChristmas', '==', false).orderBy("releaseDate", "desc");
    }
    }

    isChristmasTime() {
      // this.notChristmas;
      const currentDay: Number = new Date().getDate();
      const formatter = new Intl.DateTimeFormat('en', { month: 'numeric' });
      const month = formatter.format(new Date());
      const currentMonth = month;
      if (
      currentMonth == '1' && currentDay <= 7 || 
      currentMonth == '11' && currentDay >= 15 || 
      currentMonth == '12') {
        return true;
      } else {
        return false;
      }

    }

    fetchECards() {
      console.log('running fetchECards()');
      const ecardsRef: AngularFirestoreCollection<any> = this.ecardCollection;
      if (this.ecards.subscribe((res) => {if (res.length == 0) {return true;} else {return false;}}) ) {
      if (this.isChristmasTime() ) {
        this.ecardsSubs.push(
        this.ecardCollection
        .snapshotChanges()
        .pipe(
            take(1),
            map(docArray => {  
                return docArray.map(doc => {
                      return {
                        // id : doc.payload.doc.data["id"],
                        // name : doc.payload.doc.data["name"],
                        // formatOptions : doc.payload.doc.data["formatOptions"],
                        // styleOptions : doc.payload.doc.data["styleOptions"],
                        // thumbnail : doc.payload.doc.data["thumbnail"],
                        // mediaAltText : doc.payload.doc.data["mediaAltText"],
                        // releaseDate : doc.payload.doc.data["releaseDate"],
                        // synopsis : doc.payload.doc.data["synopsis"],
                        // price : doc.payload.doc.data["price"],
                        // saleWindow : doc.payload.doc.data["saleWindow"],
                        // sendLimit : doc.payload.doc.data["sendLimit"],
                        // review : doc.payload.doc.data["review"],
                        // occasions : doc.payload.doc.data["occasions"],
                        // tags : doc.payload.doc.data["tags"],
                        // categories : doc.payload.doc.data["categories"],
                        // customised : false
                        id : doc.payload.doc.get("id"),
                        name : doc.payload.doc.get("name"),
                        formatOptions : doc.payload.doc.get("formatOptions"),
                        styleOptions : doc.payload.doc.get("styleOptions"),
                        thumbnail : doc.payload.doc.get("thumbnail"),
                        mediaAltText : doc.payload.doc.get("mediaAltText"),
                        releaseDate : doc.payload.doc.get("releaseDate"),
                        synopsis : doc.payload.doc.get("synopsis"),
                        price : doc.payload.doc.get("price"),
                        priceEUR: doc.payload.doc.get("priceEUR"),
                        priceUSD: doc.payload.doc.get("priceUSD"),
                        saleWindow : doc.payload.doc.get("saleWindow"),
                        sendLimit : doc.payload.doc.get("sendLimit"),
                        review : doc.payload.doc.get("review"),
                        occasions : doc.payload.doc.get("occasions"),
                        tags : doc.payload.doc.get("tags"),
                        categories : doc.payload.doc.get("categories"),
                        customised : false
                  
                  }})
            })
        ).subscribe( (ecards: ECard[]) => {
          this.store.dispatch(new ECardActions.SetECards(ecards));
        },
        error => {
          console.log("Whoops! " , error);
        })
      );
      } else {
        // get everything but the christmas cards
        console.log("get everything but the christmas cards");
        this.afs.collection("ecards").ref.where('isChristmas', '==', false).orderBy('releaseDate', 'desc').onSnapshot((querySnapshot) => {
          // console.log("running snapshot");
          // console.log("querySnapshot: ", querySnapshot)
          let ecards = [];
          querySnapshot.docChanges().forEach((doc) => {
            // console.log("doc", doc.doc.data())
            ecards.push(doc.doc.data())
          })
          this.store.dispatch(new ECardActions.SetECards(ecards));
        })
      }
      } else {
        return;
      }
      // this.ecardsSubs.push()
      //   this.ecardCollection
      //   .snapshotChanges()
      //   .pipe(
      //     map(docArray => {
      //       return docArray.map(doc => {
      //         return {
      //             id : doc.payload.doc.get("id"),
      //             name : doc.payload.doc.get("name"),
      //             formatOptions : doc.payload.doc.get("formatOptions"),
      //             styleOptions : doc.payload.doc.get("styleOptions"),
      //             thumbnail : doc.payload.doc.get("thumbnail"),
      //             mediaAltText : doc.payload.doc.get("mediaAltText"),
      //             releaseDate : doc.payload.doc.get("releaseDate"),
      //             synopsis : doc.payload.doc.get("synopsis"),
      //             price : doc.payload.doc.get("price"),
      //             saleWindow : doc.payload.doc.get("saleWindow"),
      //             sendLimit : doc.payload.doc.get("sendLimit"),
      //             review : doc.payload.doc.get("review"),
      //             occasions : doc.payload.doc.get("occasions"),
      //             tags : doc.payload.doc.get("tags"),
      //             categories : doc.payload.doc.get("categories"),
      //             customised : false
      //         }
      //       });
      //     })
      //   )
      
    // fromPromise(
    //   ecardsRef.ref.get().then(querySnapshot => {
    //     let ecards: ECard[] = [];
    //     console.log("got through ref!");
    //     querySnapshot.forEach(doc => {
    
    //       let id = doc.get("id");
    //       let name = doc.get("name");
    //       let formatOptions = doc.get("formatOptions");
    //       let styleOptions = doc.get("styleOptions");
    //       let thumbnail = doc.get("thumbnail");
    //       let mediaAltText = doc.get("mediaAltText");
    //       let releaseDate = doc.get("releaseDate");
    //       let synopsis = doc.get("synopsis");
    //       let price = doc.get("price");
    //       let saleWindow = doc.get("saleWindow");
    //       let sendLimit = doc.get("sendLimit");
    //       let review = doc.get("review");
    //       let occasions = doc.get("occasions");
    //       let tags = doc.get("tags");
    //       let categories = doc.get("categories");
    //       let customised = false;
    //       let ecard : ECard = {
    //         id,
    //         name,
    //         formatOptions,
    //         styleOptions,
    //         thumbnail,
    //         mediaAltText,
    //         releaseDate,
    //         synopsis,
    //         price,
    //         saleWindow,
    //         sendLimit,
    //         review,
    //         occasions,
    //         tags,
    //         categories,
    //         customised
    //       };
    //       ecards.push(ecard);
    //     });
    //     console.log("ecards: ",  ecards);
    //     return ecards.slice();
    //   })
    // )
    // .subscribe(
    //   (ecards: ECard[]) => {
    //     this.store.dispatch(new ECardActions.SetECards(ecards));
    //   },
    //   error => {
    //     console.log("Whoops! " , error);
    //   }
    // )
    }
     

    generateId() {
      return this.afs.createId();
    }

    AddECard(ecard: ECard) {
      console.log("Running add ecard...");
      console.log("ECard recieved: " , ecard);
      
      this.afs.collection("ecards").doc(ecard.id).set({
        ...ecard
      });
      this.store.dispatch(new ECardActions.AddECard(ecard));
    }

    updateECard(ecard: ECard) {
      console.log("id:" , ecard.id);
      console.log("ecard changes: " , ecard);
      let id = ecard.id;
      const updatedECard = {
        id: ecard.id,
        name: ecard.name,
        synopsis: ecard.synopsis,
        thumbnail: ecard.thumbnail,
        mediaAltText: ecard.mediaAltText,
        formatOptions: ecard.formatOptions,
        styleOptions: ecard.styleOptions,
        releaseDate: ecard.releaseDate,
        price: ecard.price,
        priceEUR: ecard.priceEUR,
        priceUSD: ecard.priceUSD,
        salePrice: ecard.salePrice,
        saleWindow: ecard.saleWindow,
        sendLimit: ecard.sendLimit,
        review: ecard.review,
        tags: ecard.tags,
        occasions: ecard.occasions,
        categories: ecard.categories,
        customised: false,
        isChristmas: ecard.isChristmas
      }
      this.afs.collection("ecards").doc(id).update(updatedECard);
      this.store.dispatch(new ECardActions.UpdateECard({id, updatedECard}));
      // this.fetchECards();
    }

  


/// below code failed to work and also implemented an extension of the caching service

// private ecards: Observable<ECard[]>;
// private ecard: Observable<ECard[]>;
// constructor( private http: Http ) {
//             super();
//         }



// public all(): Observable<ECard[]>{
//     return this.cache<ECard[]>(     () => this.ecard,
//                                     (val: Observable<ECard[]>) => this.ecard = val,
//                                     () => this.http
//                                     .get("./assets/ecards.json")
//                                     .map( 
//                                     (response) => response.json().map( 
//                                                     (item) => {
//                                                     let model = new ECard();
//                                                     model.updateFrom(item);
//                                                     return model;
//                                                             } )));
//                                                         }



// getECard(id: number) {

//     // const card = this.retrieve();
//     // let item = card.items.find((p) => p.id === p.id);
//     // item = new ECard();
//     }
        
        
        
}
