import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import * as firebase from 'firebase/app';
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { Subscription }   from 'rxjs/Subscription';

import { UserService } from './shared/user.service';
import { isPlatformBrowser } from '@angular/common';
import { CurrencyService } from './shared/currency.service';

import Hotjar from '@hotjar/browser';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {


 
  //keep refs to subscriptions to be able to unsubscribe later
  // private popupOpenSubscription: Subscription;
  // private popupCloseSubscription: Subscription;
  // private initializeSubscription: Subscription;
  // private statusChangeSubscription: Subscription;
  // private revokeChoiceSubscription: Subscription;
  // private noCookieLawSubscription: Subscription;

  constructor(private userService: UserService, 
              // private ccService: NgcCookieConsentService,
              private currencyService: CurrencyService,
              @Inject(PLATFORM_ID) private platformId: object) {
                // const siteId = environment.hj.trackingCode;
                // const hotjarVersion = 6;
                
                // Hotjar.init(siteId, hotjarVersion);
                
                // // Initializing with `debug` option:
                // Hotjar.init(siteId, hotjarVersion, {
                //   debug: true
                // });
              }


  ngOnInit(){
    this.currencyService.onChangeCurrency('gbp');
    if (isPlatformBrowser(this.platformId)) {
      // firebase.analytics();
    }
    //  // subscribe to cookieconsent observables to react to main events
    //  this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.initializeSubscription = this.ccService.initialize$.subscribe(
    //   (event: NgcInitializeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
    //   (event: NgcStatusChangeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    // this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });

    //   this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
    //   (event: NgcNoCookieLawEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //   });
    this.userService.initAuthListener();
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    // this.popupOpenSubscription.unsubscribe();
    // this.popupCloseSubscription.unsubscribe();
    // this.initializeSubscription.unsubscribe();
    // this.statusChangeSubscription.unsubscribe();
    // this.revokeChoiceSubscription.unsubscribe();
    // this.noCookieLawSubscription.unsubscribe();
  }
  
}

