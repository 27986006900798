import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAuth from '../auth/store/auth.reducers';
import * as fromBasket from '../basket/store/basket.reducers';
import * as fromUser from '../shared/store/users.reducers';
import * as fromCheckout from '../checkout/store/checkout.reducers';
import * as fromAddressbook from '../user-area/user-settings/address-book/store/addressbook.reducers';

export interface AppState {
    auth: fromAuth.State,
    // basket : fromBasket.State,
    user: fromUser.State,
    // contacts: fromAddressbook.State,
    checkout: fromCheckout.State
}

export const reducers: ActionReducerMap<AppState> = {
    auth: fromAuth.authReducer,
    // basket: fromBasket.basketReducer,
    user: fromUser.userReducer,
    // contacts: fromAddressbook.addressbookReducer,
    checkout: fromCheckout.checkoutReducer,
};


//feeature selectors ect pending
export const getAuthState = createFeatureSelector<fromAuth.State>('auth');
export const getUserState = createFeatureSelector<fromUser.State>('user');
export const getBasketState = createFeatureSelector<fromBasket.State>('basket');
export const getCheckoutState = createFeatureSelector<fromCheckout.State>('checkout');
// export const getContactsState = createFeatureSelector<fromAddressbook.State>('contacts');

// export const getBasketItems = createSelector(getBasketState, fromBasket.getBasket);
export const getIsAuth = createSelector(getAuthState, fromAuth.getIsAuth);
export const getIsToken = createSelector(getAuthState, fromAuth.getIsToken);
export const getIsUser =  createSelector(getUserState, fromUser.getUser);

export const getSelectedGifts = createSelector(getCheckoutState, fromCheckout.getSelectedGifts);
export const getSelectedECards = createSelector(getCheckoutState, fromCheckout.getSelectedECards);
export const getBasketItems = createSelector(getCheckoutState, fromCheckout.getBasketItems);
export const getCustomisedECards = createSelector(getCheckoutState, fromCheckout.getCustomisedECards);
// export const getContacts = createSelector(getContactsState, fromAddressbook.getAvailableContacts);