import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';


import { UserService } from '../../shared/user.service';
import * as fromApp from '../../store/app.reducers';
import * as AuthActions from '../store/auth.actions';
import * as UserActions from '../.././shared/store/users.actions';
import * as fromUsers from '../.././shared/store/users.reducers';

import { User } from '../../shared/user.model';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit, OnDestroy {

  signUpForm: FormGroup;
  isAvailable: boolean = false;
  signUpError: string;
  ifSignUpError: boolean = false;
  signUpErrorSub: Subscription;
  isAttemptingSignup: boolean = false;

  constructor(private store: Store<fromApp.AppState>,
              private uStore: Store<fromUsers.State>,
              private userService: UserService,
              private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Sign Up");
    this.userService.onUserPresent();
    this.signUpForm = new FormGroup({
      'user': new FormGroup({
        'firstname': new FormControl(null, Validators.required),
        'surname': new FormControl(null, Validators.required),
        'email': new FormControl(null, [Validators.required, Validators.email]),
        'password': new FormControl(null, [Validators.required, Validators.minLength(6)]),
        'password-repeat': new FormControl(null, [Validators.required, Validators.minLength(6)]),
        'roles': new FormGroup({
          'subscriber': new FormControl(false),
          'admin': new FormControl(false)
        }),
        'legal': new FormControl()
      })
    });
  this.signUpErrorSub = this.userService.signUpError.subscribe( errorString => {
    this.signUpError = errorString;
    console.log("error found" + this.signUpError);
    if(this.signUpError != '') {
      this.ifSignUpError = true;
      this.isAttemptingSignup = false;
    } else {
      this.ifSignUpError = false;
    }
  })
  }

  onSignUp() {
    this.userService.onSignUp({
      username: this.signUpForm.get('user.email').value,
      firstname: this.signUpForm.get('user.firstname').value,
      surname: this.signUpForm.get('user.surname').value,
      email: this.signUpForm.get('user.email').value,
      password: this.signUpForm.get('user.password').value,
      roles: this.signUpForm.get('user.roles').value,
    });
    this.isAttemptingSignup = true;
  }

ngOnDestroy() {
  this.signUpErrorSub.unsubscribe();
}

}
