import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { fromPromise } from "rxjs/observable/fromPromise";

import * as fromOccCatTag from "../occ-cat-tag/store/occ-cat-tag.reducers";
import * as OccCatTagActions from "../occ-cat-tag/store/occ-cat-tag.actions";
import { Action } from "rxjs/internal/scheduler/Action";

@Injectable()
export class OccCatTagService {
    private occFetched = [];
    private OccSubs: Subscription[] = [];
    
    constructor(private afs: AngularFirestore,
                private store: Store<fromOccCatTag.State>) {}

    

fetchOCC() {
    if (this.occFetched.length == 0) {
    const occRef: AngularFirestoreCollection<any> = this.afs.collection("occ-cat-tag").doc("filters").collection("occ");
    return fromPromise(occRef.ref.get()
        .then( 
            (querySnapshot) => {
                let occs = [];

                querySnapshot.forEach( (doc) => {
                    let occName = doc.get("occName");
                    let occId = doc.id;
                    let occ = {id: occId, name: occName};
                    occs.push(occ);
                    return occs;
                })
                this.occFetched = occs;
                return this.store.dispatch(new OccCatTagActions.SetOccs(occs));
            }
          )) // close of from promise
        } else {
            return;
        }

}

createOCC(data) {
    var letUID:string = 'oc'+Date.now();
        var occsRef = this.afs.collection("occ-cat-tag").doc("filters").collection("occ").doc(letUID);
        // this.store.dispatch(new OccCatTagActions.AddOccs(data)); // now passing full object as required for del function
        this.store.dispatch( {type: 'ADD_OCCS', payload: {'name': data, 'id': letUID}} );
        console.log("createOCC: data: ", data);
        return occsRef.set({"occName" : data});
}

updateOCC(data) {
    var docID = data.id;
    var newData = data.name;
    // this.store.dispatch(new OccCatTagActions.AddOccs(data));
    this.store.dispatch(new OccCatTagActions.EditOccs(data))
    // this.store.dispatch(new OccCatTagActions.SetOccs())
    var occRef: AngularFirestoreDocument<any> = this.afs.collection("occ-cat-tag").doc("filters").collection("occ").doc(docID);
    return occRef.update({
        "occName": newData
    })
}

deleteOCC(data) {
    var docID = data.id;
    var occRef: AngularFirestoreDocument<any> = this.afs.collection("occ-cat-tag").doc("filters").collection("cat").doc(docID);
    // this.store.dispatch(new OccCatTagActions.DeleteOccs(data)); // already in component
    return occRef.delete()
        .then(
        () => {
            console.log("Occasion successfully deleted!");
        }
    )
    .catch(
        (error) => {
            console.log("Error removing document: ", error);
        }
    )
}



}

