import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shipping-settings',
  templateUrl: './shipping-settings.component.html',
  styleUrls: ['./shipping-settings.component.css']
})
export class ShippingSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
