import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, DoCheck } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { Subscription } from "rxjs/Subscription";
import { Store } from "@ngrx/store";

import { BasketService } from './basket.service';
import { ProductService } from '../shared/product.service';

import {BasketShoppingCart } from './basket-shopping-cart.model';
import { BasketItem } from './basket-item.model';
import { Product } from "../shared/product.model";

import * as fromApp from '../store/app.reducers';
import { Title } from "@angular/platform-browser";
import { CurrencyService } from "../shared/currency.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css']
})
export class BasketComponent implements OnInit, DoCheck {
  basketTotalItemsCount: number;
  basket: BasketShoppingCart; // not used
  subTotal;
  isAuth: boolean;
  currency: string;
 
constructor(  private basketService: BasketService,
              private currencyService: CurrencyService,
              private title: Title,
              private store: Store<fromApp.AppState> ) {}

ngOnInit() {
  this.title.setTitle("Basket");
  this.currencyService.currency.subscribe((currency) => {
    this.currency = currency;
  })
  this.store.select(fromApp.getIsAuth).subscribe( res => {
    // console.log("res from header: " + res);
    this.isAuth = res;
  });
  }

ngDoCheck() {
  this.subTotal = this.basketService.calculateBasketGrossTotal();
  this.basketTotalItemsCount = this.basketService.calculateBasketItemsTotal();
}

onCheckOut() {
  console.log("On checkout procedure beginning...");
  this.basketService.onCheckout();
}

  
}
