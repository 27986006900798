import { ECardCustomise, ECardPack } from '../../../../checkout/customise-e-card/customise-ecard.model';

import { OwnedECardsActions, SET_ECARD_PACKS, SET_OWNED_ECARDS, UPDATE_ECARD_PACK, UPDATE_OWNED_ECARD } from './ownedecards.actions';
import * as fromApp from '../../../../store/app.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface OwnedECardsState {
    ownedECards: ECardCustomise[];
    ecardPacks: ECardPack[];
}

export interface State extends fromApp.AppState {
    ownedECards: OwnedECardsState,
    ecardPacks: OwnedECardsState
}

const initialState : OwnedECardsState = {
    ownedECards: null,
    ecardPacks: null
}

export function ownedEcardsReducer(state = initialState, action: OwnedECardsActions) {
    switch (action.type) {
        case SET_OWNED_ECARDS:
            return {
                ...state,
                ownedECards: [...action.payload]
            };
        case SET_ECARD_PACKS:
            return {
                ...state,
                ecardPacks: [...action.payload]
            };
        case UPDATE_OWNED_ECARD:
            const oecId = action.payload.ecard.id;
            const oecIdIndex = state.ownedECards.indexOf(state.ownedECards.find(e => e.ecard.id == oecId));
            const owned =  state.ownedECards[oecIdIndex];
            const updatedOwnedECard = {
                 ...action.payload
            };
            const updatedOwned = [...state.ownedECards];
            updatedOwned[oecIdIndex] = updatedOwnedECard;
            console.log("updated owned ecards store: " , updatedOwned);
            return {
                ...state,
                ownedECards: updatedOwned
            };
        case UPDATE_ECARD_PACK:
            const oecpId = action.payload.id;
            const pId = state.ecardPacks.indexOf(state.ecardPacks.find(p => p.id === oecpId));
            const updatedPacks = [...state.ecardPacks];
            console.log("before splice store length: ", updatedPacks[pId].pack.length);
            var updatedPackspIdCopy = {...updatedPacks[pId]};
            var updatedPackspIdCopyPackArray = [...updatedPackspIdCopy.pack];
            updatedPackspIdCopyPackArray.splice(action.payload.index, 1);
            updatedPackspIdCopy.pack = updatedPackspIdCopyPackArray;
            updatedPacks[pId] = updatedPackspIdCopy;
            console.log("updated ecards pack store: ", updatedPacks);
            console.log("before splice store length: ", updatedPacks[pId].pack.length);
            return {
                ...state,
                ecardPacks: updatedPacks
            }
        default:
            return state;
    }
}

export const getOwnedECardsState =  createFeatureSelector<OwnedECardsState>('ownedECards');
export const getECardPacksState = createFeatureSelector<OwnedECardsState>('ecardPacks');

export const getOwnedECards =  createSelector(getOwnedECardsState, (state: OwnedECardsState) => state.ownedECards);
export const getECardPacks = createSelector(getECardPacksState, (state:OwnedECardsState) => state.ecardPacks);