import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  AngularFirestore,
  AngularFirestoreDocument
} from "@angular/fire/compat/firestore";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, Subject, Subscription } from "rxjs";

import { User } from "./user.model";
import * as UserActions from "./store/users.actions";
import * as fromApp from "../store/app.reducers";
import * as Auth from "../auth/store/auth.actions";
import * as fromUser from "./store/users.reducers";
import { BasketService } from "../basket/basket.service";
import { CheckoutService } from "../checkout/checkout.service";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";

import { take } from 'rxjs/operators/take';
import { map } from "rxjs/operators/map";
import { fromPromise } from "rxjs-compat/observable/fromPromise";

@Injectable()
export class UserService {
  currentUser = new Subject<User>();
  logInError = new Subject<string>();
  signUpError = new Subject<string>();
  private user: User;
  private newUser;
  private uid:string ='';
  private newUserSignup = false;

  private logout: boolean = false;

  private uSubs: Subscription;

  constructor(
    private router: Router,
    private store: Store<fromUser.State>,
    private appStore: Store<fromApp.AppState>,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private basketService: BasketService,
    private analytics: AngularFireAnalytics
  ) {}

  initAuthListener() {
    this.appStore.select(fromApp.getIsAuth).subscribe(res => {
      // console.log("resing!", res);
      // console.log("route check... ", this.router.url);
      if (res) {
        this.store.dispatch(new Auth.SetAuthenticated());
        this.currentUser.next(this.user);
        this.afAuth.authState.subscribe(user => {
          if (user) {
            // console.log(this.basketService.items.slice());
            // if (this.basketService.items.slice() != []) {
            //   this.router.navigate(['/basket']);
            // }
            //  else {
              this.router.navigate(["/account"]);
            // };
          } else {
            this.router.navigate(["/"]);
          }
        });
      } else {
        this.store.dispatch(new Auth.SetUnauthenticated());
        // this.router.navigate(['/login']);
        // if (this.router.url == '/user') {
        //     this.router.navigate(['/login']);
        // }
        // this.logout = false;
      }
    });
  }

  async onLogin(authData) {
    
    await this.afAuth.signInWithEmailAndPassword(authData.email, authData.password).catch(error => {
      let errorCode = error.code;
      let errorMessage = error.message;
      // this.openSnackBar(error, 'OK')
      console.log('error: ' , errorCode, errorMessage)
    });
    // console.log("user object: " , authData);
    this.uid =  (await this.afAuth.currentUser).uid;
    return this.afAuth.setPersistence('session').then(
      () => {
      return this.afAuth.signInWithEmailAndPassword(
      authData.email,
      authData.password
    );
      }
    )
    .then( (authData) => {
     let userUID:string = '';
      return userUID = this.uid; 
    })
      .then( (userUID:string) => {
        console.log("received uid for user fetch..." , userUID)
        // let userData;
        // this.afs.collection("users").doc(uid).snapshotChanges().pipe(
        //   take(1),
        //   map((document) => {return userData = document.payload.data()})
        // )

        this.afs
      .collection("users")
      .doc(userUID)
      .ref.get()
      .then((doc) => {
        console.log("User doc: " + doc.ref.id, doc.data());
        let userDoc: User =  { 
          uid: doc.data()["uid"],
          username: doc.data()["username"],
          firstname: doc.data()['firstname'],
          surname: doc.data()["surname"],
          email: doc.data()["email"],
          roles: doc.data()["roles"],
          stripeCustomerId: doc.data()["stripeCustomerId"]
          }
        console.log("user data, ", userDoc.uid);
        console.log("let userDoc = ", userDoc);
        if (userDoc === undefined || null) {
          console.log("nothing to see ehere folks...")
        } 

        return userDoc;
      })
      .then(userData => {
        console.log("userdata returned from fetch user data: ", userData);
        this.user = { uid: userData.uid,
                      username: userData.username,
                      firstname: userData.firstname,
                      surname: userData.surname,
                      email: userData.email,
                      roles: userData.roles};
        if (userData.stripeCustomerId) {
          this.user.stripeCustomerId = userData.stripeCustomerId
        }                      
        console.log(this.user);
        this.currentUser.next(this.user);
        let isToken: string;
        this.afAuth.idToken.subscribe((token)=> {
          //subscribe started returning null, or it always did and I just noticed, moved appStore.disptach inside the subscribe listen
          this.appStore.dispatch(new Auth.SetToken(token));
          //originally just returned isToken
          return isToken});
        let token = isToken;
        console.log("Token: ", token);
        
        this.appStore.dispatch(new Auth.SetAuthenticated());
        this.store.dispatch(new UserActions.SetUser(this.user)); // can plug the store into here.
        if (this.newUserSignup) {
          this.analytics.logEvent("new_user_login_success");
        } else {
          this.analytics.logEvent("user_login_success");
        }
        
      })
      .catch(err => {
        console.log("Hi! There has been a logging you in..." + err.message);
        console.log("Need to try creating that user in afs again...", this.user);

        // be careful with this one - it will delete current user details if the above code breaks, otherwise
        // it's what allows new users to complete their first login
        console.log("trying again... this.onCreateUser( " , this.uid, ", " , this.newUser, "this.newUser);");
        this.onCreateNewUser(this.uid, this.newUser);
        this.logInError.next("Sorry there was a problem logging you in. " + err.message);
        // end of new user first login
      }) ;
    // console.log("onLogin() end.");
    }
    )
    .catch(err => {
      console.log("Hi! There has been a problem...");
      this.logInError.next("Sorry there was a problem logging you in.\n Please check your email and password and try again.");
    }) 
   
  }

  onSignUp(signupData) {
    // console.log(signupData);
    console.log("Running sign up new user...");

    this.store.dispatch(new UserActions.LogoutUser());
    this.appStore.dispatch(new Auth.SetToken(null));
    this.afAuth
      .createUserWithEmailAndPassword(signupData.email, signupData.password)
      .then(() => {
        let userUID: string = '';
        const fetchUserId = fromPromise(this.afAuth.currentUser.then((user)=> {return this.uid = user.uid}));
        const currentUserId: string = this.uid;
        let uid = userUID;
        console.log("adding user to afs: " ,uid);
         const newUser = { uid: uid,
          username: signupData.username,
          email: signupData.email,
          firstname: signupData.firstname,
          surname: signupData.surname,
          roles: signupData.roles};
          this.newUser = newUser;
          this.uid = uid;
        return { uid, newUser}
      }).then ((data) => {
       this.onCreateNewUser(this.uid, this.newUser);
      })
      .catch(error => {
        console.log("Hi! There has been a problem creating a new user..." +  error.message);
        this.analytics.logEvent("signup_error");
        this.signUpError.next(error.message);
      }).then( () => {
        this.onLogin(signupData);
      });
  }

  onCreateNewUser(uid, newUser) {
    console.log("create new user running...");
    console.log("newUser to be added to afs:" , newUser);
    this.afs
    .collection("users")
    .doc(uid)
    .set({...newUser})
    .then((newUser) => {
      console.log("User attempt to login..." , newUser);
      this.newUserSignup = true;
      this.onLogin(newUser);
    })
    .catch( err => {
      this.analytics.logEvent("create_user_error");
      // console.log("error laddind new user to afs after onCreateNewUser attempt... " , err);
    })
    this.store.dispatch(new UserActions.SetUser(newUser));
    this.analytics.logEvent('signup_success');
    this.appStore.dispatch(new Auth.SetAuthenticated());
  }

  onLogOut() {
    this.logout = true;
    this.user = null;
    // this.uid = '';
    
    this.afAuth.signOut().then( () => {
        this.appStore.dispatch(new Auth.SetUnauthenticated());
        this.store.dispatch(new UserActions.LogoutUser());
        this.currentUser.next(null);
        this.uSubs.unsubscribe();
        this.router.navigate(['/']);
        this.analytics.logEvent("user_logout");
        // window.location.reload(true); 
      }
    );
  }

  onUserPresent() {
    this.appStore.select(fromApp.getIsAuth).subscribe(auth => {
      if (auth) {
        this.router.navigate(['/account']);
      } else {
        return;
      }
    }); 
  }


}
