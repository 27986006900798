import { Component, OnInit } from '@angular/core';
import { ECard } from '../../../../e-card-catalogue/e-card.model';
import { ECardService } from '../../../../e-card-catalogue/e-card.service';
import { Gift } from '../../../../gift-catalogue/gift.model';
import { GiftService } from '../../../../gift-catalogue/gift.service';
import { Store } from '@ngrx/store';

import * as fromECard from '../../../../e-card-catalogue/store/ecard.reducers';
import * as fromGift from '../../../../gift-catalogue/store/gift.reducers';

@Component({
  selector: 'app-all-products',
  templateUrl: './all-products.component.html',
  styleUrls: ['./all-products.component.css']
})
export class AllProductsComponent implements OnInit {

  ecards: ECard[];
  ecard: ECard;
  gifts: Gift[];
  gift: Gift;
  all: any[];

  // getAll() {
  // let isNewItem = this.all.find((i) => i.id === this.ecard.id);
  //   if (isNewItem === undefined) 
  //     {
  //     isNewItem.id = ecard.id;
  //     isNewItem.name = ecard.name;
  //     isNewItem.productImage = item.productImage || item.thumbnail;
  //     isNewItem.price = item.price;
  //     this.items.push(isNewItem);
  //     this.itemsChanged.next(this.items.slice());
  //     }
  //     isNewItem.quantity += quantity;
  // }

  

  constructor(private ecardService: ECardService,
              private giftService: GiftService,
              private ecardStore: Store<fromECard.State>,
              private giftStore: Store<fromGift.State>) { }

  ngOnInit() {
    this.ecardStore.select(fromECard.getAvailableECards).subscribe(
      res => {
        this.ecards = res;
      }
    );
    // this.giftStore.select(fromGift.get)
  }

}
