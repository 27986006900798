import { Injectable } from "@angular/core";

import { Gift } from './gift.model';

let quantity = 0;

@Injectable()
export class GiftService {

//  private gifts: Gift[] = [

//    new Gift(
//      1,
//      'A Gift',
//     'assets/ecards/ecard-thumbnails/placeholder001.jpg',
//     [
//         './ecards/ecard-thumbnails/placeholder001.jpg',
//         './ecards/ecard-thumbnails/placeholder001.jpg'
//     ],
//      '12/02/2017',
//      'A shiny new gift!',
//   'A shiny new gift! It\'s totes awmazhing yah!',
//    2.50),

//     new Gift(
//     2,
//     'A Second Gift',
//     'assets/ecards/ecard-thumbnails/placeholder001.jpg',
//     [
//         './ecards/ecard-thumbnails/placeholder001.jpg',
//         './ecards/ecard-thumbnails/placeholder001.jpg'
//     ],
//      '12/02/2017',
//      'A shiny new gift!',
//   'A shiny new gift! It\'s totes awmazhing yah!',
//    5.50),
//  ]


constructor() {}


getGifts() {
  // return this.gifts.slice();
}

getGift(id: number) {
  // let gift = this.gifts.find(i => i.id === id );
  //   return gift;
        }


}


