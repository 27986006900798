import { Injectable, EventEmitter } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { Observer } from "rxjs/Observer";

import { StorageService } from "../shared/storage.service";
import { ECardService } from "../e-card-catalogue/e-card.service";

import { BasketItem } from "./basket-item.model";
import { BasketShoppingCart } from "./basket-shopping-cart.model";
import { DeliveryOption } from "../checkout/delivery-option.model";
import { Product } from "../shared/product.model";
import { ECard } from "../e-card-catalogue/e-card.model";
import { Gift } from "../gift-catalogue/gift.model";
import { Subject } from "rxjs/Subject";
import { Store } from "@ngrx/store";

import * as fromApp from "../store/app.reducers";
import * as fromBasket from "./store/basket.reducers";
import * as BasketActions from "./store/basket.actions";
import * as fromCheckout from "../checkout/store/checkout.reducers";
import * as CheckoutActions from "../checkout/store/checkout.actions";
import { CheckoutService } from "../checkout/checkout.service";
import { Router } from "@angular/router";

import * as fromECard from "../e-card-catalogue/store/ecard.reducers";
import { ECardCustomise } from "../checkout/customise-e-card/customise-ecard.model";
import { AddressbookService } from "../user-area/user-settings/address-book/addressbook.service";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { CurrencyService } from "../shared/currency.service";

@Injectable()
export class BasketService {
  itemsChanged = new Subject<BasketItem[]>(); // observable + observer to changes of array
  selectedECards = new Subject<ECard[]>();
  selectedGifts = new Subject<Gift[]>();
  items: BasketItem[] = []; // internal basket items array
  currency: string;

  public basketTotalItems: number = 0; // initialised basket count
  public basketTotal: number; // total cost of basket

  constructor(
    private appStore: Store<fromApp.AppState>,
    private currencyService: CurrencyService,
    private store: Store<fromBasket.State>,
    private eStore: Store<fromECard.State>,
    private checkoutStore: Store<fromCheckout.State>,
    // private checkoutService: CheckoutService,
    private router: Router,
    // private analytics: AngularFireAnalytics
  ) {}

  // fetches a copy of the items
  public getBasketItems() {
    return this.items.slice();
  }

  // searches items array for matching id
  // if found updates quantity for existing instance
  // else all properties of item are transfered as normal with addItem()
  public basketItemQuantity(item: any) {
    let quantityChecker = this.items.find(i => i.id === item.id);
    if (quantityChecker === undefined) {
      return 0;
    } else {
      return quantityChecker.quantity;
    }
  }

  public onUpdateBasketQty(item: BasketItem, quantity) {
    const updateItemQty: BasketItem = this.items.find(i => i.id === item.id);
    return updateItemQty.quantity = quantity;
  }

  // finds if item is already in basket & updates its quantity if it exists,
  // else adds a new instance
  public addItem(item: any, quantity: number) {
    this.currencyService.currency.subscribe((currency) => {
      this.currency = currency;
    })
    let isNewItem = this.items.find(i => i.id === item.id);
    if (isNewItem === undefined) {
      isNewItem = new BasketItem();
      isNewItem.id = item.id;
      isNewItem.name = item.name; 
      isNewItem.productImage = item.productImage || item.thumbnail.downloadURL;
      isNewItem.price = item.price;
      isNewItem.priceEUR = item.priceEUR;
      isNewItem.priceUSD = item.priceUSD;
      if (item.productImage == undefined) {
        isNewItem.type = "ecard";
        isNewItem.sendLimit = item.sendLimit;
        console.log("adding item to basket: " , item);
        console.log("adding item: ecard.sendLimit: " , item.sendLimit);
        this.selectedECards.next(item);
      } else {
        isNewItem.type = "gift";
        this.selectedGifts.next(item);
      }
      let newItem: BasketItem = isNewItem;
      this.items.push(isNewItem);
      // this.analytics.logEvent('add_to_cart', {item: isNewItem.name, itemId: isNewItem.id})
      console.log("isNewItem: ", isNewItem);
      // this.store.dispatch(new BasketActions.AddItem(isNewItem));
      this.itemsChanged.next(this.items.slice());
    }
    isNewItem.quantity += quantity;
  }

  // looks at the array of items by index of key (basketItem) passed from BasketItemComponent,
  // starting at index position 0,
  // if the key exists the array is spliced there
  public deleteItem(basketItem: BasketItem) {
    let toDelete = this.items.indexOf(basketItem, 0);
    // this.analytics.logEvent('remove_from_cart', {'item': basketItem.name, 'itemId': basketItem.id })
    if (toDelete > -1) {
      this.items.splice(toDelete, 1);
      // this.itemsChanged.next(this.items.slice());
    }
  }

  // keeps count of quantity of all items are in the basket
  public calculateBasketItemsTotal() {
    return (this.basketTotalItems = this.getBasketItems()
      .map(x => x.quantity)
      .reduce((p, n) => p + n, 0));
  }

  // keeps count of total cost of whole basket
  public calculateBasketGrossTotal() {
    this.currencyService.currency.subscribe((currency) => {
      this.currency = currency;
    })
    if (this.currency == 'gbp') {
    return (this.basketTotal = this.getBasketItems()
      .map(x => x.price * x.quantity)
      .reduce((p, n) => p + n, 0));
    } else if (this.currency == 'eur') {
      return (this.basketTotal = this.getBasketItems()
      .map(x => x.priceEUR * x.quantity)
      .reduce((p, n) => p + n, 0));
    } else {
      return (this.basketTotal = this.getBasketItems()
      .map(x => x.priceUSD * x.quantity)
      .reduce((p, n) => p + n, 0));
    }
  }

  // pass the basket to the checkout
  public onCheckout():void { 
   this.selectedECards.subscribe(res => {
  console.log("ecard subject selected: " , res);
    })
  console.log("Sending to checkout: ", this.items.slice());
  }
}
