import { Injectable } from "@angular/core";
// import { Http } from "@angular/http";
import { Product } from "./product.model";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";
import { CachingServiceBase } from "./caching.service";
import { GiftService } from "../gift-catalogue/gift.service";


let count = 0;

@Injectable()
export class ProductService extends CachingServiceBase {




}
