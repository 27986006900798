import { Component, DoCheck, OnInit } from '@angular/core';
import { ECard } from '../../e-card-catalogue/e-card.model';
import { ECardService } from '../../e-card-catalogue/e-card.service';
import { Store } from "@ngrx/store";

import * as fromECard from '../../e-card-catalogue/store/ecard.reducers';
import { SEOService } from '../../shared/seo.service';
import { CurrencyService } from '../../shared/currency.service';


@Component({
  selector: 'app-latest-ecards',
  templateUrl: './latest-ecards.component.html',
  styleUrls: ['./latest-ecards.component.css']
})
export class LatestEcardsComponent implements OnInit, DoCheck {

  latestEcards: ECard[] = [];

  runFetch: boolean;

   ecardsLoading = true;

  currency: string;
  // priceCurrency = 'ecard.price';

constructor(private ecardService: ECardService,
            private currencyService: CurrencyService,
            private seoService: SEOService,
            private store: Store<fromECard.State>) {}

  ngOnInit(): void {
    // this.checkStore();
    // this.ecardService.fetchECards();
    // this.seoService.generateTags({
    //   name: "Shop our ecard collection",
    //   slug: 'https://lyricaldays.com/ecards',
    //   title: 'Shop Ecards',
    //   description: 'Browse our ever growing collection of ecards for something that would suit your occasion.',
    //   image: 'https://firebasestorage.googleapis.com/v0/b/lyrical-days.appspot.com/o/branding%2FSpring-Summer-super.jpg?alt=media&token=80bbb181-a8c3-49a5-8de7-39199c854ad3'
    // }) ;
    this.currencyService.currency.subscribe((currency) => {
      this.currency = currency;
    })
    this.onWaitingRes();
    this.store.select(fromECard.getAvailableECards)
    .subscribe( (res) => {
      console.log("Running latest ecards pull: ", res.length);
      this.latestEcards = res.slice(0, 6);
    });
  }

  ngDoCheck() {
    this.onWaitingRes();
    this.currencyService.currency.subscribe((currency) => {
      this.currency = currency;
    })
  }

onWaitingRes() {
if (this.latestEcards.length == 0) {
  this.ecardsLoading = true;
}
else {
  this.ecardsLoading = false;
}
}

  // checkStore() {
  //   this.store.select(fromECard.getAvailableECards)
  //   .subscribe( (res) => {
  //     console.log("res in checkStore: ", res);
  //   if (res.length < 1) {
  //     return this.runFetch = true;
  //   }
  //   else {
  //     return this.runFetch = false;
  //   }
  //   });
  // }

}
