import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng4-validators';

import { AuthenticateService } from '../../auth/authenticate.service';
import { CreditCard } from './card.model';


@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css']
})
export class PaymentDetailsComponent implements OnInit {

paymentDetailsForm: FormGroup;
card: CreditCard;

constructor(private authService: AuthenticateService) { }

  ngOnInit() {
    this.paymentDetailsForm = new FormGroup({
    'card-type': new FormControl(null, [Validators.required]),
    'cardholder-name': new FormControl(null, [Validators.required]),
    'card-number': new FormControl(null, [Validators.required, 
                                          CustomValidators.digits, 
                                          CustomValidators.creditCard]),
    'card-expiration': new FormControl(null, [Validators.required, 
                                          Validators.pattern("(((0[123456789]|10|11|12)([/])(([1][9][0-9][0-9])|([2][0-9][0-9][0-9]))))"),// 
                                          CustomValidators.date]),
    'card-security-code': new FormControl(null, [Validators.required, 
                                          CustomValidators.digits, Validators.maxLength(4), 
                                          Validators.minLength(3)]),
  });
  }



}
