import { Component, OnInit, DoCheck } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducers';

import { BasketItem } from '../basket-item.model';
import { BasketService } from '../basket.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-basket-list',
  templateUrl: './basket-list.component.html',
  styleUrls: ['./basket-list.component.css']
})
export class BasketListComponent implements OnInit, DoCheck {

  // basketState: Observable<{basket:BasketItem[]}>; // an empty array of items for the basket item bits

  basket: BasketItem[] = [];

  constructor(private store: Store<fromApp.AppState>,
              private basketService: BasketService) { }

  ngOnInit() {
    
  }

  // keeps the basket list up-to-date with latest version of items
ngDoCheck() {
  // this.store.select(fromApp.getBasketItems).subscribe(res => {
  //   if (res) {
  //     this.basket = res;
  //     console.log("Basket items: " , res);
  //   }
  //   else {
  //     console.log("Cannot find basket. Help me look, please.");
  //   }
  // })
  this.basket = this.basketService.getBasketItems();
}

}
