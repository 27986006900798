import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { AuthInterceptor } from "../shared/auth.interceptor";

import { EssentialsComponent } from "./essentials/essentials.component";
import { HomeComponent } from "./home/home.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from './footer/footer.component';
import { FooterNavComponent } from './footer/footer-nav/footer-nav.component';
import { AppRoutingModule } from "../app-routing.module";

import { ECardService } from '../e-card-catalogue/e-card.service';
import { GiftService } from '../gift-catalogue/gift.service';
import { ProductService } from '../shared/product.service';
import { BasketService } from '../basket/basket.service';
import { AuthenticateService } from '../auth/authenticate.service';
import { UserService } from '../shared/user.service';
import { LocalStorageService, StorageService } from '../shared/storage.service';
import { AuthGuard } from '../auth/auth-guard.service';
import { MaterialModule } from "../material.module";
import { LatestEcardsComponent } from './latest-ecards/latest-ecards.component';
import { ECardItemComponent } from "../e-card-catalogue/e-card-list/e-card-item/e-card-item.component";
import { SharedModule } from "../shared/shared.module";
import { StoreModule } from "@ngrx/store";
import { ecardReducer } from "../e-card-catalogue/store/ecard.reducers";
import { ECardRoutingModule } from "../e-card-catalogue/e-card-routing.module";
import { ECardDetailComponent } from "../e-card-catalogue/e-card/e-card-detail.component";
import { ECardListComponent } from "../e-card-catalogue/e-card-list/e-card-list.component";
import { ECardCatalogueComponent } from "../e-card-catalogue/e-card-catalogue.component";
import { ECardFeatureModule } from "../e-card-catalogue/e-card.module";


@NgModule({
    declarations: [
        EssentialsComponent,
        HeaderComponent,
        HomeComponent,
        FooterComponent,
        FooterNavComponent,
        LatestEcardsComponent,
    ],
    imports: [
        AppRoutingModule,
        CommonModule,
        SharedModule,
        AppRoutingModule,
        // ECardFeatureModule,
        // ECardItemComponent,
        StoreModule.forFeature('ecards', ecardReducer),
    ],
    exports: [
        AppRoutingModule,
        EssentialsComponent,
        HeaderComponent,
        HomeComponent,
        FooterComponent,
        FooterNavComponent
    ],

    providers: [
        ECardService,
        GiftService,
        ProductService,
        // BasketService,
        AuthenticateService,
        AuthGuard,
        LocalStorageService,
        UserService,
        { provide: StorageService, useClass: LocalStorageService },
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {
          deps: [StorageService, ProductService],
          provide: BasketService,
          useClass: BasketService
        }
      ]
})

export class CoreModule {}