import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromOccCatTag from '../store/occ-cat-tag.reducers';
import * as OccCatTagActions from '../store/occ-cat-tag.actions';


@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent implements OnInit {

  tagForm: FormGroup;
  editMode: boolean = false;
  tempTag: {id: string, name: string};

  tag$: Observable<string[]>;
  

  constructor(private store: Store<fromOccCatTag.State>) { }

  ngOnInit() {
    this.store.dispatch({type: 'TRY_TAGS'});
    this.tag$ = this.store.select(fromOccCatTag.getAvailableTags);
    this.tagForm = new FormGroup({
      'tagControl': new FormControl(null, Validators.required)
    })
  }

  onTag() {
  
    if (this.editMode === true) {
      // check for string changes first
      if (this.tempTag.name != this.tagForm.get('tagControl').value) {
        this.tempTag.name = this.tagForm.get('tagControl').value;
      let id = this.tempTag.id;
      let name = this.onTitleCase(this.tempTag.name);
      this.store.dispatch( {type: 'EDIT_TAGS', payload: {id, name} } );
      this.store.dispatch({type: 'TRY_TAGS'});
      }
      // else nothing
    } // else create new tag
    else {
       let tagValue = this.tagForm.get('tagControl').value;
    // let letUID:string = 'tg'+Date.now();   moved to effects
        var dataTitleCase = this.onTitleCase(tagValue);
    this.store.dispatch({type: 'ADD_TAGS', payload: {'tagName': dataTitleCase} }) ;
    }
    this.editMode = false;
    this.tagForm.reset();
  }

  onEdit(tagId: string, tagName: string) {
    this.editMode = true;
    this.tempTag = {id: tagId, name: tagName};
    this.tagForm.setValue({'tagControl': tagName}); 
  }
  
  onCancelEdit() {
    this.editMode = false;
    this.tagForm.reset();
  }

onDelete() {
  let id = this.tempTag.id;
  let name = this.tempTag.name;
  this.store.dispatch( { type: 'DELETE_TAGS', payload: {id, name} } );
  this.store.dispatch({type: 'TRY_TAGS' });
  this.editMode = false;
  this.tagForm.reset();
}

onTitleCase(string) {
  // let string: string = this.tagForm.get('tagControl').value;
  var nameTitleCase = string.toLowerCase().split(' ').map( (word) => {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
return nameTitleCase;
}

ngOnChanges() {

}

}


//// Break

// const getEvents = (id) => {
//   return new Promise((resolve, reject) => {
//       let events = [];
//       const snapshot = firestore.collection('users')
//           .doc(id)
//           .collection('events')
//           .get()
//           .then((snapshot) => {
//               snapshot.forEach((doc) => events.push(doc));
//               resolve(events); // return the events only after they are fetched
//           })
//           .catch(error => {
//               reject(error);
//           });
//   });
// };


