import { ECard } from '../e-card.model';
import { ECardActions, ADD_ECARD, SET_ECARDS, UPDATE_ECARD, DELETE_ECARD } from './ecard.actions';
import * as fromApp from '../../store/app.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface ECardState {
    ecards: ECard[];
}

export interface State extends fromApp.AppState {
    ecards: ECardState
}


const initialState: ECardState = {
    ecards: []
};

export function ecardReducer(state = initialState, action: ECardActions) {
    switch (action.type) {
        case SET_ECARDS:
        return {
            ...state,
        ecards: [...action.payload]
        };
        case ADD_ECARD:
        return {
            ...state,
        ecards: [...state.ecards, action.payload]
        };
        case UPDATE_ECARD:
        const ecardEdit = state.ecards.find(e=> e.id === action.payload.id);
        const index = state.ecards.indexOf(ecardEdit);
        const ecard = state.ecards[index];
        const updatedECard = {
            ...ecard,
            ...action.payload.updatedECard
        };
        const updatedECards = [...state.ecards];
        updatedECards[index] = updatedECard;
        return {
            ...state,
            ecards: updatedECards
        };
        case DELETE_ECARD:
        const refreshedEcards = [...state.ecards];
        refreshedEcards.splice(action.payload, 1);
        return {

        }
        default:
        return state;
    }
}

// selectors

export const getECardsState =  createSelector((state: State) => state.ecards, ecards => ecards);

export const getAvailableECards = createSelector(getECardsState, (state: ECardState) => state.ecards);