import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import {
  AngularFireStorage,
  AngularFireUploadTask
} from "@angular/fire/compat/storage";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { tap } from "rxjs/operators";
import { UploadTaskSnapshot } from "@angular/fire/compat/storage/interfaces";

@Component({
  selector: "app-media-uploader",
  templateUrl: "./media-uploader.component.html",
  styleUrls: ["./media-uploader.component.css"]
})
export class MediaUploaderComponent implements OnInit {

isHovering: boolean;

files: File[] = [];

toggleHover(event: boolean) {
  this.isHovering = event;
}

onDrop(files: FileList) {
  for (let i = 0; i < files.length; i++) {
    this.files.push(files.item(i));
  }
}



  // // Main task...just needs to exist but could be moved to const inside startUpload()
  // task: AngularFireUploadTask;

  // // Progress monitoring
  // percentage$: Observable<number>;

  // snapshot$: Observable<any>;

  // // Donwload URL
  // downloadURL: Observable<string>;

  // // State for dropzone CSS toggling
  // isHovering: boolean;

  // isActivated: boolean = false;

  // constructor(
  //   private storage: AngularFireStorage,
  //   private afs: AngularFirestore
  // ) {}

  // toggleHover(event: boolean) {
  //   this.isHovering = event;
  // }

  // startUpload(event: FileList) {
  //   // This file object
  //   const file = event.item(0);

  //   // client-sdie validation example
  //   if (file.type.split("/")[0] !== "image") {
  //     // this.mediaItem.mediaType = "video";
  //     console.error("unsupported file type ;(");
  //     // Matching afs back end rule::
  //     // allow read; allow write: if request.resource.contentType.matches('image/.*');
  //     return;
  //   }
  //   //storage path
  //   const filePath = `media/${new Date().getTime()}_${file.name}`;

  //   // Totally optional metadata
  //   const customMetadata = {
  //     copyright: "©2019 LyricalDays.com. All rights reserved."
  //   };

  //   //the main task now has enough info to complete upload to storage
  //   this.task = this.storage.upload(filePath, file, { customMetadata });
  //   const fileRef = this.storage.ref(filePath);

  //   // Progress monitoring for client-side
  //   this.percentage$ = this.task.percentageChanges();
  //   this.snapshot$ = this.task.snapshotChanges().pipe(
  //     tap(snapshot => {
  //       let bytesTransferred = snapshot.bytesTransferred;
  //       let totalBytes = snapshot.totalBytes;

  //       if (bytesTransferred === totalBytes) {
  //         // update firestore on completion
  //         this.afs
  //           .collection("media")
  //           .add({ filePath, size: snapshot.totalBytes });
  //       }
  //     })
  //   );

  //   // the file's download URL
  //   this.snapshot$
  //     .pipe(
  //       finalize(
  //         () => (this.downloadURL = this.storage.ref(filePath).getDownloadURL())
  //       )
  //     )
  //     .subscribe();
  // }

  // // isActive(snapshot: UploadTaskSnapshot) {
  // //   console.log('Snapshot', snapshot);
  // //   return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  // // }

  // isActive(snapshot) {
  //   return (
  //     snapshot.state === "running" &&
  //     snapshot.bytesTransferred < snapshot.totalBytes
  //   );
  // }

  ngOnInit() {}
}
